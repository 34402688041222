import * as FullStory from '@fullstory/browser';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import { useGlobalSearchContext } from 'App/GlobalSearch/GlobalSearchContext';
import GlobalSearchDialog from 'App/GlobalSearch/GlobalSearchDialog';
import { ReactComponent as LogoIcon } from 'assets/brand/icon-white.svg';
import { ReactComponent as AssetExposureIcon } from 'assets/icons/asset-exposure.svg';
import { ReactComponent as AssetInventoryIcon } from 'assets/icons/asset-inventory.svg';
import { ReactComponent as BrainIcon } from 'assets/icons/brain.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/broadcast.svg';
import { ReactComponent as CampaignsIcon } from 'assets/icons/campaigns.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as CompareIntegrationsIcon } from 'assets/icons/compare.svg';
import { ReactComponent as DefenseSurfaceIcon } from 'assets/icons/defense-surface.svg';
import { ReactComponent as DetectionsIcon } from 'assets/icons/detections.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
// NOTE: Our Identities icon is originally based on the MUI "Recent Actors" icon
import { ReactComponent as IdentitiesIcon } from 'assets/icons/identities.svg';
import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/integrations.svg';
import { ReactComponent as KeyboardIcon } from 'assets/icons/keyboard.svg';
import { ReactComponent as LiveChatIcon } from 'assets/icons/live_chat.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SoftwareIcon } from 'assets/icons/software.svg';
import { ReactComponent as ThreatGroupIcon } from 'assets/icons/threat-group.svg';
import { ReactComponent as ThreatProfileIcon } from 'assets/icons/threat-model.svg';
import { ReactComponent as ThreatsIcon } from 'assets/icons/threats.svg';
import { ReactComponent as VulnerabilitiesIcon } from 'assets/icons/vulnerabilities.svg';
import { ReactComponent as WrenchIcon } from 'assets/icons/wrench.svg';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { useAuth } from 'core/Auth';
import Mousetrap from 'mousetrap';
import { Divider, Kbd, useNotification } from 'reablocks';
import { FC, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'reakeys';
import { Avatar } from 'shared/elements/Avatar';
import RequireRole from 'shared/user/RequireRole';
import { useUserContext } from 'shared/user/UserContext';
import MenuItem from './MenuItem';
import css from './Nav.module.css';
import OrganizationSwitchingMenu from './OrganizationSwitchingMenu';
import SubmenuItem, { UserSubmenuItem } from './SubmenuItem';

/**
 * NOTE: The roles used to guard navigation items are set in the provisioner
 * @see https://github.com/Room40Labs/provisioner/blob/4164497b9d351c3300712994e66209bbba380142/fusionclient/service_config.json
 */
const USE_NO_CHAT_UI = import.meta.env.VITE_IS_ON_PREMISE === 'true';

export const Nav: FC = () => {
  let { show } = useIntercom();
  if (location.host.includes('localhost')) {
    show = () => alert(`If we weren't in dev, you'd see the help pop-over now`);
  }
  const { logout } = useAuth();
  const { user } = useUserContext();

  const { notify } = useNotification();
  const { isOpen: isGlobalSearchOpen, open: showGlobalSearch } =
    useGlobalSearchContext();
  const [hasMenuOpen, setHasMenuOpen] = useState(false);
  /**
   * The organization switching menu is different from all other menus, in that
   * they're static, but the org menu dynamically changes it's size when the
   * user shows more organization (we don't want to show the "scroll back up"
   * link until there is content to scroll back up to).
   *
   * That breaks the MUI positioning, so we need this state var to force the
   * menu to re-render (re-positioning it) when the org menu changes size. We
   * accomplish this by incrementing this state
   */
  const [orgMenuReposition, setOrgMenuReposition] = useState(1);
  const repositionMenu = () => setOrgMenuReposition(orgMenuReposition + 1);

  useHotkeys([
    {
      name: 'Live Chat',
      keys: 'h',
      description: 'Open the Live Chat',
      callback: () => show()
    },
    {
      name: 'Capture Session',
      keys: 'mod+/',
      description: 'Capture the session for debugging',
      callback: () => {
        const sessionURL = FullStory?.getCurrentSessionURL(true);
        if (sessionURL) {
          copy(sessionURL);
          notify(`Copied ${sessionURL} to your clipboard.`);
        }
      }
    }
  ]);
  return (
    <nav className={css.container}>
      <div className={css.top + ` px-0 py-lg`}>
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<LogoIcon className={css.logo} />}
          isTopButton={true}
          link="/"
        />
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<ThreatsIcon />}
          isActivePatterns={[
            '/campaigns/*',
            '/software/*',
            '/techniques/*',
            '/threat-groups/*',
            '/threat-profiles/*'
          ]}
          name="Threat Exposure"
          submenu={({ onClose }) => (
            <>
              <SubmenuItem
                {...{ onClose }}
                icon={CampaignsIcon}
                label="Campaigns"
                link="/campaigns"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={BrainIcon}
                label="Techniques"
                link="/techniques"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={SoftwareIcon}
                label="Software"
                link="/software"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={ThreatGroupIcon}
                label="Threat Groups"
                link="/threat-groups"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={ShieldOutlinedIcon}
                label="ATT&CK Matrix"
                link="/attack-matrix"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={ThreatProfileIcon}
                label="Threat Profile"
                link="/threat-profiles"
              />
            </>
          )}
        />
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<DefenseSurfaceIcon />}
          isActivePatterns={[
            '/controls/*',
            '/detections/*',
            '/integrations/*',
            '/recommendations/*',
            '/sources/*',
            '/product-comparison/*',
            '/visibility/*'
          ]}
          name="Defense Surface"
          submenu={({ onClose }) => (
            <>
              <SubmenuItem
                {...{ onClose }}
                icon={VisibilityIcon}
                label="Visibility"
                link="/visibility"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={DetectionsIcon}
                label="Detections"
                link="/detections"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={IntegrationsIcon}
                label="Integrations"
                link="/integrations"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={CompareIntegrationsIcon}
                label="Product Comparison"
                link="/product-comparison"
              />
            </>
          )}
        />
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<AssetExposureIcon />}
          isActivePatterns={[
            '/asset/*',
            '/asset-inventory/*',
            '/platforms/*',
            '/vulnerabilities/*'
          ]}
          name="Attack Surface"
          submenu={({ onClose }) => (
            <>
              <SubmenuItem
                {...{ onClose }}
                icon={VulnerabilitiesIcon}
                label="Vulnerabilities"
                link="/vulnerabilities"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={AssetInventoryIcon}
                label="Assets"
                link="/asset"
              />
              <SubmenuItem
                {...{ onClose }}
                icon={IdentitiesIcon}
                label="Identities"
                link="/identities"
              />
            </>
          )}
        />
        <GlobalSearchDialog />
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<SearchIcon />}
          isActive={isGlobalSearchOpen}
          name="Global Search"
          onClick={showGlobalSearch}
        />
      </div>
      <div className={css.push} />
      <div
        className={classNames(
          css.bottom +
            ` flex h-[360px] w-full flex-col items-center justify-end px-0 py-lg`,
          { [css.old]: USE_NO_CHAT_UI },
          // Without this the span from the ReaBlocks popover screws up the
          // menu items, by not letting them grow to their proper width
          '[&>a]:min-w-full [&>span]:min-w-full'
        )}
      >
        {!USE_NO_CHAT_UI && (
          <RequireRole role="AI_CHAT">
            <MenuItem
              {...{ hasMenuOpen, setHasMenuOpen }}
              icon={<ChatIcon />}
              isActivePatterns={['/assistant/*', '/aichat/*']}
              link="/aichat/welcome"
              name="AI Chat"
              triggerClassName={css.settingsTooltip}
            />
          </RequireRole>
        )}
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<WrenchIcon />}
          isActivePatterns={['/settings/*']}
          link="/settings"
          name="Settings"
        />
        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          icon={<HelpIcon />}
          modifiers={{
            offset: {
              offset: '0, 30px'
            }
          }}
          name="Help"
          submenu={({ onClose }) => (
            <>
              <SubmenuItem
                {...{ onClose }}
                alwaysWhite
                icon={LiveChatIcon}
                label="Live Chat"
                onClick={() => show()}
              />
              <SubmenuItem
                {...{ onClose }}
                alwaysWhite
                icon={InformationIcon}
                label="Docs"
                onClick={() =>
                  window.open('http://docs.interpres.io/', '__blank')
                }
              />
              <SubmenuItem
                {...{ onClose }}
                alwaysWhite
                icon={KeyboardIcon}
                label={<span className="pr-lg">Keyboard Shortcuts</span>}
                onClick={() => Mousetrap.trigger('SHIFT+?')}
                shortcut={
                  <div>
                    <Kbd
                      keycode="shift+?"
                      title="Shift + ? to open hotkey shortcuts"
                    />
                  </div>
                }
              />
            </>
          )}
        />
        <RequireRole role="ReadChildTenant">
          <OrganizationSwitchingMenu
            {...{ hasMenuOpen, repositionMenu, setHasMenuOpen }}
          />
        </RequireRole>

        <MenuItem
          {...{ hasMenuOpen, setHasMenuOpen }}
          buttonClassName="p-0 mt-md mb-[3px]"
          icon={<Avatar user={user} />}
          name="Account"
          submenuClass="!fill-gray100 min-w-[260px]"
          submenu={onClose => (
            <>
              <UserSubmenuItem user={user} />
              <Divider disableMargins />
              <SubmenuItem
                {...{ onClose }}
                icon={LogoutIcon}
                isRed
                label="Log Out"
                onClick={logout}
              />
            </>
          )}
        />
      </div>
    </nav>
  );
};
