import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { Kbd, List, ListItem } from 'reablocks';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HotkeyShortcuts, MODIFIER_KEY, useHotkeys } from 'reakeys';
import BaseDialog from 'shared/dialogs/BaseDialog/BaseDialog';
import css from './HotkeyCombos.module.css';

const KeyCode = ({ code }) => {
  const wrapped = Array.isArray(code) ? code : [code];
  const formatted = wrapped.map(k => k.replace('mod', MODIFIER_KEY));

  return (
    <div>
      {formatted.map((k, i) => (
        <Kbd key={i} className={css.combo} keycode={k} />
      ))}
    </div>
  );
};

const Group = ({ group, keyCombos }) =>
  !!keyCombos?.length && (
    <section key={group}>
      <h3>{group}</h3>
      <List>
        {keyCombos.map(kk => (
          <ListItem
            key={kk.name}
            disableGutters
            disablePadding
            className={css.comboContainer}
            end={<KeyCode code={kk.keys} />}
          >
            <label>{kk.name}</label>
            {kk.description && <p>{kk.description}</p>}
          </ListItem>
        ))}
      </List>
    </section>
  );

const HotkeyCombos = ({ sorted }) => {
  const { General, ...rest } = sorted;
  const others = sortBy(Object.keys(rest || {}));

  return (
    <div
      className={
        css.groups + ' grid justify-items-stretch gap-[30px] ' //+
        // We wound up abandoning multiple columns because we only have a max
        // of 4 hotkeys possible, and that looks fine in a single column.
        // However, if we ever get more than 4 we can bring columns back (and
        // potentially the dialog could even go up to 3 columns, if needed).
        // (others.length ? `grid-cols-2` : ``)
      }
    >
      <Group group="General" keyCombos={General} />
      {others.map(group => (
        <Group key={group} keyCombos={sorted[group]} {...{ group }} />
      ))}
    </div>
  );
};

const HotkeyDialog: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  useHotkeys([
    {
      name: 'Hotkey Dialog',
      keys: 'SHIFT+?',
      hidden: true,
      callback: () => setVisible(true)
    }
  ]);
  const hotkeys = useHotkeys();
  const categories = useMemo(() => groupBy(hotkeys, 'category'), [hotkeys]);

  const sorted: { [key: string]: HotkeyShortcuts[] } = Object.keys(
    categories
  ).reduce((prev, cur) => {
    const category = sortBy(categories[cur], 'name');
    const label = cur === 'undefined' ? 'General' : cur;
    const visible = category.filter(k => !k.hidden);

    return { ...prev, [label]: visible };
  }, {});

  return (
    <BaseDialog
      fullWidth={false}
      isOpen={visible}
      maxWidth="md"
      onClose={() => setVisible(false)}
      title="Hotkeys"
    >
      <HotkeyCombos {...{ sorted }} />
    </BaseDialog>
  );
};

/**
 * Backstory: The way GC set things up, each page component can register its own
 * hotkeys, which is nice ... except that they didn't set up any sort of
 * "everything is registered now" event. Instead, they simply didn't check the
 * hotkeys until the dialog opened (and by then everything was registered).
 *
 * One problem with that approach: if you get the hotkeys inside the dialog you
 * can't adjust the dialog's size based on those hotkeys.  As a result, you wind
 * up with a giant dialog full of blank space, with a few hotkeys on the left.
 *
 * So, we moved the hotkey logic into the dialog, which let's us solve that ...
 * but it created a new timing problem, because the hotkeys are retrieved
 * eralier than they used to be.
 *
 * (Kind of Hacky) Solution: When we go to render the dialog, wait 1 second
 * before you actually rendering it, so the hotkeys have time to register.
 */
const TimingWrapper = () => {
  const [ready, setReady] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setReady(false);
    setTimeout(() => setReady(true), 2000);
  }, [location]);
  return ready && <HotkeyDialog />;
};

export default TimingWrapper;
