import css from './Terms.module.css';

// Convenience formatting components
const Term = ({ children }) => <span className={css.term}>{children}</span>;
const B = ({ children }) => <span className={css.bold}>{children}</span>;
const U = ({ children }) => <span className={css.underline}>{children}</span>;
const Quote = ({ children }) => <>&ldquo;{children}&rdquo;</>;

const Terms = ({ email }) => (
  <div className={css.termsPage}>
    {/* Document #1 */}
    <div>
      <div className={css.centered}>SOFTWARE-AS-A-SERVICE AGREEMENT</div>
      <p>
        This SOFTWARE-AS-A-SERVICE TRIAL AGREEMENT (the <Term>Agreement</Term>)
        is entered into by and between Interpres Security Inc. (
        <Term>Interpres Security</Term>) and the Person agreeing to be bound by
        the terms herein (<Term>Customer</Term>), and is effective on the date
        when the Customer clicks the{' '}
        <Quote>
          I accept the terms and conditions of the Software-As-A-Service Trial
          Agreement
        </Quote>{' '}
        button (the <Term>Effective Date</Term>). Interpres Security and
        Customer may be referred to herein individually as a <Term>Party</Term>{' '}
        and collectively as the
        <Quote>Parties</Quote>. By clicking its acceptance to be bound by this
        Agreement, Customer agrees that its access to and use of the Services
        are governed by the terms herein.
      </p>
      <ol className={css.numberList}>
        <li>
          <B>
            <U>Definitions</U>
          </B>
          . Capitalized terms have the meanings set forth or referred to in this{' '}
          <U>Section 1</U>:
          <ol className={css.numberList}>
            <li>
              <Term>Affiliate</Term> of a Person means any other Person that
              directly or indirectly, through one or more intermediaries,
              controls, is controlled by, or is under common control with, such
              Person. The term <Quote>control</Quote> (including the terms
              &ldquo;controlled by&rdquo; and &ldquo;under common control
              with&rdquo;) means ownership of more than fifty percent (50 %) of
              the voting securities of a Person.
            </li>
            <li>
              <Term>Authorized User</Term> means each of the employees or
              independent contractor individuals authorized to use the SaaS
              Services pursuant to <U>Section 2</U> and the other terms and
              conditions of this Agreement. Each Authorized User must at all
              times while using the SaaS Services comply with Interpres
              Security&apos;s standard End User License Agreement (
              <Term>EULA</Term>) which is attached hereto as <U>Exhibit A</U>.
              Customer acknowledges and agrees that it is Customer&apos;s
              responsibility to ensure its and each of its Authorized
              User&apos;s compliance with the EULA. The EULA is deemed agreed to
              and accepted by Customer and each of its Authorized Users upon
              Customer&apos;s clicking its acceptance of this Agreement which
              shall include its acceptance of all Exhibits hereto and all terms
              and conditions incorporated herein by reference. Access to the
              SaaS Services may be restricted to the Customer or its Authorized
              Users through use of credentials provided by Interpres Security.
            </li>
            <li>
              <Term>Customer Content</Term> means (i) all data, information,
              content and other materials pertaining to any and all users of the
              SaaS Services and (ii) all data, information, content (structured
              and unstructured) and other materials uploaded or copied to the
              SaaS Services by an Authorized User or any data generated from the
              use of, concerning or contained in, or otherwise transmitted by or
              through, the SaaS Services or otherwise provided by Customer.
            </li>
            <li>
              <Term>Documentation</Term> means any and all manuals, instructions
              and other documents and materials related to the SaaS Services
              that Interpres Security provides or makes available to Customer.
            </li>
            <li>
              <Term>Intellectual Property Rights</Term> means any and all
              registered and unregistered rights granted, applied for or
              otherwise now or hereafter in existence under or related to any
              patent, copyright, trademark, trade secret, database protection or
              other intellectual property rights laws, and all similar or
              equivalent rights or forms of protection, in any part of the
              world.
            </li>

            <li>
              <Term>Interpres Security IP</Term> refers to Interpres
              Security&apos;s existing proprietary or Confidential Information
              and the SaaS Services as well as all content, materials,
              equipment, inventions, software code, APIs, works of authorship,
              appliances, methodologies, technology, code, templates, tools,
              policies, records, working papers, knowledge, data or other
              intellectual property, written or otherwise developed, acquired,
              owned or otherwise obtained by Interpres Security in any manner.
            </li>
            <li>
              <Term>Law</Term> means any statute, law, ordinance, regulation,
              rule, code, order, constitution, treaty, common law, judgment,
              decree or other requirement or rule of any federal, state, local
              or foreign government or political subdivision thereof, or any
              arbitrator, court or tribunal of competent jurisdiction.
            </li>
            <li>
              <Term>Licensed Software</Term> means an executable version of
              Interpres Security&apos;s proprietary software delivered to
              Customer for use in connection with the SaaS Services.
            </li>
            <li>
              <Term>Loss</Term> means any and all losses, damages, liabilities,
              judgments, settlements, interest, awards, costs or expenses,
              including reasonable attorneys&apos; fees and the costs of
              enforcing any right to indemnification hereunder.
            </li>
            <li>
              <Term>Maintenance Release</Term> means any update, upgrade,
              release or other adaptation or modification of Licensed Software
              that Interpres Security may provide to Customer from time to time
              during the Trial Period, which may contain, among other things,
              error corrections, enhancements, improvements, or other changes to
              the user interface, functionality, compatibility, capabilities,
              performance, efficiency, or quality of the Licensed Software.
            </li>
            <li>
              <Term>Permitted Use</Term> means use of the SaaS Services
              (including the Licensed Software used in connection with the SaaS
              Services and Documentation) for the purposes of improving and
              optimizing cyber security capabilities of Customer&apos;s
              information technology systems.
            </li>
            <li>
              <Term>Person</Term> means an individual, corporation, partnership,
              joint venture, limited liability entity, governmental authority,
              unincorporated organization, trust, association or other entity.
            </li>
            <li>
              <Term>Representatives</Term> means, with respect to a Party, that
              Party&apos;s and its Affiliates&apos; employees, officers and
              directors, consultants, agents, independent contractors, service
              providers, sublicensees, subcontractors and legal advisors.
            </li>
            <li>
              <Term>SaaS Services</Term> means Interpres Security&apos;s hosted
              security solution, related hosting, maintenance and/or support
              services provided by Interpres Security for remote access and use
              by and for Customer and its Authorized Users, including any
              Documentation, all third party products and services managed by
              and under the exclusive control of Interpres Security in support
              of the hosted security solution such as cloud and edge
              infrastructure, email/text communications platforms, device
              management applications, and content delivery networks, and all
              updates thereto and any equipment or technology used by Interpres
              Security in connection with the foregoing.
            </li>
            <li>
              <Term>Third Party Content</Term> means all data, information,
              videos, feeds, social media content, posts, blogs, surveys,
              ratings, reviews, feedback or any other information collected or
              otherwise obtained by the Customer from an independent source, and
              all data derived thereof, such as reports, summaries, graphs and
              charts.
            </li>
          </ol>
        </li>

        <li>
          SaaS Services.
          <ol className={css.numberList}>
            <li>
              <U>SaaS Services</U>. Subject to and conditioned on
              Customer&apos;s acceptance and compliance with the terms and
              conditions of this Agreement, Interpres Security hereby grants to
              Customer a worldwide, non-exclusive, non-transferable right to
              access and use, and to permit its Authorized Users to access and
              use, the SaaS Services in accordance with this Agreement and
              solely for Customer to evaluate the SaaS Service for
              Customer&apos;s internal business purposes during the Trial
              Period. Customer may make a reasonable number of backup or archive
              copies of the Documentation at no additional charge, subject to
              Customer&apos;s confidentiality obligations set forth in{' '}
              <U>Section 7</U>.
            </li>
            <li>
              <U>License Grant</U>. Licensed Software may be delivered to
              Customer and/or its Authorized Users to be used solely in
              connection with the SaaS Services under this Agreement. Subject to
              and conditioned on Customer&apos;s acceptance and compliance with
              the terms and conditions of his Agreement, Interpres Security
              hereby grants to Customer a worldwide, non- exclusive,
              non-sublicensable, and non-transferrable limited license right to
              download, install, execute (in object code form) access, and use,
              and to permit its Authorized Users to access and use, any Licensed
              Software delivered to Customer by Interpres Security in connection
              with the SaaS Services in accordance with this Agreement and
              solely for Customer to evaluate the SaaS Services for
              Customer&apos;s internal business purposes during the Trial
              Period. At all times herein, whether or not expressly stated, the
              Parties&apos; rights and obligations with regard to the SaaS
              Services as provided in this Agreement shall be deemed to extend
              to the Licensed Software used in connection with the SaaS Services
              where applicable.
            </li>
            <li>
              <U>Scope of Licensed Access and Use.</U> Customer may install, use
              and run any Licensed Software only as necessary in connection with
              SaaS Services during the Trial Period and may make one copy of the
              Licensed Software solely for testing, disaster recovery, or
              archival purposes. Any copy of the Licensed Software made by
              Customer: (a) will remain the exclusive property of Interpres
              Security; (b) be subject to the terms and conditions of this
              Agreement; and (c) must include all copyright or other
              Intellectual Property Rights notices contained in the original.
            </li>
            <li>
              Authorized Users.
              <ol className={css.letterList}>
                <li>
                  <U>Selecting Authorized Users</U>. Customer agrees to obtain
                  separate credentials (e.g., user IDs and passwords) via the
                  SaaS Services for each Authorized User and may not permit the
                  sharing of Authorized User credentials.
                </li>
                <li>
                  <U>
                    Customer&apos;s Responsibilities Regarding Authorized Users
                  </U>
                  . Customer shall at all times be responsible for and expressly
                  assume the risks associated with all use of the SaaS Services
                  under an Authorized User&apos;s account, whether such action
                  was taken by an Authorized User or by another party, and
                  whether or not such action was authorized by an Authorized
                  User. This responsibility includes the security of each
                  Authorized User&apos;s credentials, and Customer agrees not to
                  share (and will instruct each Authorized User not to share)
                  such credentials with any other Person or entity, or otherwise
                  permit any other Person or entity to access or use the SaaS
                  Services.
                </li>
              </ol>
            </li>

            <li>
              <U>Open Source Licenses</U>. The SaaS Services includes certain
              open source components, which will be made available upon written
              request to Interpres Security. Customer&apos;s use of any open
              source software is subject to the terms of the applicable open
              source licenses. Ownership of all Intellectual Property Rights in
              any open source components will remain with the respective owners
              thereof, subject to Customer&apos;s rights under the applicable
              open source licenses.
            </li>
          </ol>
        </li>
        <li>
          {' '}
          Reservation of Rights and Restrictions.
          <ol className={css.numberList}>
            <li>
              <U>General Restrictions on Use</U>. Except as this Agreement
              expressly permits, and subject to Section 2.5 with respect to any
              open source components, Customer may not, and must not permit any
              other Person to: (a) copy the SaaS Services or Licensed Software,
              in whole or in part; (b) modify, correct, adapt, translate,
              enhance or otherwise prepare derivative works or improvements of
              any SaaS Services or Licensed Software; (c) rent, lease, lend,
              sell, sublicense, assign, distribute, publish, transfer or
              otherwise make available the SaaS Services or Licensed Software to
              any Person, including on or in connection with the internet or any
              time-sharing, service bureau, software as a service, cloud or
              other technology or service; (d) reverse engineer, disassemble,
              decompile, decode or adapt the SaaS Services or Licensed Software,
              or otherwise attempt to derive or gain access to the source code
              of the SaaS Services or Licensed Software, in whole or in part;
              (e) bypass or breach any security device or protection used for or
              contained in the SaaS Services, Licensed Software, or
              Documentation; (f) remove, delete, efface, alter, obscure,
              translate, combine, supplement or otherwise change any trademarks,
              terms of the Documentation, warranties, disclaimers, or
              Intellectual Property Rights, proprietary rights or other symbols,
              notices, marks or serial numbers on or relating to any copy of the
              SaaS Services or Documentation; (g) use the SaaS Services or
              Licensed Software in any manner or for any purpose that infringes,
              misappropriates or otherwise violates any Intellectual Property
              Right or other right of any Person, or that violates any
              applicable Law; (h) use the SaaS Services or Licensed Software for
              purposes of: (i) benchmarking or competitive analysis of the SaaS
              Services or Licensed Software; or (ii) developing, using or
              providing a competing software product or service; or (i) use the
              SaaS Services or Licensed Software in or in connection with the
              design, construction, maintenance, operation or use of any
              hazardous environments, systems or applications, any safety
              response systems or other safety-critical applications, or any
              other use or application in which the use or failure of the SaaS
              Services or Licensed Software could lead to personal injury or
              severe physical or property damage.
            </li>
            <li>
              <U>Delivery</U>. Interpres Security will deliver any applicable
              Licensed Software electronically to Customer as a self-configuring
              docker-installation.
            </li>
            <li>
              <U>Maintenance</U>. During the Trial Period, Interpres Security
              will provide Customer with all Maintenance Releases that Interpres
              Security may, in its sole discretion, make generally available to
              its customers at no additional charge. All Maintenance Releases
              are deemed Licensed Software subject to all applicable terms and
              conditions in this Agreement. Customer will install all
              Maintenance Releases as soon as practicable after receipt.
            </li>
            <li>
              <U>Modification to the SaaS Services</U>. Interpres Security
              reserves the right to modify the software underlying the SaaS
              Services, at any time and agrees to use commercially reasonable
              efforts to notify Customer of any such modifications to the extent
              that such modifications adversely affect functionality. Interpres
              Security reserves the right to improve or otherwise modify its
              internal computing or storage device, or network architecture at
              any time subject to maintaining appropriate industry standards of
              practice relating to the provision and security of the SaaS
              Services, and provided that any such modification does not
              materially diminish the core functionality or security of the SaaS
              Services.
            </li>
            <li>
              <U>No Implied Licenses</U>. Customer acknowledges that there are
              no licenses granted by implication under this Agreement. Interpres
              Security reserves all rights that are not expressly granted.
              Customer acknowledges that, as between the Parties, Interpres
              Security owns all Intellectual Property Rights and proprietary
              interests that are embodied in, or practiced by, the SaaS Services
              and Licensed Software.
            </li>
            <li>
              <U>Right of Access</U>. Customer understands that this Agreement
              grants certain rights of access only to the SaaS Services which
              may require delivery of a copy of Licensed Software to Customer or
              installation of a copy of Licensed Software upon any computers or
              systems under Customer&apos;s control. Customer shall use any such
              Licensed Software only in connection with the SaaS Services and
              only for the limited purpose set forth in this Agreement.
            </li>
          </ol>
        </li>
        <li>
          Intellectual Property Rights.
          <ol className={css.numberList}>
            <li>
              <U>Ownership</U>. Except for the limited rights expressly set
              forth herein, Interpres Security retains all Intellectual Property
              Rights and all other proprietary rights in and to the Interpres
              Security IP, the SaaS Services, and the Licensed Software.
              Customer agrees not to delete or alter the copyright, trademark,
              or other proprietary rights notices or markings appearing within
              the SaaS Services or Licensed Software as delivered. Customer
              agrees that the SaaS Services and applicable Licensed Software are
              provided on a non-exclusive basis and that no transfer of
              ownership of Intellectual Property Rights will occur. Customer
              further acknowledges and agrees that portions of the SaaS Services
              and Licensed Software, including but not limited to the source
              code and the specific design and structure of individual modules
              or programs, constitute or contain trade secrets and other
              Intellectual Property Rights of Interpres Security and its
              licensors.
            </li>
            <li>
              <U>Feedback</U>. Customer is under no duty to provide any
              suggestions, enhancement requests, or other feedback regarding the
              Services (<Term>Feedback</Term>). If the Customer chooses to offer
              Feedback to Interpres Security, Customer hereby grants Interpres
              Security a perpetual, irrevocable, non-exclusive, worldwide,
              fully-paid, sub-licensable, assignable license to incorporate into
              the Services or otherwise use any Feedback Interpres Security
              receives from the Customer. Customer also irrevocably waives in
              favor of Interpres Security any moral rights which it may have in
              such Feedback pursuant to applicable copyright law. Interpres
              Security acknowledges that any Feedback is provided on an
              <Quote>as-is</Quote> basis with no warranties of any kind.
            </li>
            <li>
              <U>Customer Cooperation and Notice of Infringement</U>. Customer
              must, during the Trial Period: (a) take commercially reasonable
              measures to safeguard the SaaS Services, Licensed Software, and
              Documentation (including all copies thereof) from infringement,
              misappropriation, theft, misuse or unauthorized access; (b) at
              Interpres Security&apos;s expense, take all such steps as
              Interpres Security may reasonably require to assist Interpres
              Security in maintaining the validity, enforceability and Interpres
              Security&apos; ownership of the Intellectual Property Rights in
              the SaaS Services, Licensed Software, and Documentation; (c)
              promptly notify Interpres Security in writing if Customer becomes
              aware of: (i) any actual or suspected infringement,
              misappropriation or other violation of Interpres Security&apos;
              Intellectual Property Rights in or relating to the SaaS Services,
              Licensed Software, or Documentation; or (ii) any claim that the
              SaaS Services, Licensed Software, or Documentation, including any
              production, use, marketing, sale or other disposition of the SaaS
              Services, Licensed Software or Documentation, in whole or in part,
              infringes, misappropriates or otherwise violates the Intellectual
              Property Rights or other rights of any Person; and (d) at
              Interpres Security&apos;s sole expense, fully cooperate with and
              assist Interpres Security in all reasonable ways in the conduct of
              any claim, suit, action or proceeding (each, an{' '}
              <Term>Action</Term>) by Interpres Security to prevent or abate any
              actual or threatened infringement, misappropriation or violation
              of Interpres Security&apos; rights in, and to attempt to resolve
              any claims relating to, the SaaS Services, Licensed Software, or
              Documentation.
            </li>
          </ol>
        </li>
        <li>
          Customer and Third Party Content.
          <ol className={css.numberList}>
            <li>
              <U>Customer Content</U>. Customer owns and shall retain all right,
              title, and interest, including, without limitation, all
              Intellectual Property Rights in and to the Customer Content, which
              shall be deemed Customer Confidential Information. Interpres
              Security has only those rights in and to the Customer Content as
              are expressly granted to it pursuant to this Agreement. Customer
              hereby grants to Interpres Security a worldwide, non-exclusive,
              non-transferable right and license to store, host, reproduce,
              maintain, process and transmit the Customer Content solely for
              provision of the Services in accordance with this Agreement.
              Notwithstanding the foregoing, Customer acknowledges and agrees
              that Interpres Security may collect, maintain, process and use
              diagnostic, technical, usage and related information, including
              information about Customer&apos;s computers, systems and software,
              that Interpres Security may gather periodically to improve the
              performance of the SaaS Services and/or Licensed Software so long
              as such usage data does not contain any Customer Content the{' '}
              <Term>Usage Data</Term>). Interpres Security will not share (other
              than with third parties providing services to Interpres Security
              who agree in writing to terms at least as restrictive regarding
              the processing of Usage Data as those set forth in this Agreement)
              or publicly make available any Usage Data that identifies
              Customer, or any of its Authorized Users, other data subjects, or
              customers. In addition, this Usage Data will be treated in
              accordance with Interpres Security&apos; privacy policy, as
              amended from time to time, which can be viewed at
              interpressecurity.com/privacy or a successor website address.
            </li>
            <li>
              <U>Required Consents and Approvals</U>. Prior to uploading
              Customer Content or Third Party Content to the SaaS Services or
              using the SaaS Services to collect or retrieve Customer Content or
              Third Party Content, Customer shall, at its own expense, obtain
              all licenses, consents and/or other permissions from appropriate
              third parties as may be necessary for Customer&apos;s use of the
              relevant Customer Content and/or Third Party Content as necessary
              to enable Customer to grant the rights granted by{' '}
              <U>Section 5.1</U>. Further, Customer is responsible for ensuring
              that retrieval, use and processing of Customer Content and Third
              Party Content, as contemplated in this Agreement, will not violate
              applicable rights of third party rights-holders, nor violate the
              requirements of applicable law. To the extent Customer accesses or
              uses any Third Party Content using the tools available through the
              SaaS Services, Customer shall adhere to any terms of service and
              privacy policies that apply to the publisher of the applicable
              third-party website or other third-party resource.
            </li>
            <li>
              <U>Content Disclaimers</U>.
              <ol className={css.letterList}>
                <li>
                  Interpres Security shall have no obligation to preview,
                  verify, flag, modify, filter or remove any Customer Content or
                  Third Party Content (although Interpres Security may do so in
                  its sole discretion), and Interpres Security shall not be
                  responsible for any failure to remove, or for any delay in
                  removing, harmful, inaccurate, unlawful or otherwise
                  objectionable Customer Content or Third Party Content.
                </li>
                <li>
                  Customer acknowledges that, as between the Parties, Customer
                  is. responsible for backup and archiving of any content
                  processed by the SaaS Services, including all Customer Content
                  and Third Party Content. Interpres Security shall not be
                  responsible or liable for the deletion, correction,
                  destruction, damage, loss or failure to store any Customer
                  Content or Third Party Content.
                </li>
                <li>
                  Interpres Security does not provide any warranty or support
                  under this Agreement for any non-Interpres Security products
                  or services, including without limitation, Customer Content
                  and/or Third Party Content.
                </li>
              </ol>
            </li>
            <li>
              <U>Compliance with Laws; Data Protection</U>.
              <ol className={css.letterList}>
                <li>
                  <U>By Interpres Security</U>. Interpres Security will provide
                  the SaaS Services in accordance with its obligations under
                  laws and government regulations applicable to Interpres
                  Security&apos;s provision of the SaaS Services to its
                  customers generally, including, without limitation those
                  related to data protection and data privacy, without regard to
                  Customer&apos;s particular use of the SaaS Services and
                  subject to Customer&apos;s use of the SaaS Services in
                  accordance with this Agreement. The terms of the SaaS Services
                  DPA (attached hereto as EXHIBIT B) are hereby incorporated by
                  reference and shall apply to the extent Customer Content
                  includes Personal Data, as defined in the SaaS Services DPA.
                </li>
                <li>
                  <U>By Customer</U>. Customer represents and warrants to
                  Interpres Security that its and its Authorized Users&apos; use
                  of SaaS Services will comply with all applicable laws,
                  including without limitation any privacy or data protection
                  laws applicable to Customer use of the SaaS Services to
                  process Personal Data, and that, without limiting the
                  foregoing, Customer Content will not contain any data for
                  which Customer does not have all rights, power and authority
                  necessary for its collection, use and processing as
                  contemplated by this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <U>Security Measures</U>. The SaaS Services may contain
              technological measures designed to prevent unauthorized or illegal
              use of the SaaS Services. Customer acknowledges and agrees that:
              (a) Interpres Security may use these and other lawful measures to
              verify Customer&apos;s compliance with the terms of this Agreement
              and enforce Interpres Security&apos; rights, including all
              Intellectual Property Rights, in and to the SaaS Services and
              Licensed Software; and (b) Interpres Security may deny any
              individual access to and/or use of the SaaS Services or Licensed
              Software on written notice to Customer if Interpres Security, in
              its sole discretion, believes that person&apos;s use of the SaaS
              Services or Licensed Software would violate any provision of this
              Agreement.
            </li>
          </ol>
        </li>
        <li>
          Fees and Payment.
          <ol className={css.numberList}>
            <li>
              <U>SaaS Fees</U>. Customer will not be obligated to pay any
              license fees or other charges of any kind in connection with this
              Agreement or with the use of the SaaS Services or Licensed
              Software for evaluation purposes, as contemplated under this
              Agreement. This Agreement does not obligate Customer to enter into
              any other agreement with Interpres Security, including any
              agreement to continue using the SaaS Services after expiration or
              termination of this Agreement. If Customer desires to continue
              using Interpres&apos; SaaS services following expiration or
              termination of this Agreement, the parties will negotiate a new
              agreement, which will specify the fees or rates that Customer will
              pay to Interpres Security for such services.
            </li>
          </ol>
        </li>
        <li>
          Confidentiality.
          <ol className={css.numberList}>
            <li>
              <U>Confidential Information</U>. In connection with this Agreement
              each Party (as the <Term>Disclosing Party</Term>) may disclose or
              make available to the other Party (as the{' '}
              <Term>Receiving Party</Term>) Confidential Information. Subject to{' '}
              <U>Section 7.2</U>, <Term>Confidential Information</Term> means
              information in any form or medium (whether oral, written,
              electronic or other) that the Disclosing Party considers
              confidential or proprietary, including information consisting of
              or relating to the Disclosing Party&apos;s technology, trade
              secrets, know-how, business operations, plans, strategies,
              customers, and pricing, and information with respect to which the
              Disclosing Party has contractual or other confidentiality
              obligations, whether or not marked, designated or otherwise
              identified as <Quote>confidential.</Quote> Without limiting the
              foregoing, the SaaS Services, Licensed Software, and Documentation
              are the Confidential Information of Interpres Security and the
              Customer Data is the Confidential Information of Customer.
            </li>
            <li>
              <U>Exclusions and Exceptions</U>. Confidential Information does
              not include information that, as may be demonstrated through
              written records: (a) was rightfully known to the Receiving Party
              without restriction on use or disclosure prior to such
              information&apos;s being disclosed or made available to the
              Receiving Party in connection with this Agreement; (b) was or
              becomes generally known by the public other than by the Receiving
              Party&apos;s or any of its Representatives&apos; noncompliance
              with this Agreement; (c) was or is received by the Receiving Party
              on a non-confidential basis from a third party that was not or is
              not, at the time of such receipt, under any obligation to maintain
              its confidentiality; or (d) was or is independently developed by
              the Receiving Party without reference to or use of any
              Confidential Information.
            </li>
            <li>
              <U>Protection of Confidential Information</U>. As a condition to
              being provided with any disclosure of or access to Confidential
              Information, the Receiving Party will: (a) not access or use
              Confidential Information other than as necessary to exercise its
              rights or perform its obligations under and in accordance with
              this Agreement; (b) not disclose or permit access to Confidential
              Information other than to its Representatives who: (i) need to
              know such Confidential Information for purposes of the Receiving
              Party&apos;s exercise of its rights or performance of its
              obligations under and in accordance with this Agreement; (ii) have
              been informed of the confidential nature of the Confidential
              Information and the Receiving Party&apos;s obligations under this{' '}
              <U>Section 7</U>; and (iii) are bound by written confidentiality
              and restricted use obligations at least as protective of the
              Confidential Information as the terms set forth in this{' '}
              <U>Section 7</U>; (c) safeguard the Confidential Information from
              unauthorized use, access or disclosure using at least the degree
              of care it uses to protect its similarly sensitive information and
              in no event less than a reasonable degree of care; and (d) ensure
              its Representatives&apos; compliance with, and be responsible and
              liable for any of its Representatives&apos; non-compliance with,
              the terms of this <U>Section 7</U>.
            </li>
            <li>
              <U>Compelled Disclosures</U>. If the Receiving Party or any of its
              Representatives is compelled by applicable Law to disclose any
              Confidential Information then, to the extent permitted by
              applicable Law, the Receiving Party must: (a) promptly, and prior
              to such disclosure, notify the Disclosing Party in writing of such
              requirement so that the Disclosing Party can seek a protective
              order or other remedy or waive its rights under <U>Section 7.3</U>
              ; and (b) provide reasonable assistance to the Disclosing Party,
              at the Disclosing Party&apos;s sole cost and expense, in opposing
              such disclosure or seeking a protective order or other limitations
              on disclosure. If the Disclosing Party waives compliance or, after
              providing the notice and assistance required under this{' '}
              <U>Section 7.4</U>, the Receiving Party remains required by Law to
              disclose any Confidential Information, the Receiving Party may
              disclose only that portion of the Confidential Information that
              the Receiving Party is legally required to disclose.
            </li>
          </ol>
        </li>
        <li>
          Term and Termination.
          <ol className={css.numberList}>
            <li>
              <U>Term</U>. The term of this Agreement commences as of the
              Effective Date and will continue in effect for thirty (30)
              calendar days (the <Term>Trial Period</Term>). At the close of
              business on the 30th day of the Trial Period, this Agreement shall
              terminate and all access provided to Customer and/or
              Customer&apos;s Authorized User(s) shall be terminated. If
              Customer wishes to continue to use Interpres&apos; Security&apos;s
              SaaS services, it shall enter into a separate agreement defining
              the terms and conditions of such engagement, provided that neither
              Party shall be obligated to enter into any future agreement with
              the other at the end of the Trial Period.
            </li>
          </ol>
        </li>
        <li>
          <U>Evaluation License</U>. Subject to the terms and conditions of this
          Agreement, Interpres Security hereby grants to Customer a
          non-exclusive, revocable, and non-transferable right, during the Trial
          Period to access and use the SaaS Services and any connected Licensed
          Software solely for Customer&apos;s internal, non-production
          evaluation purposes, free of charge and without payment of any license
          fees or other amounts to Interpres Security.
          <ol className={css.numberList}>
            <li>
              <U>Termination</U>. Interpres may terminate this Agreement and
              terminate Customer&apos;s and/or its Authorized User(s) access if
              Customer breaches any of the terms this Agreement. Additionally,
              either Party may terminate this Agreement at any time for any
              reason by giving written notice to the other Party.
            </li>
            <li>
              <U>Effect of Termination or Expiration</U>. On the expiration or
              earlier termination of this Agreement:
              <ol className={css.letterList}>
                <li>
                  all rights, licenses and authorizations granted to Customer
                  and/or. Customer&apos;s Authorized User(s) hereunder will
                  immediately terminate and Customer must: (i) immediately cease
                  all use of and other activities with respect to the SaaS
                  Services, Licensed Software, and Documentation; and (ii)
                  within 15 days deliver to Interpres Security, or at Interpres
                  Security&apos;s written request destroy, and permanently erase
                  from all devices and systems Customer directly or indirectly
                  controls, access to the SaaS Services and Licensed Software,
                  the Documentation, and Interpres Security&apos;s Confidential
                  Information, including all documents, files and tangible
                  materials (and any partial and complete copies) containing,
                  reflecting, incorporating or based on any of the foregoing,
                  whether or not modified or merged into other materials; and
                  (iii) certify to Interpres Security in a signed written
                  instrument that it has complied with the requirements of this
                  <U>Section 9.2</U>.
                </li>
              </ol>
            </li>
            <li>
              Surviving Terms. The provisions set forth in the following
              Sections, and any other right, obligation or provision under this
              Agreement that, by its nature, should survive termination or
              expiration of this Agreement, will survive any expiration or
              termination of this Agreement: this Section 9, Section 1
              (Definitions), Section 4 (Intellectual Property Rights), Section 7
              (Confidentiality), Section 11 (Indemnification), Section 12
              (Limitations of Liability) and Section 15 (Miscellaneous).
            </li>
          </ol>
        </li>
        <li>
          Representations and Warranties.
          <ol className={css.numberList}>
            <li>
              <U>Mutual Representations and Warranties</U>. Each Party
              represents, warrants and covenants to the other Party that (a) it
              has the capacity and authority to accept the terms of and be bound
              by this Agreement (b) it has the full right, power and authority
              to enter into and perform its obligations and grant the rights,
              licenses and authorizations it grants and is required to grant
              under this Agreement; (c) if applicable, the acceptance of this
              Agreement by Customer&apos;s representative has been duly
              authorized by all necessary corporate or organizational action or
              as otherwise required to so act for Customer; and (d) when
              Customer or Customer&apos;s representative clicks its acceptance,
              this Agreement will constitute the legal, valid and binding
              obligation of each Party, enforceable against each Party in
              accordance with its terms.
            </li>
            <li>
              <U>Limited Warranty</U>. Subject to the limitations and conditions
              set forth in this <U>Section 10</U>, Interpres Security warrants
              to Customer that during the Trial Period, the SaaS Services and
              Licensed Software will substantially conform in all material
              respects to the specifications set forth in the Documentation. The
              limited warranties set forth in <U>Section 10.2</U> apply only if
              Customer: (a) notifies Interpres Security in writing of the
              warranty breach within thirty (30) days of the purported breach of
              warranty; and (b) as of the date of notification, is in compliance
              with all terms and conditions of this Agreement.
            </li>
            <li>
              <U>Exceptions</U>. Notwithstanding any provisions to the contrary
              in this Agreement, the limited warranty set forth in{' '}
              <U>Section 10.2</U> does not apply to problems arising out of or
              relating to: (a) any operation or use of, or other activity
              relating to, the SaaS Services or Licensed Software other than as
              specified in the Documentation, including any combination,
              operation or use of the SaaS Services or Licensed Software in or
              with, any technology (including any software, hardware, firmware,
              system or network) or service not specified for Customer&apos;s
              use in the Documentation; (b) Customer&apos;s or any third
              party&apos;s negligence, abuse, misapplication or misuse of the
              SaaS Services or Licensed Software, including any use of the SaaS
              Services or Licensed Software other than as specified in the
              Documentation or expressly authorized by Interpres Security in
              writing; (c) repairs, alterations, and/or modifications which are
              performed by other than Interpres Security or its agents, with or
              without authorization; (d) Customer&apos;s or a third party&apos;s
              system or network failure or malfunction, including configuration
              changes or improper maintenance by Customer of its computer
              systems or networks; (e) any open source components, beta
              software, software that Interpres Security makes available for
              testing or demonstration purposes, temporary software modules or
              software for which Interpres Security does not receive a license
              fee; (f) Customer&apos;s breach of any material provision of this
              Agreement; or (g) any other circumstances or causes outside of the
              reasonable control of Interpres Security (including abnormal
              physical or electrical stress).
            </li>
            <li>
              Remedial Efforts. If Interpres Security breaches, or is alleged to
              have breached, any of the warranties set forth in{' '}
              <U>Section 10</U>, Interpres Security will, at its option, either
              (a) modify the SaaS Services or repair the Licensed Software, (so
              that such modified SaaS Service or repaired Licensed Software
              meets or exceeds the warranties set forth in <U>Section 10.2</U>);
              or (b) terminate this Agreement. The remedies set forth in this{' '}
              <U>Section 10.4</U> shall be Interpres Security&apos;s sole and
              exclusive obligation, and Customer&apos;s sole and exclusive
              remedy, for any such breach of warranty.
            </li>
            <li>
              <U>DISCLAIMER OF WARRANTIES</U>. EXCEPT FOR THE EXPRESS LIMITED
              WARRANTIES SET FORTH IN SECTION 10.2, THE SAAS SERVICES, LICENSED
              SOFTWARE, DOCUMENTATION AND OTHER PRODUCTS, INFORMATION, MATERIALS
              AND SERVICES PROVIDED BY INTERPRES SECURITY ARE PROVIDED.
              <Quote>AS IS.</Quote> INTERPRES SECURITY HEREBY DISCLAIMS ALL
              WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHER
              (INCLUDING ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR
              TRADE PRACTICE), AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT. NEITHER INTERPRES SECURITY NOR ITS SUPPLIERS
              WARRANTS THAT THE OPERATION OF THE SAAS SERVICES OR LICENSED
              SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT ALL ERRORS IN
              THE SAAS SERVICES WILL BE CORRECTED. ALL OPEN SOURCE COMPONENTS
              AND OTHER THIRD- PARTY MATERIALS ARE PROVIDED <Quote>AS IS</Quote>
              .
            </li>
          </ol>
        </li>
        <li>
          Indemnification.
          <ol className={css.numberList}>
            <li>
              <U>Interpres Security Indemnification</U>. Interpres Security will
              indemnify, defend and hold harmless Customer and its officers,
              directors, employees, and agents (each, a{' '}
              <Term>Customer Indemnitee</Term>) from and against any and all
              Losses incurred by the Customer Indemnitee arising out of or
              relating to any claim, demand, lawsuit or action, in each case,
              initiated by a third party (a <Term>Third Party Claim</Term>) to
              the extent that such Losses arise from any allegation that the
              SaaS Services or Licensed Software, or any use of the SaaS
              Services or Licensed Software, in accordance with this Agreement
              (including the Documentation) infringes any Intellectual Property
              Right. The foregoing obligation does not apply to the extent that
              such Third Party Claim or Losses arise from any allegation of or
              relating to any: (i) open source components or other materials and
              information, in any form or medium, that are not proprietary to
              Interpres Security; (ii) patent issued on a patent application
              published after the Effective Date; (iii) modification of the SaaS
              Services or Licensed Software other than: (A) by Interpres
              Security or its contractor in connection with this Agreement; or
              (B) with Interpres Security&apos; express written authorization
              and in strict accordance with Interpres Security&apos; written
              directions and specifications; (iii) use of the SaaS Services or
              Licensed Software after Interpres Security&apos;s notice to
              Customer of such activity&apos;s alleged or actual infringement,
              misappropriation or other violation of a third party&apos;s
              rights; (iv) events or circumstances outside of Interpres
              Security&apos; commercially reasonable control (including any
              third-party hardware, software or system bugs, defects or
              malfunctions); or (v) Action or Losses for which Customer is
              obligated to indemnify Interpres Security pursuant to{' '}
              <U>Section 11.2</U>.
            </li>
            <li>
              <U>Customer Indemnification</U>. Customer will indemnify, defend
              and hold harmless Interpres Security and its Affiliates, and each
              of its and their respective officers, directors, employees,
              agents, subcontractors, (each, an{' '}
              <Term>Interpres Security Indemnitee</Term>) from and against any
              and all Losses incurred by the Interpres Security Indemnitee in
              connection with any Third Party Claim to the extent that such
              Losses arise out of or relate to (a) any breach of{' '}
              <U>Section 4</U>, (b) a misuse of the SaaS Services or Licensed
              Software, or (c) of or relating to gross negligence, abuse,
              misapplication, misuse or more culpable act or omission (including
              recklessness or willful misconduct) by or on behalf of Customer or
              any of its Representatives with respect to the SaaS Services,
              Licensed Software or Documentation or otherwise in connection with
              this Agreement.
            </li>
            <li>
              <U>Indemnification Procedure</U>. Each Party will promptly notify
              the other Party in writing of any claim for which such Party
              believes it is entitled to be indemnified pursuant to{' '}
              <U>Section 11.1</U> or <U>Section 11.2</U>. The Party seeking
              indemnification (the
              <Term>Indemnitee</Term>) will cooperate with the other Party (the{' '}
              <Term>Indemnitor</Term>) at the Indemnitor&apos;s sole cost and
              expense. The Indemnitor will immediately take control of the
              defense and investigation of such Action and will employ counsel
              reasonably acceptable to the Indemnitee to handle and defend the
              same, at the Indemnitor&apos;s sole cost and expense. The
              Indemnitee&apos;s failure to perform any obligations under this{' '}
              <U>Section 11.3</U> will not relieve the Indemnitor of its
              obligations under this <U>Section 11</U> except to the extent that
              the Indemnitor can demonstrate that it has been prejudiced as a
              result of such failure. The Indemnitee may participate in and
              observe the proceedings at its own cost and expense with counsel
              of its own choosing.
            </li>
            <li>
              <U>Mitigation</U>. If any part of the SaaS Services or Licensed
              Software is, or in Interpres Security&apos; opinion is likely to
              be, claimed to infringe, misappropriate or otherwise violate any
              third-party Intellectual Property Right, or if Customer&apos;s use
              of the SaaS Services or Licensed Software is enjoined or
              threatened to be enjoined, Interpres Security may, at its option
              and sole cost and expense: (a) obtain the right for Customer to
              continue to use the SaaS Services as contemplated by this
              Agreement; (b) modify or replace the SaaS Services, in whole or in
              part, to seek to make the SaaS Services non-infringing, while
              providing materially equivalent features and functionality, and
              such modified or replacement software will constitute SaaS
              Services under this Agreement; or (c) if, after Interpres
              Security&apos; exercise of commercially reasonable efforts, none
              of the remedies set forth in this Section 11.4 is reasonably
              available to Interpres Security, terminate this Agreement, in its
              entirety or with respect to the affected part or feature of the
              SaaS Services, effective immediately on written notice to
              Customer, in which event Customer will cease all use of the SaaS
              Services and Documentation immediately on receipt of
              Customer&apos;s notice.
            </li>
            <li>
              <U>Sole Remedy</U>. THIS SECTION 11 SETS FORTH CUSTOMER&apos;S
              SOLE. REMEDIES AND INTERPRES SECURITY&apos; SOLE LIABILITY AND
              OBLIGATION FOR. ANY ACTUAL, THREATENED OR ALLEGED CLAIMS THAT THIS
              AGREEMENT OR ANY SUBJECT MATTER HEREOF (INCLUDING THE SAAS
              SERVICES, LICENSED SOFTWARE, AND DOCUMENTATION) INFRINGES,
              MISAPPROPRIATES OR OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY
              RIGHTS OF ANY THIRD PARTY.
            </li>
          </ol>
        </li>
        <li>
          Limitations of Liability.
          <ol className={css.numberList}>
            <li>
              <U>EXCLUSION OF DAMAGES</U>. EXCEPT AS EXPRESSLY OTHERWISE
              PROVIDED IN SECTION 12.2, (a) IN NO EVENT WILL EITHER PARTY BE
              LIABLE FOR CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
              SPECIAL, ENHANCED OR PUNITIVE DAMAGES, IN EACH CASE REGARDLESS OF
              WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH
              LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE
              FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR
              OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
            </li>

            <li>
              <U>Exceptions to Limitations of Liability</U>. The exclusions and
              limitations in <U>Section 12.1</U> and this <U>Section 12.2</U> do
              not apply to any liability for either Party&apos;s gross
              negligence or willful misconduct.
            </li>
          </ol>
        </li>
        <li>
          <B>
            <U>Export Regulation</U>
          </B>
          Customer will not itself, or permit any other Person to, export,
          re-export or release, directly or indirectly the SaaS Services or
          Licensed Software to any country, jurisdiction or Person to which the
          export, re-export or release of the SaaS Services or Licensed Software
          (a) is prohibited by applicable Law or (b) without first completing
          all required undertakings (including obtaining any necessary export
          license or other governmental approval).
        </li>

        <li>
          Force Majeure.
          <ol className={css.numberList}>
            <li>
              <U>No Breach or Default</U>. In no event will either Party be
              liable or responsible to the other Party, or be deemed to have
              defaulted under or breached this Agreement, for any failure or
              delay in fulfilling or performing any term of this Agreement when
              and to the extent such failure or delay is caused by any
              circumstances beyond such Party&apos;s reasonable control (a{' '}
              <Term>Force Majeure Event</Term>), including acts of God, flood,
              fire, earthquake or explosion, war, terrorism, invasion, riot or
              other civil unrest, embargoes or blockades in effect on or after
              the date of this Agreement, national or regional emergency,
              strikes, labor stoppages or slowdowns or other industrial
              disturbances, passage of Law or any action taken by a governmental
              or public authority, including imposing an export or import
              restriction, quota or other restriction or prohibition or any
              complete or partial government shutdown, or national or regional
              shortage of adequate power or telecommunications or
              transportation. Either Party may terminate this Agreement if a
              Force Majeure Event continues substantially uninterrupted for a
              period of 30 days or more.
            </li>
            <li>
              <U>Affected Party Obligations</U>. In the event of any failure or
              delay caused by a Force Majeure Event, the affected Party will
              give prompt written notice to the other Party stating the period
              of time the occurrence is expected to continue and use
              commercially reasonable efforts to end the failure or delay and
              minimize the effects of such Force Majeure Event.
            </li>
          </ol>
        </li>
        <li>
          Miscellaneous.
          <ol className={css.numberList}>
            <li>
              <U>Further Assurances</U>. On a Party&apos;s reasonable request,
              the other Party will, at the requesting Party&apos;s sole cost and
              expense, execute and deliver all such documents and instruments,
              and take all such further actions, as may be necessary to give
              full effect to this Agreement.
            </li>
            <li>
              <U>Relationship of the Parties</U>. The relationship between the
              Parties is that of independent contractors. Nothing contained in
              this Agreement will be construed as creating any agency,
              partnership, joint venture or other form of joint enterprise,
              employment or fiduciary relationship between the Parties, and
              neither Party will have authority to contract for or bind the
              other Party in any manner whatsoever.
            </li>
            <li>
              <U>Public Announcements</U>. Neither Party will issue or release
              any announcement, statement, press release or other publicity or
              marketing materials relating to this Agreement or, unless
              expressly permitted under this Agreement, otherwise use the other
              Party&apos;s trademarks, service marks, trade names, logos, domain
              names or other indicia of source, association or sponsorship, in
              each case, without the prior written consent of the other Party,
              which will not be unreasonably delayed or withheld, provided,
              however, that Interpres Security may, without Customer&apos;s
              consent, include Customer&apos;s name and/or other indicia in its
              lists of Interpres Security&apos; current and/or, as the case may
              be, former customers of Interpres Security in promotional and
              marketing materials.
            </li>
            <li>
              <U>Notices</U>. Except as otherwise expressly set forth in this
              Agreement, any notice, request, consent, claim, demand, waiver or
              other communication under this Agreement will have legal effect
              only if in writing and addressed to a Party as follows (or to such
              other address or such other Person that such addressee Party may
              designate from time to time in accordance with this{' '}
              <U>Section 15.4</U>
              ):
              <p>If to Interpres Security:</p>
              <div className={css.indented}>
                {' '}
                Interpres Security Inc.: S Morgans Point Rd, #214 Mount
                Pleasant, SC 29466
                <br />
                E-mail:{' '}
                <a href="mailto:support@interpressecurity.com">
                  support@interpressecurity.com
                </a>
                <br />
                Attention: Interpres Support
              </div>
              <p>If to Customer:</p>
              <div className={css.indented}>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
              <p>
                Notices sent in accordance with this <U>Section 15.4</U> will be
                deemed effectively given: (a) when received, if delivered by
                hand, with signed confirmation of receipt; (b) when received, if
                sent by a nationally recognized overnight courier, signature
                required, or if mailed by certified or registered mail, return
                receipt requested, postage prepaid; and (c) when sent, if by
                e-mail, (in each case, with confirmation of transmission), if
                sent during the addressee&apos;s normal business hours, and on
                the next business day, if sent after the addressee&apos;s normal
                business hours.
              </p>
            </li>
            <li>
              <U>Entire Agreement</U>. This Agreement, together with all
              attached Exhibits and any other documents or other terms and
              conditions incorporated herein by reference, constitutes the sole
              and entire agreement of the parties with respect to the subject
              matter of this Agreement and supersedes all prior and
              contemporaneous understandings, agreements, representations and
              warranties, both written and oral, with respect to such subject
              matter. In the event of any inconsistency between the statements
              made in the body of this Agreement, the related Exhibits,
              schedules, attachments and appendices (other than an exception
              expressly set forth as such therein) and any other documents
              incorporated herein by reference, the following order of
              precedence governs: (a) first, this Agreement, excluding its
              Exhibits, schedules, attachments and appendices; (b) second, the
              exhibits, schedules, attachments and appendices to this Agreement
              as of the Effective Date; and (c) third, any other documents
              incorporated herein by reference.
            </li>
            <li>
              <U>Assignment</U>. Customer will not assign or otherwise transfer
              any of its rights, or delegate or otherwise transfer any of its
              obligations or performance, under this Agreement without Interpres
              Security&apos;s prior written consent, which consent may not
              unreasonably be delayed or withheld. Any purported assignment,
              delegation or transfer in violation of this Section 15.6 is void.
              This Agreement is binding on and inures to the benefit of the
              parties hereto and their respective permitted successors and
              assigns.
            </li>
            <li>
              <U>No Third-Party Beneficiaries</U>. This Agreement is for the
              sole benefit of the Parties hereto and their respective permitted
              successors and permitted assigns and nothing herein, express or
              implied, confers on any other Person any legal or equitable right,
              benefit or remedy of any nature whatsoever under or by reason of
              this Agreement.
            </li>
            <li>
              <U>Amendment and Modification; Waiver</U>. No amendment to or
              modification of this Agreement is effective unless it is in
              writing and signed by an authorized representative of each Party.
              No waiver by any Party of any of the provisions hereof will be
              effective unless explicitly set forth in writing and signed by the
              Party so waiving. Except as otherwise set forth in this Agreement,
              no failure to exercise, or delay in exercising, any rights,
              remedy, power or privilege arising from this Agreement will
              operate or be construed as a waiver thereof; nor will any single
              or partial exercise of any right, remedy, power or privilege
              hereunder preclude any other or further exercise thereof or the
              exercise of any other right, remedy, power or privilege.
            </li>
            <li>
              <U>Severability</U>. If any provision of this Agreement is found
              to be invalid or otherwise unenforceable, the further conditions
              of this Agreement will remain fully effective and the Parties will
              be bound by obligations which approximate, as closely as possible,
              the effect of the provision found invalid or unenforceable,
              without being themselves invalid or unenforceable.
            </li>
            <li>
              <U>Governing Law; Submission to Jurisdiction</U>. This Agreement
              is governed by and construed in accordance with the internal laws
              of the Commonwealth of Virginia without giving effect to any
              choice or conflict of law provision or rule that would require or
              permit the application of the laws of any jurisdiction other than
              those of the Commonwealth of Virginia. Any legal suit, action or
              proceeding arising out of or related to this Agreement or the
              licenses granted hereunder will be instituted exclusively in the
              federal courts of the United States or the courts of the
              Commonwealth of Virginia in each case located in the county of
              Fairfax, and each Party irrevocably submits to the exclusive
              jurisdiction of such courts in any such suit, action or
              proceeding. Service of process, summons, notice or other document
              by mail to such Party&apos;s address set forth herein will be
              effective service of process for any suit, action or other
              proceeding brought in any such court.
            </li>
            <li>
              <U>Equitable Remedies</U>. Each Party acknowledges and agrees that
              a breach or threatened breach by such Party of any of its
              obligations under Section 3 (Reservations of Rights and
              Restrictions), Section 7 (Confidentiality), Section 4
              (Intellectual Property Rights) or Section 11 (Indemnification) of
              this Agreement would cause the other Party irreparable harm for
              which monetary damages would not be an adequate remedy and that,
              in the event of such breach or threatened breach, the other Party
              will be entitled to equitable relief, including in a restraining
              order, an injunction, specific performance and any other relief
              that may be available from any court of competent jurisdiction,
              without any requirement to post a bond or other security, or to
              prove actual damages or that monetary damages are not an adequate
              remedy. Such remedies are not exclusive and are in addition to all
              other remedies that may be available at law, in equity or
              otherwise.
              <p>
                BY CUSTOMER CLICKING ITS ACCEPTANCE OF THESE TERMS AND
                CONDITIONS the Parties hereto have executed this Agreement as of
                the date acceptance.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>

    {/* Mini-document #2 */}
    <div className={css.centered + ' ' + css.newPage}>
      EXHIBIT A<br />
      Interpres Security End User License Agreement
    </div>

    {/* Document #3 */}
    <div className={css.newPage}>
      <div className={css.bold + ' ' + css.centered}>
        Interpres Security, Inc.
        <br />
        Interpres Security End User License Agreement
      </div>
      <hr />
      <p>
        CAREFULLY READ THIS END USER LICENSE AGREEMENT (<Term>AGREEMENT</Term>)
        WHICH GOVERNS (A) YOUR INSTALLATION AND PERMITTED USE OF THE INTERPRES
        SECURITY SOFTWARE APPLICATION, INCLUDING ALL ENHANCEMENTS, VERSIONS AND
        MODIFICATIONS, (COLLECTIVELY, THE <Term>SOFTWARE</Term>) WITHIN YOUR
        CONTROLLED ENVIRONMENT (THE <Term>ON-PREM LICENSE</Term>) AND/OR (B)
        YOUR ACCESS TO THE SOFTWARE HOSTED BY INTERPRES SECURITY (THE{' '}
        <Term>SAAS SERVICES</Term>). YOUR USE OF THE SOFTWARE AND ACCESS TO THE
        SAAS SERVICES ARE PROVIDED TO YOU UNDER THE TERMS AND CONDITIONS OF THIS
        AGREEMENT AND THE ACCOMPANYING DOCUMENTATION (THE
        <Term>DOCUMENTATION</Term>). <Term>INTERPRES SECURITY</Term> MEANS
        INTERPRES SECURITY, INC. <Term>YOU</Term> OR THE <Term>CUSTOMER</Term>{' '}
        MEANS THE INDIVIDUAL PERSON DOWNLOADING, INSTALLING, USING OR OTHERWISE
        ACCESSING THE SOFTWARE OR THE ORGANIZATION FOR WHICH SUCH PERSON
        REPRESENTS AND ON WHOSE BEHALF SUCH INDIVIDUAL IS INSTALLING, USING OR
        OTHERWISE ACCESSING THE SOFTWARE AND/OR THE SAAS SERVICES. YOU REPRESENT
        THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ENTITY OR ORGANIZATION TO
        THIS AGREEMENT. YOU AND INTERPRES SECURITY MAY EACH ALSO BE REFERRED TO
        AS A <Quote>PARTY</Quote> AND TOGETHER, THE <Quote>PARTIES</Quote>.
      </p>
      <p>
        BY CLICKING THE <Quote>I ACCEPT THE TERMS OF THIS LICENSE</Quote> BUTTON
        OR OTHERWISE DOWNLOADING, INSTALLING, OR USING THE SOFTWARE OR ACCESSING
        THE SAAS SERVICES, YOU AGREE TO BE BOUND BY AND BECOME A PARTY TO THIS
        AGREEMENT, AND YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND AND
        AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, (2) YOU ARE OF LEGAL
        AGE TO FORM A BINDING CONTRACT WITH INTERPRES SECURITY, AND (3) YOU HAVE
        THE AUTHORITY TO ENTER INTO THIS AGREEMENT PERSONALLY OR FOR THE
        ORGANIZATION ON WHOSE BEHALF YOU ARE ACTING AS THE CUSTOMER. IF YOU DO
        NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, YOU MAY NOT DOWNLOAD,
        INSTALL, ACCESS, OR USE THE SOFTWARE.
      </p>
      <p className={css.bold}>
        THIS AGREEMENT REQUIRES THE USE OF ARBITRATION TO RESOLVE DISPUTES,
        RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES
        AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
      </p>
      <ol className={css.numberList}>
        <li>
          SAAS SERVICES. Subject to the terms and conditions of this Agreement,
          and, if the Customer has elected to purchase access to the SaaS
          Services, then subject to payment of all applicable subscription or
          user fees or payment in accordance with an Indirect Order through a
          Channel Partner (as appropriate), Interpres Security hereby grants to
          Customer a worldwide, non-exclusive, non-transferable right to access
          and use, and to permit its Authorized Users to access and use, the
          SaaS Services solely for Customer&apos;s and its Authorized
          Users&apos; internal business purposes during the applicable
          Subscription Term. Interpres Security shall use its commercially
          reasonable efforts to provide the SaaS Services in accordance with its
          then current service level commitments. Interpres Security shall
          electronically deliver or make available the SaaS Services, such that
          no tangible media passes to Customer. Customer may make a reasonable
          number of backup or archive copies of the Documentation at no
          additional charge, subject to Customer&apos;s confidentiality
          obligations set forth in Section 7.
          <ol className={css.numberList}>
            <li>
              <U>Authorized Users</U>.
              <ol className={css.letterList}>
                <li>
                  <U>Selecting Authorized Users</U>. Customer agrees to obtain
                  separate credentials (e.g., user IDs and passwords) via the
                  SaaS Services for each Authorized User and may not permit the
                  sharing of Authorized User credentials.
                </li>
                <li>
                  <U>
                    Customer&apos;s Responsibilities Regarding Authorized Users
                  </U>
                  . Customer shall at all times be responsible for and expressly
                  assume the risks associated with all use of the SaaS Services
                  under an Authorized User&apos;s account (including for the
                  payment of Fees related to such use), whether such action was
                  taken by an Authorized User or by another party, and whether
                  or not such action was authorized by an Authorized User. This
                  responsibility includes the security of each Authorized
                  User&apos;s credentials, and Customer agrees not to share (and
                  will instruct each Authorized User not to share) such
                  credentials with any other person or entity, or otherwise
                  permit any other person or entity to access or use the SaaS
                  Services, except to the extent permitted in a SOW.
                </li>
                <li>
                  Interpres Security reserves the right to refuse registration
                  of, or to cancel, login IDs that it reasonably believes to
                  violate the terms and conditions set forth in this Agreement,
                  in which case Interpres Security will promptly inform Customer
                  in writing of such refusal or cancellation. In addition to the
                  rights set forth in this Agreement, Interpres Security may
                  suspend Customer&apos;s access and use of the Interpres
                  Security Services if there is an unusual and material spike or
                  increase in Customer&apos;s use of the Interpres Security
                  Services or Interpres Security reasonably suspects or knows
                  that any Customer use is fraudulent or materially and
                  negatively impacting the operating capability of the Interpres
                  Security Services. Interpres Security will provide notice
                  prior to such suspension if permitted by applicable law or
                  unless Interpres Security reasonably believes that providing
                  such notice poses a risk to the security of the Interpres
                  Security Services. Interpres Security will promptly reinstate
                  Customer&apos;s access and use once the issue has been
                  resolved.
                </li>
              </ol>
            </li>
            <li>
              <U>Modification to the SaaS Services</U>. Interpres Security
              reserves the right to modify the software underlying the SaaS
              Services, at any time and agrees to use commercially reasonable
              efforts to notify Customer of any such modifications to the extent
              that such modifications adversely affect functionality. Interpres
              Security reserves the right to improve or otherwise modify its
              internal computing or storage device, or network architecture at
              any time subject to maintaining appropriate industry standards of
              practice relating to the provision and security of the SaaS
              Services, and provided that any such modification does not
              materially diminish the core functionality or security of the SaaS
              Services.
            </li>
            <li>
              <U>Right of Access</U>. Customer understands that this Agreement
              grants certain rights of access only to the SaaS Services, and
              that nothing in this Section may be interpreted to require
              delivery of a copy of any of the Software to Customer or
              installation of a copy of such Software upon any computers or
              systems under Customer&apos;s control.
            </li>
          </ol>
        </li>
        <li>
          LICENSE GRANT AND USAGE. Subject to the terms and conditions of this.
          Agreement, and, if the Customer has elected to purchase an On-Prem
          License to use the Software, then subject to payment of all applicable
          license fees or payment in accordance with an Indirect Order through a
          Channel Partner (as appropriate), Interpres Security grants Customer a
          non-exclusive, non-transferable, non-sublicensable license to
          download, install and use a copy of the Software solely for its
          internal business purposes in accordance with this Agreement.
          <ol className={css.numberList}>
            <li>
              <U>Updates</U>. Customer understands that the Software is
              evolving. As a result, Interpres Security may require Customer to
              accept updates to the Software. Customer acknowledges and agrees
              that Interpres Security may update the Software with or without
              notifying the Customer. Customer may need to update third-party
              software from time to time in order to use the Software. Any
              future release, update, or other addition to functionality of the
              Software shall be subject to the terms of this Agreement, unless
              Interpres Security expressly states otherwise. Neither Interpres
              Security nor any of its suppliers is obligated to provide any
              services, updates or upgrades to the Software.
            </li>
          </ol>
        </li>
        <li>
          RESTRICTIONS
          <ol className={css.numberList}>
            <li>
              <U>Restrictions on Use</U>. Except as this Agreement expressly
              permits, and subject to Section 3.2 with respect to any open
              source components, Customer may not, and must not permit any other
              person to: (a) copy the SaaS Services or the Software, in whole or
              in part; (b) modify, correct, adapt, translate, enhance or
              otherwise prepare derivative works or improvements of any SaaS
              Services; (c) rent, lease, lend, sell, sublicense, assign,
              distribute, publish, transfer or otherwise make available the SaaS
              Services or the Software to any Person, including on or in
              connection with the internet or any time-sharing, service bureau,
              software as a service, cloud or other technology or service; (d)
              reverse engineer, disassemble, decompile, decode or adapt the SaaS
              Services or the Software, or otherwise attempt to derive or gain
              access to the source code of the SaaS Services or the Software, in
              whole or in part; (e) bypass or breach any security device or
              protection used for or contained in the SaaS Services or the
              Software or Documentation; (f) remove, delete, efface, alter,
              obscure, translate, combine, supplement or otherwise change any
              trademarks, terms of the Documentation, warranties, disclaimers,
              or intellectual property rights, proprietary rights or other
              symbols, notices, marks or serial numbers on or relating to any
              copy of the SaaS Services or the Software or Documentation; (g)
              use the SaaS Services or the Software in any manner or for any
              purpose that infringes, misappropriates or otherwise violates any
              Intellectual Property Right or other right of any Person, or that
              violates any applicable Law; (h) use the SaaS Services or the
              Software for purposes of: (i) benchmarking or competitive analysis
              of the SaaS Services or the Software; or (ii) developing, using or
              providing a competing software product or service; (i) use the
              SaaS Services or the Software in or in connection with the design,
              construction, maintenance, operation or use of any hazardous
              environments, systems or applications, any safety response systems
              or other safety-critical applications, or any other use or
              application in which the use or failure of the SaaS Services or
              the Software could lead to personal injury or severe physical or
              property damage; (j) access the SaaS Services or the Software in
              order to build a similar or competitive product or service or (k)
              access or use the SaaS Services or the Software to identify
              vulnerabilities in it or publish any information on how to
              circumvent the SaaS Services or the Software.
            </li>
            <li>
              <U>Third Party Code</U>. The Software and the SaaS Services are
              delivered with certain items of independent, third-party code that
              are licensed under separate terms provided by the authors or
              licensors, including certain open source components (
              <Term>Third-Party Code</Term>). This Third-Party Code is licensed
              under the terms of the license that accompanies such Third- Party
              Code and not this Agreement. Nothing in this Agreement limits
              Customer&apos;s rights under, or grants Customer rights that
              supersede, the terms and conditions of any applicable license for
              any Third-Party Code delivered with the Software or the SaaS
              Services. Except for Section 11 and Section 12, none of the terms
              of this Agreement apply to such Third-Party Code. In particular,
              nothing in this Agreement restricts Customer&apos;s right to copy,
              modify, and distribute such Third-Party Code. Notwithstanding any
              contrary provisions in this Agreement, for Third Party Code
              licensed under the LGPL, if applicable, Customer may modify only
              the portions of the Software that are linked with such Third Party
              Code solely for Customer&apos;s own use. Ownership of all
              intellectual property rights in any Third-Party Code will remain
              with the respective owners thereof, subject to Customer&apos;s
              rights under the applicable license for such Third-Party Code.
            </li>
          </ol>
        </li>
        <li>
          MAINTENANCE OR SUPPORT. Unless expressly and otherwise agreed by the
          Parties, the Software and/or the SaaS Services are provided with
          Interpres Security&apos;s then-available support and maintenance terms
          and conditions.
        </li>
        <li>
          OWNERSHIP OF SOFTWARE AND THE SAAS SERVICES.
          <ol className={css.numberList}>
            <li>
              Interpres Security, its suppliers and/or its licensors own all
              worldwide right, title and interest in and to the Software and/or
              the SaaS Services, including all worldwide patent rights
              (including patent applications and disclosures); copyright rights
              (including copyrights, copyright registration and copy rights with
              respect to computer software, software design, software code,
              software architecture, firmware, programming tools, graphic user
              interfaces, reports, dashboard, business rules, use cases,
              screens, alerts, notifications, drawings, specifications and
              databases); moral rights; trade secrets and other rights with
              respect to confidential or proprietary information; know-how;
              other rights with respect to inventions, discoveries, ideas,
              improvements, techniques, formulae, algorithms, processes,
              schematics, testing procedures, technical information and other
              technology; and any other intellectual and industrial property
              rights, whether or not subject to registration or protection; and
              all rights under any license or other arrangement with respect to
              the foregoing. Except as expressly stated in this Agreement,
              Interpres Security does not grant Customer any intellectual
              property rights in the Software and/or the SaaS Services, and all
              right, title, and interest in and to all copies of the Software
              and/or the SaaS Services not expressly granted remain with
              Interpres Security, its suppliers and/or its licensors. The
              Software and/or the SaaS Services are copyrighted and protected by
              the laws of the United States and other countries, and
              international treaty provisions. Customer acknowledges that the
              Software and/or the SaaS Services are licensed and not sold.
            </li>
            <li>
              <U>No Implied Licenses</U>. Customer acknowledges that there are
              no licenses granted by implication under this Agreement. Interpres
              Security reserves all rights that are not expressly granted.
            </li>
            <li>
              <U>Feedback</U>. Customer is under no duty to provide any
              suggestions, enhancement requests, or other feedback regarding the
              Services (<Term>Feedback</Term>). If the Customer chooses to offer
              Feedback to Interpres Security, Customer hereby grant Interpres
              Security a perpetual, irrevocable, non-exclusive, worldwide,
              fully-paid, sub-licensable, assignable license to incorporate into
              the Services or otherwise use any Feedback Interpres Security
              receives from the Customer. Customer also irrevocably waives in
              favor of Interpres Security any moral rights which it may have in
              such Feedback pursuant to applicable copyright law. Interpres
              Security acknowledges that any Feedback is provided on an{' '}
              <Quote>as-is</Quote> basis with no warranties of any kind.
            </li>
            <li>
              <U>Customer Cooperation and Notice of Infringement</U>. Customer
              must, during the Term: (a) take commercially reasonable measures
              to safeguard the Software, the SaaS Services and Documentation
              (including all copies thereof) from infringement,
              misappropriation, theft, misuse or unauthorized access; (b) at
              Interpres Security&apos; expense, take all such steps as Interpres
              Security may reasonably require to assist Interpres Security in
              maintaining the validity, enforceability and Interpres
              Security&apos; ownership of the intellectual property rights in
              the Software, the SaaS Services and Documentation; (c) promptly
              notify Interpres Security in writing if Customer becomes aware of:
              (i) any actual or suspected infringement, misappropriation or
              other violation of Interpres Security&apos; intellectual property
              rights in or relating to the Software, the SaaS Services or
              Documentation; or (ii) any claim that the Software, the SaaS
              Services or Documentation, including any production, use,
              marketing, sale or other disposition of the Software, the SaaS
              Services or Documentation, in whole or in part, infringes,
              misappropriates or otherwise violates the intellectual property
              rights or other rights of any Person; and (d) at Interpres
              Security&apos; sole expense, fully cooperate with and assist
              Interpres Security in all reasonable ways in the conduct of any
              claim, suit, action or proceeding (each, an <Term>Action</Term>)
              by Interpres Security to prevent or abate any actual or threatened
              infringement, misappropriation or violation of Interpres
              Security&apos; rights in, and to attempt to resolve any claims
              relating to, the Software, the SaaS Services or Documentation.
            </li>
          </ol>
        </li>
        <li>
          CUSTOMER AND THIRD PARTY CONTENT.
          <ol className={css.numberList}>
            <li>
              <U>Customer Content</U>. Customer owns and shall retain all right,
              title, and interest, including, without limitation, all
              intellectual property rights in and to the Customer data,
              information or instructions (the <Term>Customer Content</Term>
              ), which shall be deemed Customer Confidential Information.
              Interpres Security has only those rights in and to the Customer
              Content as are expressly granted to it pursuant to this Agreement.
              Customer hereby grants to Interpres Security a worldwide,
              non-exclusive, non-transferable right and license to store, host,
              reproduce, maintain, process and transmit the Customer Content
              solely for performing its obligations in accordance with this
              Agreement. Notwithstanding the foregoing, Customer acknowledges
              and agrees that Interpres Security may collect, maintain, process
              and use diagnostic, technical, usage and related information,
              including information about Customer&apos;s computers, systems and
              software, that Interpres Security may gather periodically to
              improve the performance of the SaaS Services or supporting the
              Software so long as such usage data does not contain any Customer
              Content (the <Term>Usage Data</Term>). Interpres Security will not
              share (other than with third parties providing services to
              Interpres Security who agree in writing to terms at least as
              restrictive regarding the processing of Usage Data as those set
              forth in this Agreement) or publicly make available any Usage Data
              that identifies Customer, or any of its Authorized Users, other
              data subjects, or customers. In addition, this Usage Data will be
              treated in accordance with Interpres Security&apos; privacy
              policy, as amended from time to time, which can be viewed at
              interpressecurity.com/privacy or a successor website address.
            </li>
            <li>
              <U>Required Consents and Approvals</U>. Prior to uploading or
              using Customer Content or Third Party Content to the SaaS Services
              or Software or using the Software or the SaaS Services to collect
              or retrieve Customer Content or Third Party Content, Customer
              shall, at its own expense, obtain all licenses, consents and/or
              other permissions from appropriate third parties as may be
              necessary for Customer&apos;s use of the relevant Customer Content
              and/or Third Party Content as necessary to enable Customer to
              grant the rights granted by Section 6.1. Further, Customer is
              responsible for ensuring that retrieval, use and processing of
              Customer Content and Third Party Content, as contemplated in this
              Agreement, will not violate applicable rights of third party
              rights-holders, nor violate the requirements of applicable law. To
              the extent Customer accesses or uses any Third Party Content using
              the tools available through the SaaS Services or Software,
              Customer shall adhere to any terms of service and privacy policies
              that apply to the publisher of the applicable third-party website
              or other third-party resource.
            </li>
            <li>
              <U>Content Disclaimers</U>.
              <ol className={css.letterList}>
                <li>
                  Interpres Security shall have no obligation to preview,
                  verify, flag, modify, filter or remove any Customer Content or
                  Third Party Content (although Interpres Security may do so in
                  at its sole discretion), and Interpres Security shall not be
                  responsible for any failure to remove, or for any delay in
                  removing, harmful, inaccurate, unlawful or otherwise
                  objectionable Customer Content or Third Party Content.
                </li>
                <li>
                  Customer acknowledges that, as between the Parties, Customer
                  is responsible for backup and archiving of any content
                  processed by the SaaS Services or the Software, including all
                  Customer Content and Third Party Content. Interpres Security
                  shall not be responsible or liable for the deletion,
                  correction, destruction, damage, loss or failure to store any
                  Customer Content or Third Party Content.
                </li>
                <li>
                  Interpres Security does not provide any warranty or support
                  under this Agreement for any non-Interpres Security products
                  or services, including without limitation, Customer Content
                  and/or Third Party Content.
                </li>
              </ol>
            </li>
            <li>
              <U>Compliance with Laws; Data Protection</U>.
              <ol className={css.letterList}>
                <li>
                  <U>By Interpres Security</U>. Interpres Security will provide
                  the SaaS Services in accordance with its obligations under
                  laws and government regulations applicable to Interpres
                  Security&apos; provision of the SaaS Services to its customers
                  generally, including, without limitation those related to data
                  protection and data privacy, without regard to Customer&apos;s
                  particular use of the SaaS Services and subject to
                  Customer&apos;s use of the SaaS Services in accordance with
                  this Agreement. The terms of Interpres Security then-current
                  SaaS Services DPA are hereby incorporated by reference and
                  shall apply to the extent Customer Content includes Personal
                  Data, as defined in the SaaS Services DPA.
                </li>
                <li>
                  <U>By Customer</U>. Customer represents and warrants to
                  Interpres Security that its and its Authorized Users&apos; use
                  of SaaS Services and Software will comply with all applicable
                  laws, including without limitation any privacy or data
                  protection laws applicable to Customer use of the SaaS
                  Services or Software to process Personal Data, and that,
                  without limiting the foregoing, Customer Content will not
                  contain any data for which Customer does not have all rights,
                  power and authority necessary for its collection, use and
                  processing as contemplated by this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <U>Security Measures</U>. The SaaS Services may contain
              technological measures designed to prevent unauthorized or illegal
              use of the SaaS Services. Customer acknowledges and agrees that:
              (a) Interpres Security may use these and other lawful measures to
              verify Customer&apos;s compliance with the terms of this Agreement
              and enforce Interpres Security&apos; rights, including all
              intellectual property rights, in and to the SaaS Services; and (b)
              Interpres Security may deny any individual access to and/or use of
              the SaaS Services on written notice to Customer if Interpres
              Security, in its sole discretion, believes that person&apos;s use
              of the SaaS Services would violate any provision of this
              Agreement.
            </li>
          </ol>
        </li>
        <li>
          CONFIDENTIALITY.
          <ol className={css.numberList}>
            <li>
              <U>Confidential Information</U>. In connection with this Agreement
              each Party (as the <Term>Disclosing Party</Term>) may disclose or
              make available to the other Party (as the{' '}
              <Term>Receiving Party</Term>) Confidential Information. Subject to
              Section 7.2, <Quote>Confidential Information</Quote>
              means information in any form or medium (whether oral, written,
              electronic or other) that the Disclosing Party considers
              confidential or proprietary, including information consisting of
              or relating to the Disclosing Party&apos;s technology, trade
              secrets, know-how, business operations, plans, strategies,
              customers, and pricing, and information with respect to which the
              Disclosing Party has contractual or other confidentiality
              obligations, whether or not marked, designated or otherwise
              identified as <Quote>confidential.</Quote> Without limiting the
              foregoing, the SaaS Services and Documentation are the
              Confidential Information of Interpres Security and the Customer
              Data is the Confidential Information of Customer.
            </li>
            <li>
              <U>Exclusions and Exceptions</U>. Confidential Information does
              not include information that, as may be demonstrated through
              written records: (a) was rightfully known to the Receiving Party
              without restriction on use or disclosure prior to such
              information&apos;s being disclosed or made available to the
              Receiving Party in connection with this Agreement; (b) was or
              becomes generally known by the public other than by the Receiving
              Party&apos;s or any of its Representatives&apos; noncompliance
              with this Agreement; (c) was or is received by the Receiving Party
              on a non-confidential basis from a third party that was not or is
              not, at the time of such receipt, under any obligation to maintain
              its confidentiality; or (d) was or is independently developed by
              the Receiving Party without reference to or use of any
              Confidential Information.
            </li>
            <li>
              <U>Protection of Confidential Information</U>. As a condition to
              being provided with any disclosure of or access to Confidential
              Information, the Receiving Party will: (a) not access or use
              Confidential Information other than as necessary to exercise its
              rights or perform its obligations under and in accordance with
              this Agreement; (b) not disclose or permit access to Confidential
              Information other than to its Representatives who: (i) need to
              know such Confidential Information for purposes of the Receiving
              Party&apos;s exercise of its rights or performance of its
              obligations under and in accordance with this Agreement; (ii) have
              been informed of the confidential nature of the Confidential
              Information and the Receiving Party&apos;s obligations under this
              Section 7.3; and (iii) are bound by written confidentiality and
              restricted use obligations at least as protective of the
              Confidential Information as the terms set forth in this Section 7;
              (c) safeguard the Confidential Information from unauthorized use,
              access or disclosure using at least the degree of care it uses to
              protect its similarly sensitive information and in no event less
              than a reasonable degree of care; and (d) ensure its
              Representatives&apos; compliance with, and be responsible and
              liable for any of its Representatives&apos; non-compliance with,
              the terms of this Section 7.
            </li>
            <li>
              <U>Compelled Disclosures</U>. If the Receiving Party or any of its
              Representatives is compelled by applicable Law to disclose any
              Confidential Information then, to the extent permitted by
              applicable Law, the Receiving Party must: (a) promptly, and prior
              to such disclosure, notify the Disclosing Party in writing of such
              requirement so that the Disclosing Party can seek a protective
              order or other remedy or waive its rights provided herein; and (b)
              provide reasonable assistance to the Disclosing Party, at the
              Disclosing Party&apos;s sole cost and expense, in opposing such
              disclosure or seeking a protective order or other limitations on
              disclosure. If the Disclosing Party waives compliance or, after
              providing the notice and assistance required by this Agreement,
              the Receiving Party remains required by Law to disclose any
              Confidential Information, the Receiving Party may disclose only
              that portion of the Confidential Information that the Receiving
              Party is legally required to disclose.
            </li>
            <li>
              <U>Injunctive relief</U>. The Parties expressly acknowledge and
              agree that no adequate remedy exists at law for an actual or
              threatened breach of this Section and that, in the event of an
              actual or threatened breach of the provisions of this Section, the
              non-breaching Party will be entitled to seek immediate injunctive
              and other equitable relief, without waiving any other rights or
              remedies available to it. Each Party will promptly notify the
              other in writing if it becomes aware of any violations of the
              confidentiality obligations set forth in this Section.
            </li>
          </ol>
        </li>
        <li>
          FEES AND PAYMENT.
          <ol className={css.numberList}>
            <li>
              <U>License or Subscription Fees</U>. In consideration of the
              rights granted to Customer under this Agreement and depending upon
              whether the Customer has elected to purchase access to the SaaS
              Services or an On-Prem License, Customer will pay to Interpres
              Security the applicable license fee (for the On-Prem License) (the{' '}
              <Term>License Fee</Term>) or the applicable subscription fee (for
              access to the SaaS Services) (the <Term>Subscription Fee</Term>{' '}
              and together with the License Fee, the <Term>Fees</Term>) as the
              Parties may mutually agree upon.
            </li>
            <li>
              <U>Taxes</U>. All Fees and other amounts payable by Customer under
              this Agreement are exclusive of taxes and similar assessments.
              Without limiting the foregoing, Customer is responsible for all
              sales, service, use and excise taxes, and any other similar taxes,
              duties and charges of any kind imposed by any federal, state or
              local governmental or regulatory authority on any amounts payable
              by Customer hereunder, other than any taxes imposed on Interpres
              Security&apos; income, revenues, gross receipts, personnel or real
              or personal property or other assets.
            </li>
            <li>
              <U>Payment</U>. Customer will pay all amounts due and owing under
              this Agreement, if applicable, within 30 days after the date of
              execution of this agreement, or sooner if agreed to by the
              Parties. Customer must make all payments hereunder in US dollars
              and to the address or account as Interpres Security may specify in
              writing from time to time.
            </li>
            <li>
              <U>Late Payment</U>. If Customer fails to make any applicable
              payment when due then, in addition to all other remedies that may
              be available to Interpres Security: (a) Interpres Security may
              charge interest on the past due amount at the rate of 1.5% per
              month calculated daily and compounded monthly or, if lower, the
              highest rate permitted under applicable Law; (b) Customer will
              reimburse Interpres Security for all reasonable costs incurred by
              Interpres Security in collecting any late payment of amounts due
              or related interest, including attorneys&apos; fees, court costs
              and collection agency fees; and (c) if such failure continues for
              30 days following written notice thereof, Interpres Security may:
              (i) disable Customer&apos;s use of the Software or the SaaS
              Services (including by means of a disabling code, technology or
              device); (ii) withhold, suspend or revoke its grant of a license
              hereunder; and/or (iii) terminate this Agreement in accordance
              with Section 9.3.
            </li>
            <li>
              <U>No Deductions or Setoffs</U>. All amounts payable to Interpres
              Security under this Agreement must be paid by Customer to
              Interpres Security in full without any setoff, recoupment,
              counterclaim, deduction, debit or withholding for any reason
              (other than any deduction or withholding of tax as may be required
              by applicable Law).
            </li>
          </ol>
        </li>
        <li>
          LICENSE OR SUBSCRIPTION TERM.
          <ol className={css.numberList}>
            <li>
              <U>Term</U>. This period of time that the Customer may download,
              install and us the Software is referred to as the{' '}
              <Term>License Term</Term> and the period of time that the Customer
              may access the SaaS Services shall be referred to as the{' '}
              <Term>Subscription Term</Term> and collectively as the{' '}
              <Term>Term.</Term> Each Term shall commence on the date when
              Customer accepts this Agreement (as described in the preamble
              above) and remains in full force and effect while the Customer
              uses the Software or access the SaaS Services, unless terminated
              earlier in accordance with this Agreement.
            </li>
            <li>
              <U>Prior Use</U>. Notwithstanding the foregoing, if the Customer
              used the Software prior to the date Customer accepted this
              Agreement, Customer hereby acknowledges and agrees that this
              Agreement commenced on the date Customer first used the Software
              and will remain in full force and effect while Customer uses the
              Software during the Term, unless earlier terminated in accordance
              with this Agreement.
            </li>
            <li>
              <U>Termination for Breach or Insolvency</U>. Either Party may
              terminate this Agreement upon thirty (30) days&apos; prior written
              notice if the other Party materially breaches this Agreement and
              does not cure such breach within thirty (30) days following
              receipt of notice specifying the breach. Either Party may also
              have the right to terminate this Agreement in the event the other
              party (i) becomes insolvent, (ii) becomes subject to a petition in
              bankruptcy filed by or against it that is not dismissed within
              thirty days of the filing of such petition, (iii) is placed under
              the control of a receiver, liquidator or committee of creditors,
              or (iv) dissolves, ceases to function as a going concern or to
              conduct its business in the normal course.
            </li>
            <li>
              <U>Effect of Termination</U>. Upon termination of this Agreement,
              Customer&apos;s right to use the Software or access the SaaS
              Services will automatically terminate immediately. Customer
              understands that any termination this Agreement may involve
              deletion of Customer&apos;s content associated therewith from
              Interpres Security&apos;s databases, if any. Interpres Security
              will not have any liability whatsoever to Customer for any
              suspension or termination, including for deletion of
              Customer&apos;s content. All provisions of this Agreement which by
              their nature should survive, shall survive termination of this
              Agreement, including without limitation, ownership provisions,
              warranty disclaimers, and limitation of liability.
            </li>
          </ol>
        </li>
        <li>
          WARRANTY AND DISCLAIMERS.
          <ol className={css.numberList}>
            <li>
              With respect to an On-Prem License, Interpres Security warrants
              that during the period commencing on the installation date and
              ending ninety (90) days thereafter, the Software will perform, in
              all material respects, in conformance with its specifications and
              the Documentation. With respect to access to the SaaS Services,
              Interpres Security warrants that during the Subscription Term, the
              SaaS Services will perform, in all material respects, in
              conformance with its specifications and the Documentation. For any
              breach of the foregoing warranty, Interpres Security&apos;s sole
              liability, and Customer&apos;s sole and exclusive remedy, shall be
              for Interpres Security to promptly correct such material
              non-conformance, or, if Interpres Security cannot correct such
              material non-conformance in a timely manner, Customer may
              terminate this Agreement.
            </li>
            <li>
              EXCEPT FOR THE FOREGOING EXPRESS LIMITED WARRANTY, THE SOFTWARE
              AND THE SAAS SERVICES (INCLUDING, WITHOUT LIMITATION, ANY CONTENT)
              ARE PROVIDED <Quote>AS IS</Quote> AND <Quote>AS AVAILABLE,</Quote>{' '}
              WITH ALL FAULTS, AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS,
              IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
              FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE
              OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH, TO THE MAXIMUM
              EXTENT PERMITTED UNDER APPLICABLE LAW, ARE EXPRESSLY DISCLAIMED.
              CUSTOMER AGREES THAT ALL USE OF THE SOFTWARE AND THE SAAS SERVICES
              ARE SOLELY AT ITS OWN RISK.
            </li>
            <li>
              INTERPRES SECURITY IS NOT OBLIGATED TO INDEMNIFY YOU FROM ANY
              CLAIM OR ACTION, INCLUDING INTELLECTUAL PROPERTY INFRINGEMENT
              CLAIMS OR ACTIONS.
            </li>
          </ol>
        </li>
        <li>
          EXCLUSION AND LIMITATION OF REMEDIES AND DAMAGES.
          <ol className={css.numberList}>
            <li>
              IN NO EVENT SHALL INTERPRES SECURITY, NOR ITS DIRECTORS,
              EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE
              LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY
              OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SOFTWARE OR
              THE SAAS SERVICES (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF
              PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT,
              INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND
              WHATSOEVER, OR SUBSTITUTE GOODS OR SERVICES, (II) FOR YOUR
              RELIANCE ON THE SOFTWARE OR THE SAAS SERVICES (III) FOR ANY DIRECT
              DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE FEES RECEIVED BY
              INTERPRES SECURITY FROM CUSTOMER OR (IV) FOR ANY MATTER BEYOND
              INTERPRES SECURITY&apos;S REASONABLE CONTROL. THIS LIMITATION OF
              LIABILITY SHALL BE ENFORCED TO THE MAXIMUM EXTENT PERMITTED UNDER
              APPLICABLE LAW
            </li>
          </ol>
        </li>
        <li>
          COVENANT NOT TO SUE. Customer hereby covenants not to assert any claim
          or commence any suit against Interpres Security&apos;s suppliers or
          partners with respect to the Software or the SaaS Services.
        </li>
        <li>
          EXPORT. The Software, the SaaS Services and related technology are
          subject to U.S. export control laws and may be subject to export or
          import regulations in other countries. Customer agrees not to export,
          re-export, or transfer, directly or indirectly, any U.S. technical
          data acquired from Interpres Security, or any products utilizing such
          data, in violation of the United States export laws or regulations.
          Customer shall indemnify and hold Interpres Security harmless from any
          and all claims, losses, liabilities, damages, fines, penalties, costs
          and expenses (including attorney&apos;s fees) arising from or relating
          to any breach of its obligations under this Section. Customer&apos;s
          obligations under this Section shall survive the expiration or
          termination of this Agreement.
        </li>
        <li>
          RESTRICTED RIGHTS. The Software and the SaaS Services are deemed to be{' '}
          <Quote>commercial</Quote> and{' '}
          <Quote>commercial computer software documentation</Quote> as defined
          in DFARS Section 227.7202 and FAR Section 12.212, as applicable. Any
          use, modification, reproduction release, performance, display or
          disclosure of the Software or the SaaS Services by the U.S. Government
          shall be solely in accordance with the terms of this Agreement.
        </li>
        <li>
          AGREEMENT TO ARBITRATE.
          <ol className={css.numberList}>
            <li>
              <U>General</U>. The Parties to this Agreement agree that all
              claims or disputes arising out of or relating to this Agreement,
              apart from those subject to Section 7.5 (Injunctive Relief) will
              be resolved by mandatory and binding arbitration in accordance
              with this Section.
            </li>
            <li>
              <U>Type of Arbitration</U>. The Parties agree that arbitration
              shall be conducted in accordance with the CPR Institute for
              Dispute Resolution Rules for Non-Administered Arbitration then
              currently in effect, by a sole arbitrator. Notwithstanding any
              Party&apos;s place of incorporation, or principal place of
              business, each Party hereby agrees that any arbitration pursuant
              to this Agreement shall be governed by the United States Federal
              Arbitration Act, 9 U.S.S.§§ 1-16.
            </li>
            <li>
              <U>Place of arbitration</U>. Unless mutually agreed upon between
              the Parties in writing, the place of arbitration shall be the
              County of Fairfax, Virginia, and the entire arbitration shall be
              conducted and concluded in no more than thirty days. Each Party
              further expressly agrees and consents to the jurisdiction of
              Fairfax, Virginia to enforce any judgment upon the award rendered
              by the arbitrator.
            </li>
            <li>
              <U>Costs</U>. If any arbitration or court action is commenced by
              either Party, the substantially prevailing Party in that action is
              entitled to recover from the other Party its attorneys&apos; fees
              and costs (including arbitration fees and costs and expert witness
              fees) incurred in connection therewith.
            </li>
          </ol>
        </li>
        <li>
          GENERAL. This Agreement is governed by the laws of the Commonwealth of
          Virginia without regard to conflict of law principles. Customer may
          not assign this Agreement or any of its rights or obligations
          hereunder without the prior written consent of Interpres Security and
          any such assignment shall be null and void. Interpres Security
          expressly reserves the right to assign this Agreement and to delegate
          any of its obligations hereunder. If any provision or provisions of
          this Agreement shall be held to be invalid, illegal or unenforceable,
          the validity, legality and enforceability of the remaining provisions
          shall not in any way be affected or impaired thereby. In the event
          that any provision of this Agreement is found to be contrary to law,
          then such provision shall be construed as nearly as possible to
          reflect the intention of the parties, with the other provisions
          remaining in full force and effect. Any notice to Customer may be
          provided by email. This Agreement constitutes the entire agreement of
          the parties concerning its subject matter and supersedes any and all
          prior or contemporaneous, written or oral negotiations,
          correspondence, understandings and agreements between the parties
          respecting the subject matter of this Agreement. No amendment to this
          Agreement will be binding unless evidenced by a writing signed by the
          party against whom it is sought to be enforced. The failure of any
          party to enforce any of the provisions hereof shall not be construed
          to be a waiver of the right of such party thereafter to enforce such
          provisions. In addition, the terms of this Agreement shall control any
          conflicting, inconsistent, and/or additional standard terms or
          conditions on any purchase order, invoice or other document submitted
          by Customer, notwithstanding any provision to the contrary in any such
          document. In no event will either Party be liable or responsible the
          other Party, or be deemed to have defaulted under or breached this
          Agreement, for any failure or delay in fulfilling or performing any
          term of this Agreement (except for any payment obligation), when and
          to the extent such failure or delay is caused by any circumstances
          beyond such Party&apos;s reasonable control or act of god.
        </li>
        <li>
          QUESTIONS, COMPLAINTS, AND CLAIMS. If you have any questions,
          complaints or claims with respect to the Software or the SaaS
          Services, please contact us at: Interpres Security, Inc., S Morgans
          Point Rd, #214 Mount Pleasant, SC 29466 or{' '}
          <a href="mailto:support@interpressecurity.com">
            support@interpressecurity.com
          </a>
          . If you feel that Customer&apos;s concerns have been addressed
          incompletely, we invite you to let us know for further investigation.
        </li>
      </ol>
    </div>

    {/* Mini-document #4 */}
    <div className={css.centered + ' ' + css.newPage}>
      EXHIBIT B<br />
      Data Procession Addendum
    </div>

    {/* Document #5 */}
    <div className={css.newPage}>
      <div className={css.bold + ' ' + css.centered}>
        Interpres Security Data Processing Agreement
      </div>
      <p>
        This Data Processing Addendum, including its Annexes, (<Term>DPA</Term>)
        forms part of Customer&apos;s subscription agreement or other written or
        electronic agreement between Interpres Security and Customer for the
        access to Interpres Security&apos;s security platform (hereinafter
        defined as
        <Term>SaaS Services</Term>) (the <Term>Agreement</Term>) to reflect the
        parties&apos; agreement with regard to the Processing of Personal Data.
      </p>
      <p>
        By signing or agreeing to be bound to the terms and condition of the
        Agreement, Customer enters into this DPA on behalf of itself and, to the
        extent required under applicable Data Protection Laws and Regulations,
        in the name and on behalf of its Authorized Affiliates, if and to the
        extent Interpres Security processes Personal Data for which such
        Authorized Affiliates qualify as the Controller. For the purposes of
        this DPA only, and except where indicated otherwise, the term{' '}
        <Quote>Customer</Quote>
        shall include Customer and Authorized Affiliates. All capitalized terms
        not defined herein shall have the meaning set forth in the Agreement.
      </p>
      <p>
        In the course of providing the SaaS Services to Customer pursuant to the
        Agreement, Interpres Security may Process Personal Data on behalf of
        Customer and the Parties agree to comply with the following provisions
        with respect to any Personal Data, each acting reasonably and in good
        faith.
      </p>
      {/* Despite being two exhibits, this is really one continued ordered list,
          so reset the numbers here to match the end of the previous one. */}
      <ol className={css.numberList} style={{ counterReset: 'item 17' }}>
        <li>
          Definitions.
          <ul className={css.noBullet}>
            <li>
              <Term>Affiliate</Term> means any entity that directly or
              indirectly controls, is controlled by, or is under common control
              with the subject entity. <Quote>Control,</Quote> for purposes of
              this definition, means direct or indirect ownership or control of
              more than 50% of the voting interests of the subject entity.
            </li>
            <li>
              <Term>Authorized Affiliate</Term> means any of Customer&apos;s
              Affiliate(s) which (a) is subject to the data protection laws and
              regulations of the European Union, the European Economic Area
              and/or their member states, Switzerland and/or the United Kingdom,
              and (b) is permitted to use the SaaS Services pursuant to the
              Agreement between Customer and Interpres Security, but has not
              signed its own Order Form with Interpres Security and is not a{' '}
              <Quote>Customer</Quote> as defined under this DPA.
            </li>
            <li>
              <Term>California Personal Information</Term> means Personal Data
              that is subject to the protection of the CCPA.
            </li>
            <li>
              <Term>CCPA</Term> means California Civil Code Sec. 1798.100 et
              seq. (also known as the California Consumer Privacy Act of 2018),
              as amended by the California Privacy Rights Act of 2020.
            </li>
            <li>
              <Term>Consumer</Term>, <Term>Business</Term>, <Term>Sell</Term>{' '}
              and <Term>SaaS Service Provider</Term> will have the meanings
              given to them in the CCPA.
            </li>
            <li>
              <Term>Controller</Term> means the natural or legal person, public
              authority, agency or other body which, alone or jointly with
              others, determines the purposes and means of the Processing of
              Personal Data.
            </li>
            <li>
              <Term>Customer Data</Term> means what is defined in the Agreement
              as <Quote>Customer Data</Quote> or <Quote>Your Data</Quote>,
              provided that such data is electronic data and information
              submitted by or for Customer to the SaaS Services.
            </li>
            <li>
              <Term>Data Protection Laws</Term> means all applicable worldwide
              legislation relating to data protection and privacy which applies
              to the respective party in the role of Processing Personal Data in
              question under the Agreement, including without limitation
              European Data Protection Laws, the CCPA and the data protection
              and privacy laws of Australia and Singapore; in each case as
              amended, repealed, consolidated or replaced from time to time.
            </li>
            <li>
              <Term>Data Subject</Term> means the individual to whom Personal
              Data relates.
            </li>
            <li>
              <Term>Europe</Term> means the European Union, the European
              Economic Area and/or their member states, Switzerland and the
              United Kingdom.
            </li>
            <li>
              <Term>European Data</Term> means Personal Data that is subject to
              the protection of European Data Protection Laws.
            </li>
            <li>
              <Term>European Data Protection Laws</Term> means data protection
              laws applicable in Europe, including: (i) Regulation 2016/679 of
              the European Parliament and of the Council on the protection of
              natural persons with regard to the processing of personal data and
              on the free movement of such data (General Data Protection
              Regulation) (<Term>GDPR</Term>); (ii) Directive 2002/58/EC
              concerning the processing of personal data and the protection of
              privacy in the electronic communications sector; and (iii)
              applicable national implementations of (i) and (ii); or (iii) GDPR
              as it forms parts of the United Kingdom domestic law by virtue of
              Section 3 of the European Union (Withdrawal) Act 2018 (
              <Quote>UK GDPR</Quote>); and (iv) Swiss Federal Data Protection
              Act on 19 June 1992 and its Ordinance (<Quote>Swiss DPA</Quote>);
              in each case, as may be amended, superseded or replaced.
            </li>
            <li>
              <Term>Instructions</Term> means the written, documented
              instructions issued by a Controller to a Processor, and directing
              the same to perform a specific or general action with regard to
              Personal Data (including, but not limited to, depersonalizing,
              blocking, deletion, making available).
            </li>
            <li>
              <Term>Permitted Affiliates</Term> means any of Customer&apos;s
              Affiliates that (a) are permitted to use the SaaS Services
              pursuant to the Agreement, but have not signed their own separate
              agreement with Interpres Security and are not a{' '}
              <Quote>Customer</Quote> as defined under the Agreement, (b)
              qualify as a Controller of Personal Data Processed by Interpres
              Security, and (c) are subject to European Data Protection Laws.
            </li>
            <li>
              <Term>Personal Data</Term> means any information relating to an
              identified or identifiable inliidual where (a) such information is
              contained within Customer Data; and (b) is protected similarly as
              personal data, personal information or personally identifiable
              information under applicable Data Protection Laws.
            </li>
            <li>
              <Term>Personal Data Breach</Term> means a breach of security
              leading to the accidental or unlawful destruction, loss,
              alteration, unauthorized disclosure of, or access to, Personal
              Data transmitted, stored or otherwise Processed by Interpres
              Security and/or its Sub-Processors in connection with the
              provision of the Subscription SaaS Services.
              <Quote>Personal Data Breach</Quote> will not include unsuccessful
              attempts or activities that do not compromise the security of
              Personal Data, including unsuccessful log-in attempts, pings, port
              scans, denial of service attacks, and other network attacks on
              firewalls or networked systems.
            </li>
            <li>
              <Term>Processing</Term> means any operation or set of operations
              which is performed on Personal Data, encompassing the collection,
              recording, organization, structuring, storage, adaptation or
              alteration, retrieval, consultation, use, disclosure by
              transmission, dissemination or otherwise making available,
              alignment or combination, restriction or erasure of Personal Data.
              The terms <Quote>Process</Quote>, <Quote>Processes</Quote> and{' '}
              <Quote>Processed</Quote> will be construed accordingly.
            </li>
            <li>
              <Term>Processor</Term> means a natural or legal person, public
              authority, agency or other body which Processes Personal Data on
              behalf of the Controller.
            </li>
            <li>
              <Term>Standard Contractual Clauses</Term> means the standard
              contractual clauses annexed to the European Commission&apos;s
              Decision (EU) 2021/914 of 4 June 2021 currently found at
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc/standard-contractual-clauses-international-transfers_en,
              as may be amended, superseded or replaced.
            </li>
            <li>
              <Term>Sub-Processor</Term> means any Processor engaged by
              Interpres Security or its Affiliates to assist in fulfilling its
              obligations with respect to the provision of the Subscription SaaS
              Services under the Agreement. Sub-Processors may include third
              parties or its Affiliates but will exclude any Interpres Security
              employee or consultant.
            </li>
            <li>
              <Term>UK Addendum</Term> means the International Data Transfer
              Addendum issued by the UK Information Commissioner under section
              119A(1) of the Data Protection Act 2018 currently found at
              https://ico.org.uk/media/for-organisations/documents/4019539/international-data-transfer-addendum.pdf,
              as may be amended, superseded, or replaced.
            </li>
          </ul>
        </li>
        <li>
          Customer Responsibilities.
          <ol className={css.numberList}>
            <li>
              <U>Compliance with Laws</U>. Within the scope of the Agreement and
              in its use of the SaaS Services, Customer will be responsible for
              complying with all requirements that apply to it under applicable
              Data Protection Laws with respect to its Processing of Personal
              Data and the Instructions it issues to Interpres Security.
              <p>
                In particular but without prejudice to the generality of the
                foregoing, Customer acknowledges and agrees that it will be
                solely responsible for: (a) the accuracy, quality, and legality
                of Customer Data and the means by which the Customer acquired
                Personal Data; (b) complying with all necessary transparency and
                lawfulness requirements under applicable Data Protection Laws
                for the collection and use of the Personal Data, including
                obtaining any necessary consents and authorizations
                (particularly for use by Customer for marketing purposes); (c)
                ensuring Customer has the right to transfer, or provide access
                to, the Personal Data to Interpres Security for Processing in
                accordance with the terms of the Agreement (including this DPA);
                (d) ensuring that Customer’s Instructions to Interpres Security
                regarding the Processing of Personal Data comply with applicable
                laws, including Data Protection Laws; and (e) complying with all
                laws (including Data Protection Laws) applicable to any emails
                or other content created, sent or managed through the SaaS
                Services, including those relating to obtaining consents (where
                required) to send emails, the content of the emails and its
                email deployment practices. Customer will inform Interpres
                Security without undue delay if Customer is not able to comply
                with Customer’s responsibilities under this ‘Compliance with
                Laws’ section or applicable Data Protection Laws.
              </p>
            </li>
            <li>
              <U>Controller Instructions</U>. The parties agree that the
              Agreement (including this DPA), together with Customer’s use of
              the SaaS Service in accordance with the Agreement, constitute
              Customer’s complete Instructions to Interpres Security in relation
              to the Processing of Personal Data, so long as Customer may
              provide additional instructions during the subscription term that
              are consistent with the Agreement, the nature and lawful use of
              the SaaS Service.
            </li>
            <li>
              <U>Security</U>. Customer is responsible for independently
              determining whether the data security provided for in the SaaS
              Service adequately meets Customer’s obligations under applicable
              Data Protection Laws. Customer is also responsible for Customer’s
              secure use of the SaaS Service, including protecting the security
              of Personal Data in transit to and from the SaaS Service
              (including to securely backup or encrypt any such Personal Data).
            </li>
          </ol>
        </li>
        <li>
          Interpres Security Obligations.
          <ol className={css.numberList}>
            <li>
              <U>Compliance with Instructions</U>. Interpres Security will only
              Process Personal Data for the purposes described in this DPA or as
              otherwise agreed within the scope of Customer’s lawful
              Instructions, except where and to the extent otherwise required by
              applicable law. Interpres Security is not responsible for
              compliance with any Data Protection Laws applicable to Customer or
              Customer’s industry that are not generally applicable to Interpres
              Security.
            </li>
            <li>
              <U>Conflict of Laws</U>. If Interpres Security becomes aware that
              Interpres Security cannot Process Personal Data in accordance with
              Customer’s Instructions due to a legal requirement under any
              applicable law, Interpres Security will (a) promptly notify
              Customer of that legal requirement to the extent permitted by the
              applicable law; and (b) where necessary, cease all Processing
              (other than merely storing and maintaining the security of the
              affected Personal Data) until such time as Customer issue new
              Instructions with which Interpres Security is able to comply. If
              this provision is invoked, Interpres Security will not be liable
              to Customer under the Agreement for any failure to perform the
              applicable SaaS Services until such time as Customer issue new
              lawful Instructions with regard to the Processing.
            </li>
            <li>
              <U>Security</U>. Interpres Security will implement and maintain
              appropriate technical and organizational measures to protect
              Personal Data from Personal Data Breaches, as described under{' '}
              <U>Annex 2</U> to this DPA (<Term>Security Measures</Term>).
              Notwithstanding any provision to the contrary, Interpres Security
              may modify or update its Security Measures at its discretion
              provided that such modification or update does not result in a
              material degradation in the protection offered by the Security
              Measures.
            </li>
            <li>
              <U>Confidentiality</U>. Interpres Security will ensure that any
              personnel whom Interpres Security authorize to Process Personal
              Data on its behalf is subject to appropriate confidentiality
              obligations (whether a contractual or statutory duty) with respect
              to that Personal Data.
            </li>
            <li>
              <U>Personal Data Breaches</U>. Interpres Security will notify
              Customer without undue delay after Interpres Security becomes
              aware of any Personal Data Breach and will provide timely
              information relating to the Personal Data Breach as it becomes
              known or reasonably requested by Customer. At Customer’s request,
              Interpres Security will promptly provide Customer with such
              reasonable assistance as necessary to enable Customer to notify
              relevant Personal Data Breaches to competent authorities and/or
              affected Data Subjects, if Customer is required to do so under
              Data Protection Laws.
            </li>
            <li>
              <U>Deletion or Return of Personal Data</U>. Interpres Security
              will delete or return all Customer Data, including Personal Data
              (including copies thereof) Processed pursuant to this DPA, on
              termination or expiration of Customer’s SaaS Service in accordance
              with the procedures set out in its Agreement. This term will apply
              except where Interpres Security is required by applicable law to
              retain some or all of the Customer Data, or where Interpres
              Security has archived Customer Data on back-up systems, which data
              Interpres Security will securely isolate and protect from any
              further Processing and delete in accordance with its deletion
              practices. Customer may request the deletion of Customer’s
              Interpres Security account after expiration or termination of
              Customer’s subscription by sending a request using its privacy
              request form at{' '}
              <a href="https://interpressecurity.com/legal/privacy-policy/">
                https://interpressecurity.com/legal/privacy-policy/
              </a>
              .
            </li>
          </ol>
        </li>
        <li>
          Data Subject Requests.
          <ol className={css.numberList}>
            <li>
              The SaaS Service provides Customer with a number of controls that
              Customer can use to retrieve, correct, delete or restrict Personal
              Data, which Customer can use to assist it in connection with its
              obligations under Data Protection Laws, including Customer’s
              obligations relating to responding to requests from Data Subjects
              to exercise their rights under applicable Data Protection Laws (
              <Term>Data Subject Requests</Term>).
            </li>
            <li>
              To the extent that Customer is unable to independently address a
              Data Subject Request through the SaaS Service, then upon
              Customer’s written request Interpres Security will provide
              reasonable assistance to Customer to respond to any Data Subject
              Requests or requests from data protection authorities relating to
              the Processing of Personal Data under the Agreement. Customer will
              reimburse Interpres Security for the commercially reasonable costs
              arising from this assistance.
            </li>
            <li>
              If a Data Subject Request or other communication regarding the
              Processing of Personal Data under the Agreement is made directly
              to Interpres Security, Interpres Security will promptly inform
              Customer and will advise the Data Subject to submit their request
              to Customer. Customer will be solely responsible for responding
              substantively to any such Data Subject Requests or communications
              involving Personal Data.
            </li>
          </ol>
        </li>
        <li>
          Sub-Processors.
          <ol className={css.numberList}>
            <li>
              Customer agrees Interpres Security may engage Sub-Processors to
              Process Personal Data on Customer’s behalf, and Interpres Security
              do so in three ways. First, Interpres Security may engage
              Sub-Processors to assist Interpres Security with hosting and
              infrastructure. Second, Interpres Security may engage with
              Sub-Processors to support product features and integrations.
              Third, Interpres Security may engage with Interpres Security
              Affiliates as Sub-Processors for service and support. Some
              Sub-Processors will apply to Customer as default, and some
              Sub-Processors will apply only if Customer opt-in. Interpres
              Security has currently appointed, as Sub-Processors, the third
              parties and Interpres Security Affiliates listed in Annex 3 to
              this DPA.
            </li>
          </ol>
        </li>
        <li>
          Where Interpres Security engage Sub-Processors, Interpres Security
          will impose data protection terms on the Sub-Processors that provide
          at least the same level of protection for Personal Data as those in
          this DPA (including, where appropriate, the Standard Contractual
          Clauses), to the extent applicable to the nature of the services
          provided by such Sub-Processors. Interpres Security will remain
          responsible for each Sub-Processor’s compliance with the obligations
          of this DPA and for any acts or omissions of such Sub-Processor that
          cause Interpres Security to breach any of its obligations under this
          DPA.
        </li>
        <li>
          Data Transfers.
          <ol className={css.numberList}>
            <li>
              Customer acknowledges and agrees that Interpres Security may
              access and Process Personal Data on a global basis as necessary to
              provide the SaaS Service in accordance with the Agreement, and in
              particular that Personal Data may be transferred to and Processed
              by Interpres Security, Inc. in the United States and to other
              jurisdictions where Interpres Security Affiliates and
              Sub-Processors have operations. Wherever Personal Data is
              transferred outside its country of origin, each party will ensure
              such transfers are made in compliance with the requirements of
              Data Protection Laws.
            </li>
          </ol>
        </li>
        <li>
          Additional Provisions for European Data.
          <ol className={css.numberList}>
            <li>
              <U>Scope</U>. This ‘Additional Provisions for European Data’
              section will apply only with respect to European Data.
            </li>
            <li>
              <U>Roles of the Parties</U>. When Processing European Data in
              accordance with Customer’s Instructions, the parties acknowledge
              and agree that Customer is the Controller of European Data and
              Interpres Security is the Processor.
            </li>
            <li>
              <U>Instructions</U>. If Interpres Security believes that
              Customer’s Instruction infringes European Data Protection Laws
              (where applicable), Interpres Security will inform Customer
              without delay.
            </li>
            <li>
              <U>Objection to New Sub-Processors</U>. Interpres Security will
              give Customer the opportunity to object to the engagement of new
              Sub-Processors on reasonable grounds relating to the protection of
              Personal Data within 10 days of notifying Customer in accordance
              with the ‘Sub-Processors’ section. If Customer does notify
              Interpres Security of such an objection, the parties will discuss
              Customer’s concerns in good faith with a view to achieving a
              commercially reasonable resolution. If no such resolution can be
              reached, Interpres Security will, at its sole discretion, either
              not appoint the new Sub-Processor, or permit Customer to suspend
              or terminate the affected SaaS Service in accordance with the
              termination provisions of the Agreement without liability to
              either party (but without prejudice to any fees incurred by
              Customer prior to suspension or termination). The parties agree
              that by complying with this sub-section 8.4, Interpres Security
              fulfills its obligations under Clause 9 of the Standard
              Contractual Clauses.
            </li>
            <li>
              <U>Sub-Processor Agreements</U>. For the purposes of Clause 9(c)
              of the Standard Contractual Clauses, Customer acknowledges that
              Interpres Security may be restricted from disclosing Sub-Processor
              agreements but Interpres Security will use reasonable efforts to
              require any Sub-Processor Interpres Security appoint to permit it
              to disclose the Sub-Processor agreement to Customer and will
              provide (on a confidential basis) all information Interpres
              Security reasonably can.
            </li>
            <li>
              <U>
                Data Protection Impact Assessments and Consultation with
                Supervisory Authorities
              </U>
              . To the extent that the required information is reasonably
              available to Interpres Security, and Customer do not otherwise
              have access to the required information, Interpres Security will
              provide reasonable assistance to Customer with any data protection
              impact assessments, and prior consultations with supervisory
              authorities or other competent data privacy authorities to the
              extent required by European Data Protection Laws.
            </li>
            <li>
              <U>Transfer Mechanisms for Data Transfers</U>.
              <ol className={css.letterList}>
                <li>
                  Interpres Security will not transfer European Data to any
                  country or recipient not recognized as providing an adequate
                  level of protection for Personal Data (within the meaning of
                  applicable European Data Protection Laws), unless it first
                  takes all such measures as are necessary to ensure the
                  transfer is in compliance with applicable European Data
                  Protection Laws. Such measures may include (without
                  limitation) transferring such data to a recipient that is
                  covered by a suitable framework or other legally adequate
                  transfer mechanism recognized by the relevant authorities or
                  courts as providing an adequate level of protection for
                  Personal Data, to a recipient that has achieved binding
                  corporate rules authorization in accordance with European Data
                  Protection Laws, or to a recipient that has executed
                  appropriate standard contractual clauses in each case as
                  adopted or approved in accordance with applicable European
                  Data Protection Laws.
                </li>
                <li>
                  Customer acknowledges that in connection with the performance
                  of the SaaS Services, Interpres Security is a recipient of
                  European Data in the United States. Subject to sub-sections
                  (c) and (d) below, the parties agree that the Standard
                  Contractual Clauses will be incorporated by reference and form
                  part of the Agreement as follows:
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <U>EEA Transfers</U>. In relation to European Data that is
                      subject to the GDPR (A) Customer is the “data exporter”
                      and Interpres Security is the “data importer”; (B) the
                      Module Two terms apply to the extent the Customer is a
                      Controller of European Data and the Module Three terms
                      apply to the extent the Customer is a Processor of
                      European Data; (C) in Clause 9, Module 2, Option 2 applies
                      and changes to Sub-Processors will be notified in
                      accordance with the ‘Sub-Processors’ section of this DPA;
                      (D) in Clause 10, Module 2 shall apply, (E) in Clause 11,
                      the optional language is deleted; (F) in Clauses 17 and
                      18, the parties agree that the governing law and forum for
                      disputes for the Standard Contractual Clauses will be
                      determined in accordance with the Republic of Ireland
                      (without reference to conflicts of law principles); (G)
                      the Annexes of the Standard Contractual Clauses will be
                      deemed completed with the information set out in the
                      Annexes of this DPA; and (H) if and to the extent the
                      Standard Contractual Clauses conflict with any provision
                      of this DPA the Standard Contractual Clauses will prevail
                      to the extent of such conflict.
                    </li>
                    <li>
                      <U>UK Transfers</U>. In relation to European Data that is
                      subject to the UK GDPR, the Standard Contractual Clauses
                      will apply in accordance with sub-section (a) and the
                      following modifications (A) the Standard Contractual
                      Clauses will be modified and interpreted in accordance
                      with the UK Addendum, which will be incorporated by
                      reference and form an integral part of the Agreement; (B)
                      Tables 1, 2 and 3 of the UK Addendum will be deemed
                      completed with the information set out in the Agreement
                      and the Annexes of this DPA and Table 4 will be deemed
                      completed by selecting “neither party”; and (C) any
                      conflict between the terms of the Standard Contractual
                      Clauses and the UK Addendum will be resolved in accordance
                      with Section 10 and Section 11 of the UK Addendum.
                    </li>
                    <li>
                      <U>Swiss Transfers</U>. In relation to European Data that
                      is subject to the Swiss DPA, the Standard Contractual
                      Clauses will apply in accordance with sub-section (a) and
                      the following modifications (A) references to “Regulation
                      (EU) 2016/679” will be interpreted as references to the
                      Swiss DPA; (B) references to “EU”, “Union” and “Member
                      State law” will be interpreted as references to Swiss law;
                      and (C) references to the “competent supervisory
                      authority” and “competent courts” will be replaced with
                      the “the Swiss Federal Data Protection and Information
                      Commissioner “ and the “relevant courts in Switzerland”.
                    </li>
                  </ul>
                </li>
                <li>
                  If Interpres Security cannot comply with its obligations under
                  the Standard Contractual Clauses or is breach of any
                  warranties under the Standard Contractual Clauses or UK
                  Addendum (as applicable) for any reason, and Customer intend
                  to suspend the transfer of European Data to Interpres Security
                  or terminate the Standard Contractual Clauses ,or UK Addendum,
                  Customer agree to provide Interpres Security with reasonable
                  notice to enable Interpres Security to cure such
                  non-compliance and reasonably cooperate with Interpres
                  Security to identify what additional safeguards, if any, may
                  be implemented to remedy such non-compliance. If Interpres
                  Security has not or cannot cure the non-compliance, Customer
                  may suspend or terminate the affected part of the SaaS Service
                  in accordance with the Agreement without liability to either
                  party (but without prejudice to any fees Customer have
                  incurred prior to such suspension or termination).
                </li>
                <li>
                  In the event that Interpres Security adopts an alternative
                  transfer mechanism (including any new or successor version of
                  the EU-US Privacy Shield) for transfers of European Data to
                  Interpres Security, Inc., such alternative transfer mechanism
                  will apply automatically instead of the Standard Contractual
                  Clauses described in this DPA (but only to the extent such
                  alternative transfer mechanism complies with European Data
                  Protection Laws), and Customer agree to execute such other
                  documents or take such action as may be reasonably necessary
                  to give legal effect such alternative transfer mechanism.
                </li>
              </ol>
            </li>
            <li>
              <U>Demonstration of Compliance</U>. Interpres Security will make
              all information reasonably necessary to demonstrate compliance
              with this DPA available to Customer and allow for and contribute
              to audits, including inspections conducted by or Customer’s
              auditor in order to assess compliance with this DPA. Customer
              acknowledges and agree that Customer will exercise Customer’s
              audit rights under this DPA and Clause 8.9 of the Standard
              Contractual Clauses by instructing Interpres Security to comply
              with the audit measures described in this ‘Demonstration of
              Compliance’ section. Customer acknowledges that the SaaS Services
              are hosted by its hosting Sub-Processors who maintain
              independently validated security programs (including SOC 2 and ISO
              27001) and that its systems are audited annually as part of SOC 2
              compliance and regularly tested by independent third party
              penetration testing firms. Further, at Customer’s written request,
              Interpres Security will provide written responses (on a
              confidential basis) to all reasonable requests for information
              made by Customer necessary to confirm its compliance with this
              DPA, provided that Customer will not exercise this right more than
              once per calendar year.
            </li>
          </ol>
        </li>
        <li>
          Additional Provisions for California Personal Information.
          <ol className={css.numberList}>
            <li>
              <U>Scope</U>. The ‘Additional Provisions for California Personal
              Information’ section of the DPA will apply only with respect to
              California Personal Information.
            </li>
            <li>
              <U>Roles of the Parties</U>. When processing California Personal
              Information in accordance with Customer’s Instructions, the
              parties acknowledge and agree that Customer is a Business and
              Interpres Security is a SaaS Service Provider for the purposes of
              the CCPA.
            </li>
            <li>
              <U>Responsibilities</U>. The parties agree that Interpres Security
              will Process California Personal Information as a SaaS Service
              Provider strictly for the purpose of performing the SaaS Services
              under the Agreement (the <Term>Business Purpose</Term>) or as
              otherwise permitted by the CCPA, including as described in the
              ‘Usage Data’ section of its Privacy Policy.
            </li>
          </ol>
        </li>
        <li>
          General Provisions.
          <ol className={css.numberList}>
            <li>
              <U>Amendments</U>. Notwithstanding anything else to the contrary
              in the Agreement and without prejudice to the ‘Compliance with
              Instructions’ or ‘Security’ sections of this DPA, Interpres
              Security reserve the right to make any updates and changes to this
              DPA.
            </li>
            <li>
              <U>Severability</U>. If any individual provisions of this DPA are
              determined to be invalid or unenforceable, the validity and
              enforceability of the other provisions of this DPA will not be
              affected.
            </li>
            <li>
              <U>Limitation of Liability</U>. Each party and each of their
              Affiliates’ liability, taken in aggregate, arising out of or
              related to this DPA (and any other DPAs between the parties) and
              the Standard Contractual Clauses (where applicable), whether in
              contract, tort or under any other theory of liability, will be
              subject to the limitations and exclusions of liability set out in
              the ‘Limitation of Liability’ section of the Agreement and any
              reference in such section to the liability of a party means
              aggregate liability of that party and all of its Affiliates under
              the Agreement (including this DPA).{' '}
            </li>
            <li>
              <U>Governing Law</U>. This DPA will be governed by and construed
              in accordance with the government law section of the Agreement,
              unless required otherwise by Data Protection Laws.
            </li>
          </ol>
        </li>
        <li>
          Parties to this DPA:
          <ol className={css.numberList}>
            <li>
              <U>Permitted Affiliates</U>. By signing the Agreement, Customer
              enter into this DPA (including, where applicable, the Standard
              Contractual Clauses) on behalf of itself and in the name and on
              behalf of Customer’s Permitted Affiliates. For the purposes of
              this DPA only, and except where indicated otherwise, the terms
              “Customer”, ”Customer” and “Customer’s” will include Customer and
              such Permitted Affiliates.
            </li>
            <li>
              <U>Authorization</U>. The legal entity agreeing to this DPA as
              Customer represents that it is authorized to agree to and enter
              into this DPA for and on behalf of itself and, as applicable, each
              of its Permitted Affiliates.
            </li>
            <li>
              <U>Remedies</U>. The parties agree that (a) solely the Customer
              entity that is the contracting party to the Agreement will
              exercise any right or seek any remedy any Permitted Affiliate may
              have under this DPA on behalf of its Affiliates, and (b) the
              Customer entity that is the contracting party to the Agreement
              will exercise any such rights under this DPA not separately for
              each Permitted Affiliate individually but in a combined manner for
              itself and all of its Permitted Affiliates together. The Customer
              entity that is the contracting entity is responsible for
              coordinating all Instructions, authorizations, and communications
              with Interpres Security under the DPA and will be entitled to make
              and receive any communications related to this DPA on behalf of
              its Permitted Affiliates.
            </li>
            <li>
              <U>Other rights</U>. The parties agree that Customer will, when
              reviewing its compliance with this DPA pursuant to the
              ‘Demonstration of Compliance’ section, take all reasonable
              measures to limit any impact on Interpres Security and its
              Affiliates by combining several audit requests carried out on
              behalf of the Customer entity that is the contracting party to the
              Agreement and all of its Permitted Affiliates in one single audit.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    {/* Document #6 */}
    <div className={css.newPage}>
      <div className={css.bold + ' ' + css.centered}>
        Annex 1 - Details of Processing
      </div>
      <ol className={css.letterDecimalList}>
        <li>
          <U>List of Parties</U>
          <ul className={css.noBullet}>
            <li>
              <U>Data exporter</U>:
              <ul className={css.noBullet}>
                <li>
                  Name: The Customer, as defined in the Agreement (on behalf of
                  itself and Permitted Affiliates)
                </li>
                <li>
                  Address: The Customer’s address, as set out in the Order Form
                </li>
                <li>
                  Contact person’s name, position and contact details: The
                  Customer’s contact details, as set out in the Order Form
                  and/or as set out in the Customer’s Interpres Security Account
                </li>
                <li>
                  Activities relevant to the data transferred under these
                  Clauses: Processing of Personal Data in connection with
                  Customer’s use of the Interpres Security Platform under the
                  Agreement
                </li>
                <li>Role (controller/processor): Controller</li>
              </ul>
            </li>
          </ul>
          <ul className={css.noBullet}>
            <li>
              <U>Data importer:</U>
              <ul className={css.noBullet}>
                <li>Name: Interpres Security, Inc.</li>
                <li>
                  Address: South Morgans Point Rd #214, Mount Pleasant, SC
                  29466, USA
                </li>
                <li>
                  Contact person’s name, position and contact details: Michael
                  Jenks, Data Protection Officer, Interpres Security, Inc.,
                  South Morgans Point Rd #214, Mount Pleasant, SC 29466, USA
                </li>
              </ul>
            </li>
          </ul>
          <ul className={css.noBullet}>
            <li>
              Activities relevant to the data transferred under these Clauses:
              Processing of Personal Data in connection with Customer’s use of
              the Interpres Security Platform under the Agreement.
              <ul className={css.noBullet}>
                <li>Role (controller/processor): Processor</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <U>Description of Transfer</U>
          <ul className={css.noBullet}>
            <li>
              Categories of Data Subjects whose Personal Data is Transferred
              <ul className={css.noBullet}>
                <li>
                  Customer may submit Personal Data in the course of using the
                  SaaS Service, the extent of which is determined and controlled
                  by Customer in Customer’s sole discretion, and which may
                  include, but is not limited to Personal Data relating to the
                  following categories of Data Subjects:
                </li>
                <li>
                  Customer Contacts and other end users including Customer’s
                  employees, contractors, collaborators, customers, prospects,
                  suppliers and subcontractors. Data Subjects may also include
                  individuals attempting to communicate with or transfer
                  Personal Data to Customer’s end users.
                </li>
              </ul>
            </li>
            <li>
              Categories of Personal Data Transferred
              <ul className={css.noBullet}>
                <li>
                  Customer may submit Personal Data to the SaaS Services, the
                  extent of which is determined and controlled by Customer in
                  Customer’s sole discretion, and which may include but is not
                  limited to the following categories of Personal Data:{' '}
                </li>
                <li>
                  Contact Information and any other Personal Data submitted by,
                  sent to, or received by Customer, or Customer’s end users, via
                  the SaaS Service.
                </li>
              </ul>
            </li>
            <li>
              Sensitive Data transferred and applied restrictions or safeguards
            </li>
          </ul>
          <div>
            The parties do not anticipate the transfer of sensitive data.
          </div>
          <ul className={css.noBullet}>
            <li>Frequency of the transfer</li>
          </ul>

          <div>Continuous</div>
          <ul className={css.noBullet}>
            <li>
              Nature of the Processing
              <ul className={css.noBullet}>
                <li>
                  Personal Data will be Processed in accordance with the
                  Agreement (including this DPA) and may be subject to the
                  following Processing activities:
                </li>
                <li>
                  <ol>
                    <li>
                      Storage and other Processing necessary to provide,
                      maintain and improve the SaaS Services provided to
                      Customer; and/or
                    </li>
                    <li>
                      Disclosure in accordance with the Agreement (including
                      this DPA) and/or as compelled by applicable laws.
                    </li>
                  </ol>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Purpose of the transfer and further processing
            <ul className={css.noBullet}>
              <li>
                Interpres Security will Process Personal Data as necessary to
                provide the SaaS Services pursuant to the Agreement, as further
                specified in the Order Form, and as further instructed by
                Customer in Customer’s use of the SaaS Services.
              </li>
            </ul>
          </p>
          <p>
            Period for which Personal Data will be retained
            <ul className={css.noBullet}>
              <li>
                Subject to the ‘Deletion or Return of Personal Data’ section of
                this DPA, Interpres Security will Process Personal Data for the
                duration of the Agreement, unless otherwise agreed in writing.
              </li>
            </ul>
          </p>
        </li>
        <li>
          Competent Supervisory Authority
          <ul className={css.noBullet}>
            <li>
              For the purposes of the Standard Contractual Clauses, the
              supervisory authority that will act as competent supervisory
              authority will be determined in accordance with GDPR.
            </li>
          </ul>
        </li>
      </ol>
    </div>
    {/* Document #7 */}
    <div className={css.newPage}>
      <div className={css.bold + ' ' + css.centered}>
        Annex 2 - Sub-Processors
      </div>
      <p>
        To help Interpres Security deliver the SaaS Service, Interpres Security
        engages Sub-Processors to assist with its data processing activities. A
        list of its Sub-Processors and its purpose for engaging them is as
        follows:
      </p>
      <ul>
        <li>Intercom;</li>
        <li>ZenDesk;</li>
        <li>SendGrid;</li>
        <li>FullStory;</li>
        <li>SFDC;</li>
        <li>HubSpot; and</li>
        <li>LinkedIn;</li>
        <li>Greenhouse.io</li>
        <li>BrightTalk; and</li>
        <li>ZoomInfo.</li>
      </ul>
    </div>
  </div>
);
export default Terms;
