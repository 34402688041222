import { Maybe } from 'core/graphql/graphql';

/**
 * Basic interface for any of our *Connection types (for data list results).
 *
 * NOTE: Real connection types have other properties (eg. pageInfo), but we
 *       don't care about them in this interface.
 */
interface TypeConnection<ConnectionType = any> {
  edges: Array<
    Maybe<{
      node?: Maybe<ConnectionType>;
    }>
  >;
}

/**
 * Defines an object that maps string types to TS object types, eg.
 * { assets: Maybe<AssetTypeConnection> }
 */
type TypesConnection<ConnectionTypes> = {
  [K in keyof ConnectionTypes]?: TypeConnection<ConnectionTypes[K]>[];
};

/**
 * When we have a type like SoftwareTypeConnection, we sometimes want to use the
 * base type of it (eg. SoftwareType).  This utility type extracts that base
 * type from the provided connection type.
 *
 * NOTE: Obviously if you know you are working with SoftwareTypeConnections you
 *       can just SoftwareType directly.  This utility type is meant for
 *       situations where you have (say) a Software OR Technique OR Threat Group
 *       Connection, and you want to say "I'll return a Software/Technique/
 *       Threat Group Type" (based on whatever connection type you gave me).
 *
 * @example TypeOfConnection<SoftwareTypeConnection> === SoftwareType
 */
export type BaseTypeOfConnection<ConnectionType extends TypeConnection> =
  ConnectionType['edges'][number]['node'];

/**
 * Because our GraphQL API uses the concept of edges/nodes, when we we fetch foo
 * data, we don't get back an array of foos ... instead we get:
 * {
 *     edges: [
 *       { node: actualFoo1 }, { node: actualFoo2 }, etc.
 *     ]
 * }
 *
 * This function extracts and returns a simple/flat array of the "foos" from the
 * container object.
 */
export const extractNodes = <ConnectionType extends TypeConnection = any>(
  data: ConnectionType
): BaseTypeOfConnection<ConnectionType>[] =>
  data?.edges?.map(({ node }) => node) || [];

/**
 * Similar to extractNodes, this function extracts an array of nodes from a
 * provided connection (ie. thing with edges that contain nodes).  However, it
 * goes one level deeper, and extracts/returns the provided field from each
 * extracted node.
 */
export const extractNodeField = <ConnectionType extends TypeConnection>(
  nodes: ConnectionType,
  field: string
) => extractNodes(nodes)?.map(node => node[field]);

/**
 * Extracts the provided nodes, then further extracts child nodes of a field
 * of each of those nodes (eg. it extracts the detection nodes out of all of
 * the provided technique nodes (which have a "detections" property)).
 *
 * All extracted nodes are flattened into a single array before being returned,
 * and any undefineds or other falsy values are removed.
 *
 * NOTE: If extractNodes (above) extracts with 1 level of depth, and
 *       extractNodeFields extracts with 2 levels, this function can be thought
 *       of as extracting 3 levels deep.  However it's not quite that simple:
 *       the first and third levels extract nodes from edges, while the middle
 *       level just accesses a simple property.
 *
 * @example extractNodesOfNestedField(techniques, 'technique', 'detections')
 *          (extracts all of the detection properties of the technique
 *          properties of the provided technique nodes)
 */
export const extractNodesOfNestedField = <
  ConnectionType extends TypeConnection
>(
  nodes: ConnectionType,
  childField: string,
  nestedField: string
) => {
  // Extract the field values from the nodes
  return (
    extractNodeField(nodes, childField)
      // Extract the child field
      .map(child => child[nestedField])
      // Extract the nodes of that child field
      .map(extractNodes)
      // Now we have an array of arrays: flatten them into a single array
      ?.flat()
      // Clear any falsy values (eg. undefined) we've collected
      ?.filter(x => x)
  );
};

/**
 * Convenience function for when you have an object with multiple data sources,
 * all of which need to be extracted.  For instance, you might have:
 * {
 *    assets: AssetTypeConnection,
 *    integrationAssets: IntegrationAssetTypeConnection
 * }
 * and want to get the assets/integrationAssets out.  This function extracts
 * both, returning the following for the above example:
 * {
 *    assets: AssetType[],
 *    integrationAssets: IntegrationAssetType[]
 * }
 */
// This function should work, and it has no TS errors in VS Code, but Vite
// doesn't like its TS for some reason (specifically it expects there to be
// a "]" after the "keyof" in the return type ... which doesn't seem right.
// I wound up not using it, but I'm leaving it here because it might be useful
// in the future, and is one weird TS issue away from working.
// export const extractNodesFromData = <ConnectionType>(
//   data: TypesConnection<ConnectionType>
// ): { [TypeName in keyof ConnectionType]: ConnectionType[TypeName][] } => {
//   return Object.entries(data).reduce(
//     (extracted, [key, value]) => ({
//       ...extracted,
//       [key]: extractNodes(value as any)
//     }),
//     {}
//   ) as {
//     [K in keyof ConnectionType]: ConnectionType[K][];
//   };
// };

/**
 * Handles a simple response to a GraphQL mutation.
 * "Simple" in this case means that we don't really care about what the server's
 * response is in the success case, but in the failure case we want to let the
 * user know that something went wrong.
 *
 * In other words, this helper is a great way to handle "it worked or it didn't"
 * mutations, but if you want to get the ID (or other data) that was generated
 * in a mutation, this helper will be too simple.
 * @param mutationName - name of the mutation (it's used as a response property)
 * @param data - the data returned by the server
 * @param errors - the errors returned by the server
 * @param notifyError - an error notification function (from useNotification)
 * @returns true if there were no errors and the operation was successful, false
 *          otherwise
 */
export const handleSimpleMutationResponse = (
  mutationName,
  data,
  errors,
  notifyError
) => {
  // If we have errors, that's (obviously) a failure
  if (errors) {
    notifyError(errors[0].message);
    return false;
  }
  // If we didn't get a success confirmation in the data, that too is a failure
  if (!data?.[mutationName]?.success) {
    notifyError(data?.message || 'Something went wrong');
    return false;
  }
  return true;
};
