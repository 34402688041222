import { gql, useMutation } from '@apollo/client';
import { USERS_CONTEXT } from 'core/Apollo';
import { User } from 'core/graphql/graphql';
import { Button, useDialog } from 'reablocks';
import { useEffect, useState } from 'react';
import css from './TOSDialog.module.css';
import Terms from './Terms';

const ACCEPT_TERMS_MUTATION = gql`
  mutation UpdateProfileHasAgreedToTerms {
    updateCurrentUser(input: { hasAgreedToTerms: true }) {
      success
      message
    }
  }
`;

interface AcceptTermsResponse {
  updateCurrentUser: {
    message: string;
    success: boolean;
  };
}

interface TOSDialogProps {
  user: User;
}

const TOSDialog = ({ user }: TOSDialogProps) => {
  const { Dialog, toggleOpen } = useDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(toggleOpen, [toggleOpen]); // Rea dialogs can't start open (/sigh)

  // This whole thing doesn't really need pagination; it could  easily just be a
  // single dialog page" with the terms ... but our lawyer feels it needs to be
  //  multiple pages, so ...
  const [page, setPage] = useState<number>(1);

  // Track whether the user has actually scrolled to the end of the terms
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  // Track whether the user agreed to our terms (by checking the box)
  const [hasAgreed, setHasAgreed] = useState(false);

  const [acceptTerms] = useMutation<AcceptTermsResponse>(
    ACCEPT_TERMS_MUTATION,
    USERS_CONTEXT
  );

  /** When the user finishes scrolling through the TOS, update the state var */
  const handleScroll = ({ target }) => {
    const boxHeight = target.scrollHeight;
    const amountScrolled = target.scrollTop + target.clientHeight;
    if (Math.abs(boxHeight - amountScrolled) <= 1) {
      // NOTE: Zoom can give us fractions, thus the "Math.abs" and "<=1"
      // @see https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js/49573628#49573628
      setHasScrolled(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    if (hasScrolled && hasAgreed) {
      try {
        const {
          data: {
            updateCurrentUser: { success }
          }
        } = await acceptTerms({ refetchQueries: ['ProfileQuery'] });
        if (!success) {
          throw new Error();
        }
      } catch (err) {
        alert(
          "We're sorry, something went wrong.  Please contact Interpres support."
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <div style={{ textAlign: 'center', margin: '50px' }}>
      <Dialog header={<h3>Terms of Service</h3>}>
        {page === 1 && (
          <div>
            By clicking &quot;Next&quot; you will be directed to the
            &quot;Interpres Security SaaS Agreement Terms of Service&quot;.
            <div className={css.buttonWrapper}>
              <Button color="primary" onClick={() => setPage(2)}>
                Next
              </Button>
            </div>
          </div>
        )}
        {page === 2 && (
          <form onSubmit={handleSubmit}>
            <div>
              Below are Interpres Security SaaS Agreement Terms of Service for
              use of the Interpres Security SaaS platform. Please scroll to the
              bottom in order to accept:
            </div>
            <div className={css.termsBox} onScroll={handleScroll} style={{}}>
              <Terms email={user.email} />
            </div>
            <label>
              <input
                className={css.agreementText}
                type="checkbox"
                disabled={!hasScrolled}
                onClick={e => setHasAgreed(e.target['checked'])}
              />
              &nbsp; I accept the terms and conditions of the
              Software-As-A-Service Agreement
            </label>
            <div className={css.buttonWrapper}>
              <Button
                color="primary"
                disabled={!hasScrolled || !hasAgreed || isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Dialog>
    </div>
  );
};

export default TOSDialog;
