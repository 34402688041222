import { PropsWithChildren } from 'react';
import assert from 'shared/utils/assert';
import { useUserContext } from './UserContext';

/**
 * BACKSTORY: FusionAuth has their own component, <RequireAuth>, which had the
 *            same basic idea as this (hide children if the user lacks a certain
 *            permission/role).  We made this to work with our context
 *            (and keep our code flowing through UseContext and not directly
 *            to the FusionAuth stuff).
 */
const RequireRole = ({
  children,
  role,
  roles
}: PropsWithChildren<{ role?: string; roles?: string[] }>) => {
  const { roles: userRoles } = useUserContext();
  assert(role || roles, 'You must specify required role(s)');

  const checkRole = role => userRoles.includes(role);

  const hasPermission = role ? checkRole(role) : roles.every(checkRole);

  return hasPermission ? children : null;
};

export default RequireRole;
