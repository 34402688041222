import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';

/**
 * This is Apollo's global error handler: all errors that occur during Apollo
 * queries/mutations flow through here.
 */
export const buildErrorLink = notifyError =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (import.meta.env.PROD) {
          Sentry.withScope(scope => {
            scope.setTag('type', 'graphql');
            scope.setTag('path', path as any);
            scope.setExtra('locations', locations);
            Sentry.captureException(new Error(message));
          });
        }
        if (message === 'You do not have permission to perform this action') {
          notifyError(message); // Inform user if they get a permission error
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });
