import { ServerError } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { NavigateFunction } from 'react-router-dom';

const MAX_RETRY_COUNT = 3;

export const retryLink = (navigate: NavigateFunction, refreshToken: any) =>
  new RetryLink({
    delay: {
      initial: 400,
      max: Infinity,
      jitter: true
    },
    attempts: (count, _operation, error) => {
      if (
        (error as ServerError).statusCode === 401 &&
        count > MAX_RETRY_COUNT
      ) {
        navigate('/auth/logout');
      } else {
        refreshToken();
      }

      return count <= MAX_RETRY_COUNT;
    }
  });
