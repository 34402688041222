import createTheme from '@mui/material/styles/createTheme';
import { colors } from 'shared/../../tailwind.config';

const muiTheme = createTheme({
  // Tell MUI about our colors, currently for buttons (but possibly for other
  // components in the future).
  palette: {
    primary: {
      main: colors.purple500
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //   main: ReaBlocksTheme.palettes.secondary.color
    //   light: '#F5EBFF',
    // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#47008F'
    // }
    error: {
      main: colors.error
    }
    // Same pattern for:
    // warning
    // info
    // success
  },
  // Tell MUI not to capitalize our button text
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

export default muiTheme;
