import { MAX_PRIORITY, MIN_PRIORITY } from 'App/ThreatGroups/utils';
import { ReactComponent as PriorityIcon } from 'assets/icons/priority.svg';
import { ActiveFilterChip } from 'shared/utils/ActiveFilterChip';

const defaultValue = [MIN_PRIORITY, MAX_PRIORITY]?.toLocaleString();

const PriorityActiveFilterChip = ({ filter, setFilter }) => {
  const { priority } = filter ?? {};
  if (!priority || priority === defaultValue) {
    return null;
  }
  return (
    <ActiveFilterChip
      icon={<PriorityIcon />}
      label="Priority"
      value={filter?.priority?.split(',').join(' to ')}
      onDelete={() => setFilter({ ...filter, priority: defaultValue })}
    />
  );
};

export default PriorityActiveFilterChip;
