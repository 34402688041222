import React, { useCallback, FC } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';

export const ErrorBoundary: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const onError = useCallback((error: Error) => {
    if (import.meta.env.PROD) {
      Sentry.withScope(scope => {
        scope.setTag('type', 'react');
        Sentry.captureException(error);
      });
    }
  }, []);

  return (
    <ReactErrorBoundary FallbackComponent={ErrorMessage} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};
