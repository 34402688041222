import { FC, PropsWithChildren } from 'react';
import assert from 'shared/utils/assert';

type BasicComponent = FC<PropsWithChildren>;

const composeComponents = (componentsWithProps: [BasicComponent, any][]) => {
  for (const componentWithProps of componentsWithProps) {
    assert(
      typeof componentWithProps[0] === 'function',
      'Component must be a function'
    );
  }
  const reducer =
    (AccumulatedComponents: BasicComponent, [Component, props = {}]) =>
    ({ children }) => (
      <AccumulatedComponents>
        <Component {...props}>{children}</Component>
      </AccumulatedComponents>
    );
  return componentsWithProps.reduce(
    reducer as any,
    (({ children }) => children) as BasicComponent // initial component
  );
};

/**
 * This component allows multiple other components to be "composed" into a
 * single component, eliminating (potentially large) nesting/indentation.
 *
 * It was originally added to let us "compose" all of our providers together,
 * without nested indenting that put us past the fold.
 *
 * @example
 * <Compose components={[
 *   [BrowserRouter],
 *   [AuthProvider],
 *   [ThemeProvider, {theme}]
 * ]}>
 *   <App />
 * </Compose>
 *
 * @see https://stackoverflow.com/questions/51504506/too-many-react-context-providers
 * @see https://stackoverflow.com/questions/77151720/how-to-avoid-wrapper-hell-in-a-react-project-with-typescript
 */
const Compose = ({
  children,
  componentsWithProps = []
}: {
  children: React.ReactNode;
  //   TODO: Type properly: array of arrays of [func, object]
  componentsWithProps: any;
}) => {
  const Composed = composeComponents(componentsWithProps);
  return <Composed>{children}</Composed>;
};
export default Compose;
