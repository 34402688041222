import { useQuery } from '@apollo/client';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { SearchResultConnection } from 'core/graphql/graphql';
import gql from 'graphql-tag';
import { useEffect, useRef, useState } from 'react';
import { SearchInput } from 'shared/form/SearchInput';
import { extractNodes } from 'shared/utils/GraphUtil';
import { useGlobalSearchContext } from './GlobalSearchContext';
import GlobalSearchDialogResults from './GlobalSearchDialogResults';

const buildSelection = (type: string, fields: string) =>
  `node {
    ... on ${type} {
      __typename
      ${fields}
    }
  }`;

// NOTE: The ordering of the results below (in "edges") DOES NOT MATTER; the
//       server controls ordering.  However, the order below should reflect the
//       server's order ... or at least, it did when I wrote this note.
const query = gql`
  query GlobalSearchQuery($after: String, $term: String! = "") {
    search(after: $after, first: 100, searchTerm: $term) {
      edges {
        ${buildSelection('PrioritizedTechniqueType', 'techniqueName:name mitreId')}
        ${buildSelection('PrioritizedThreatGroupType', 'threatGroupName:name mitreId')}
        ${buildSelection('PrioritizedSoftwareType', 'softwareName:name mitreId')}
        ${buildSelection('PrioritizedVulnerabilityType', 'vulnerabilityName:name id')}
        ${buildSelection('TelemetrySubcategoryType', 'visibilityName:name id')}
        ${buildSelection('DetectionType', 'detectionName:name id')}
        ${buildSelection('AssetType', 'hostname id')}
        ${buildSelection('IdentityType', 'identityName:name emailAddresses id')}
        ${buildSelection('PrioritizedCampaignType', 'campaignName:name id')}
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      count
    }
  }
`;

const GlobalSearchDialog = () => {
  const { close, isOpen } = useGlobalSearchContext();
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLElement>(null);

  // autoFocus doesn't work (because the dialog is renddered behind the scene?)
  // so we have to handle focusing the input here
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => inputRef?.current?.focus());
    }
  }, [isOpen]);

  const closeDialog = e => {
    close();
    document.body.removeEventListener('click', closeDialog);
    // Clear the input when the dialog closes
    setSearchTerm('');
  };

  const { data, previousData } = useQuery<{ search: SearchResultConnection }>(
    query,
    {
      fetchPolicy: 'no-cache',
      skip: searchTerm?.length < 3,
      variables: { after: null, term: searchTerm }
    }
  );
  const { search } = data || {};

  let results = null;
  if (searchTerm?.length > 2) {
    // The user has started typing
    if (search) {
      // We've started searching
      // If we finished, and there are search results, use them
      results = extractNodes(search);
    }
    if (!results && previousData?.search) {
      // If we're not finished, use the old results (or null) until we are
      const oldResults = extractNodes(previousData?.search);
      results = oldResults;
    }
  }
  return (
    <Dialog
      // NOTE: Without these styles we'd have 700 width; the 50 px margin is to
      //       fix the centering after we add 100px more width
      className={
        // Styles on the dialog element
        'ml-[50px] ' +
        // Styles on MUI content root
        '[&_.MuiDialogContent-root]:p-0 ' +
        // Styles on MUI paper root
        '[&_.MuiPaper-root]:w-[800px] [&_.MuiPaper-root]:bg-gray800 ' +
        '[&_.MuiPaper-root]:px-xl'
      }
      fullWidth={true}
      maxWidth="md"
      onClose={closeDialog}
      open={isOpen}
    >
      <DialogTitle>
        <div className="flex items-center justify-between bg-gray800 !py-xl text-white">
          <div className="pl-[5px]">Global Search</div>
          <CloseIcon className="cursor-pointer" onClick={closeDialog} />
        </div>

        <div className="flex min-w-full">
          <div className="w-[calc(100%_-_60px)]">
            <SearchInput
              containerClassname="min-w-[693px] ml-[5px]"
              className="w-[650px] min-w-[550px] border-0 text-md"
              onChange={e => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              ref={inputRef}
              value={searchTerm}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        className={
          'items-center gap-[32px] ' + // flex
          'min-w-[752px] max-w-[800px] bg-gray800 !px-xl' // box, background
        }
      >
        <GlobalSearchDialogResults {...{ close, results, searchTerm }} />
      </DialogContent>
    </Dialog>
  );
};

export default GlobalSearchDialog;
