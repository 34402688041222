import { Scalars } from 'core/graphql/graphql';
import assert from 'shared/utils/assert';
import { MeterProps } from '../../shared/data/Meter';

// TODO: Set threshold for success, warn and danger
export const WARN_THRESHOLD = 29;
export const DANGER_THRESHOLD = 69;

// TODO: Set threshold for Priority colors
const PRIORITY_HIGH_THRESHOLD = 60;
const PRIORITY_MEDIUM_THRESHOLD = 30;
export const MAX_PRIORITY = 100;
export const MIN_PRIORITY = 0;
export const MAX_COVERAGE = 100;
export const MIN_COVERAGE = 0;

export const PRIORITY_LEVELS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
} as const;

export const ORDER_BY_FIELDS = {
  NAME: 'name',
  PRIORITY: 'priority',
  COVERAGE: 'content',
  LAST_MODIFIED: 'modifiedTimestamp'
};

export const KEY_SAVED_FILTER_TECHNIQUES = '@interpres:techniques-filter';

export const getPostureLevel = (value: number): MeterProps['variant'] => {
  if (value <= WARN_THRESHOLD) {
    return 'error';
  }
  if (value <= DANGER_THRESHOLD) {
    return 'warning';
  }
  return 'success';
};

// @see https://stackoverflow.com/questions/60691098/how-to-get-object-values-as-type-in-typescript
/**
 * A "PriorityLevel" is just a string of "high", "medium", "low" or "info"
 */
export type PriorityLevel =
  | (typeof PRIORITY_LEVELS)[keyof typeof PRIORITY_LEVELS]
  | 'info';

/**
 * Returns a priority level, given a numeric value
 * Please modify @see getPriorityRangeFromLevel as well if any changes are made to this function
 * @param priority priority value between 0-100
 * @param invert whether range is inverted - depending upon whether its a threat or defense element
 * @returns {string}: Return string from PRIORITY_LEVELS enum
 */
export const getPriorityLevel = (
  priority: number,
  invert = false
): PriorityLevel => {
  if (priority <= PRIORITY_MEDIUM_THRESHOLD) {
    return invert ? PRIORITY_LEVELS.HIGH : PRIORITY_LEVELS.LOW;
  }
  if (priority <= PRIORITY_HIGH_THRESHOLD) {
    return PRIORITY_LEVELS.MEDIUM;
  }
  return invert ? PRIORITY_LEVELS.LOW : PRIORITY_LEVELS.HIGH;
};

export interface Prioritized {
  priority: Scalars['Int']['output'];
}

/**
 * Returns an object with low/medium/high count properties, where the counts
 * come from totalling the number of objects in the provided array with each
 * priority level.
 */
export const getPriorityCounts = (prioritized: Prioritized[]) => {
  assert(prioritized, "We can't count prioritities without something to count");
  // tsconfig.json, but still TS refuses to recognize this.
  // TODO: Remove this once target: "es2014" exists and (presumably) solves it
  // @ts-ignore We have "target": "ESNext" and "lib": [... "es2017"] in our
  const { low, medium, high } = Object.groupBy(prioritized, ({ priority }) =>
    getPriorityLevel(priority)
  );
  return {
    low: low?.length || 0,
    medium: medium?.length || 0,
    high: high?.length || 0
  };
};

/**
 * Returns the numeric range, given a @see PRIORITY_LEVELS
 * * Please modify @see getPriorityLevel as well if any changes are made to this function
 * @param priorityLevel : PRIORITY_LEVELS string
 * @returns {[number, number]}: Numeric range - both inclusive
 */
export const getPriorityRangeFromLevel = (
  priorityLevel: string
): [number, number] => {
  if (priorityLevel === PRIORITY_LEVELS.HIGH) {
    return [PRIORITY_HIGH_THRESHOLD + 1, MAX_PRIORITY];
  }
  if (priorityLevel === PRIORITY_LEVELS.MEDIUM) {
    return [PRIORITY_MEDIUM_THRESHOLD + 1, PRIORITY_HIGH_THRESHOLD];
  }
  if (priorityLevel === PRIORITY_LEVELS.LOW) {
    return [MIN_PRIORITY, PRIORITY_MEDIUM_THRESHOLD];
  }
  return [MIN_PRIORITY, MAX_PRIORITY];
};

export interface TechniquesRow {
  id: string;
  nameAndId: { name: string; mitreId: string };
  description: string;
  priority: number;
  content: number;
  platform: string[];
  tactics: string[];
  modified: any;
  relatedDataModels: {
    threatGroups: number;
    software: number;
    scenarios: number;
  };
  posture: {
    visibility: number;
    detections: number;
  };
  postureCount: {
    visibility: string;
    detections: string;
  };
}

export interface TechniquesFilter {
  tacticNames?: string;
  integrationNames?: string;
  platformNames?: string;
  priority?: string;
  coverage?: string;
  techniqueMitreIds?: string;
  campaignStixIds?: string;
  softwareMitreIds?: string;
  threatGroupMitreIds?: string;
  telemetrySubcategoryNames?: string;
  controlStixIds?: string;
  telemetrySubcategoryId?: string;
  selectedCoverage?: string;
}

/**
 * Check if HTML text contains multiple paragraphs
 * @param htmlText : HTML text with tags
 */
export const isMultiParaHtmlText = (htmlText: string) => {
  return (
    (htmlText || '').toLowerCase().indexOf('<p>') !==
    (htmlText || '').toLowerCase().lastIndexOf('<p>')
  );
};
