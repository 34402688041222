import { PropsWithChildren, createContext, useState } from 'react';
import { buildUseContext } from 'shared/utils/contextUtil';

export const GlobalSearchContext = createContext<{
  close: () => void;
  isOpen: boolean;
  open: () => void;
}>({ close: () => {}, isOpen: false, open: () => {} });

export const GlobalSearchProvider = ({
  children
}: PropsWithChildren): ReturnType<typeof GlobalSearchContext.Provider> => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  // Outside code only wants to show or hide the dialog, so don't expose setter
  return (
    <GlobalSearchContext.Provider value={{ close, isOpen, open }}>
      {children}
    </GlobalSearchContext.Provider>
  );
};

export const useGlobalSearchContext = buildUseContext(
  GlobalSearchContext,
  `GlobalSearchContext`
);
