import IdentitiesIcon from '@mui/icons-material/RecentActors';
import { ReactComponent as AssetsIcon } from 'assets/icons/asset-inventory.svg';
import { ReactComponent as TechniqueIcon } from 'assets/icons/brain.svg';
import { ReactComponent as CampaignIcon } from 'assets/icons/campaigns.svg';
import { ReactComponent as DetectionIcon } from 'assets/icons/detection.svg';
import { ReactComponent as SoftwareIcon } from 'assets/icons/software.svg';
import { ReactComponent as ThreatGroupIcon } from 'assets/icons/threat-group.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as VulnerabilityIcon } from 'assets/icons/vulnerabilities.svg';
import invert from 'lodash/invert';
import isString from 'lodash/isString';
import assert from 'shared/utils/assert';
/**
 * This file holds utility functions related to the Interpres core data types
 * (eg. Techniques, Software, Campaigns).
 *
 * NOTE: This file was created as part of global search, and thus it doesn't
 *       include Integrations or any other "non-data" datatype (is there a term
 *       for that?)
 *       There's no reason why it couldn't be extended with other types though,
 *       if that someday makes sense.
 */

const dataTypeIcons = {
  asset: AssetsIcon,
  campaign: CampaignIcon,
  detection: DetectionIcon,
  identity: IdentitiesIcon,
  software: SoftwareIcon,
  technique: TechniqueIcon,
  threatGroup: ThreatGroupIcon,
  // Visbilities are the only data type that have a different name from what the
  // customer sees: on the server they are telemetry sub categories ...
  // ... so we have to do them twice here
  telemetrySubcategory: VisibilityIcon,
  visibility: VisibilityIcon,
  vulnerability: VulnerabilityIcon
} as const;

type DataType = keyof typeof dataTypeIcons;

const aliases = {
  telemetrySubcategory: 'visibility'
};

const pluralTypes = {
  asset: 'assets',
  campaign: 'campaigns',
  detection: 'detections',
  identity: 'identities',
  software: 'software',
  technique: 'techniques',
  threatGroup: 'threatGroups',
  visibility: 'visibilities',
  vulnerability: 'vulnerabilities'
};
const plurals = Object.values(pluralTypes); // This is just for TS, /sigh
type PluralType = (typeof plurals)[number];

const typePluralizations = invert(pluralTypes);

const typeLabels = {
  asset: 'Asset',
  campaign: 'Campaign',
  detection: 'Detection',
  identity: 'Identity',
  software: 'Software',
  technique: 'Technique',
  telemetrySubcategory: 'Visibility',
  threatGroup: 'Threat Group',
  visibility: 'Visibility',
  vulnerability: 'Vulnerability'
} as const;
const singularTypes = Object.keys(typeLabels) as DataType[];

const labels = Object.values(typeLabels); // This is just for TS, /sigh
type LabelType = (typeof labels)[number];

const labelTypes = invert(typeLabels);

// TODO: Use stuff below to simplify AssociatedThreatElements (and elsewhere)
const pluralTypeLabels = {
  asset: 'Assets',
  campaign: 'Campaigns',
  detection: 'Detections',
  identify: 'Identities',
  software: 'Software',
  technique: 'Techniques',
  threatGroup: 'Threat Groups',
  visibility: 'Visibilities',
  vulnerability: 'Vulnerabilities'
} as const;
const pluralLabels = Object.values(pluralTypeLabels); // This is just for TS, /sigh
type PluralLabelType = (typeof pluralLabels)[number];
const pluralLabelTypes = invert(pluralTypeLabels);

/**
 * Every data type has a name field, conceptually, but it's not always the
 * type + "Name".  Because of one outlier (assets), we need this mapping.
 */
const typeNameFields = {
  asset: 'hostname',
  campaign: 'campaignName',
  detection: 'detectionName',
  identity: 'identityName',
  software: 'softwareName',
  technique: 'techniqueName',
  threatGroup: 'threatGroupName',
  visibility: 'visibilityName',
  vulnerability: 'vulnerabilityName'
};
export const nameFields = Object.values(typeNameFields);

/**
 * This type covers not just datatypes (eg. "technique"), but also anything that
 * can be converted into a datatype (eg. "techniques", "Techniques", etc.) */
export type ConvertsToDataType =
  | DataType
  | PluralType
  | LabelType
  | PluralLabelType;

const simplifyType = (original: ConvertsToDataType) => {
  // Get rid of any spaces or hyphens (in "threat-groups"/"Threat Groups")
  let simplified = original.replaceAll(/[ -]/g, '');

  // If this was a type with "Prioritized" in its name, remove that part
  if (simplified.startsWith('Prioritized')) {
    simplified = simplified.substring('Prioritized'.length);
  }

  // If the type ends with "Type", remove that redundancy from the end
  if (simplified.endsWith('Type')) {
    simplified = simplified.substring(0, simplified.length - 'Type'.length);
  }

  // If we had PrioritizedCampaign before, and now have Campaign, lower-case it
  simplified = simplified[0].toLowerCase() + simplified.substring(1);
  return simplified;
};

/**
 * Simple helper for converting a variety of type-like things ..
 *
 * - a type label (eg. "Technique")
 * - a plural type ("techniques")
 * - a plural type label ("Techniques")
 * - a type name ("TechniqueType")
 * - a prioritized type name ("PrioritizedTechniqueType")
 *
 * ... into a simple (consistent) type string ("technique")
 *
 * NOTE: This function is designed to be used in other functions (eg.
 *       getTypeIcon), so it also accepts direct datatypes (eg. "technique"),
 *       and simply returns them unmodified.
 */
export const getType = (original: ConvertsToDataType): DataType => {
  assert(
    isString(original),
    'Invalid (non-string) value provided to getType: ' + original
  );
  const parsed = simplifyType(aliases[original] ?? simplifyType(original));
  const type =
    // It's a type to begin with: return (eg.) "technique" as is
    ((parsed in typeLabels && parsed) ||
      // It's a plural type: convert (eg.) "techniques" => "technique"
      typePluralizations[parsed] ||
      // It's a singular label: convert (eg.) "Technique" => "technique"
      labelTypes[parsed] ||
      // It's a plural label: convert (eg.) "Techniques"=> 'technique"
      pluralLabelTypes[parsed]) as DataType;

  // If it wasn't any of those, that's bad (because that input comes from us!)
  assert(type, 'Invalid type-like thing provided: ' + original);
  return type;
};

/** Converts a type or type-like string into a type label */
export const getTypeLabel = (type: ConvertsToDataType) =>
  typeLabels[getType(type)];

/**
 * Returns the (capitalized) plural form of the provided type (which is first
 * passed through getType, so it can be a plural or label type)
 */
export const getPluralType = (original: ConvertsToDataType) => {
  const type = getType(original);
  return pluralTypes[type];
};

/**
 * Similar to getPluralType, except instead of "Threat Groups" we get
 * "threat-groups".
 */
export const getPluralUrlType = (original: ConvertsToDataType) => {
  const type = getType(original);
  return type === 'threatGroup' ? 'threat-groups' : pluralTypes[type];
};

/** Returns a component (not instance) of the icon for the provided type */
export const getTypeIcon = (original: ConvertsToDataType) =>
  dataTypeIcons[getType(original)];

/**
 * Every type has a heading field (it's name, or hostname for assetss), but then
 * each type has a sub-heading also, displayed below its name on the global
 * search results (or possibly its list page), eg. its origin or Mitre ID.
 *
 * This function returns the provided type's sub-heading field.
 */
export const getTypeSubheadingField = (original: ConvertsToDataType) => {
  const type = getType(original);
  if (type === 'asset') {
    return 'origin';
  } else {
    // TODO: handle the rest
    return 'mitreId';
  }
};

/**
 * Returns a URL for the provided type/ID (eg. '/asset' or
 * '/asset/QXNzZXRUeXBlOjQ5NA==').  This is basically just the pluralized type,
 * except for assets which use the singular type.
 *
 * Note that the "id" argument could be an "id", a "mitreId", or a
 * "vulnerabilityName", as we use all three ass identifiers in our URLs.
 */
export const getTypeUrl = (original: ConvertsToDataType, id?: string) => {
  const type = getType(original);
  let pluralType = getPluralUrlType(type);

  pluralType =
    pluralType === 'assets' || pluralType == 'visibilities' ? type : pluralType;
  const baseUrl = `/${pluralType}`;
  return baseUrl + (id ? `/${id}` : '');
};
