import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ExternalApolloProvider
} from '@apollo/client';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNotification } from 'reablocks';
import { useCookie } from 'react-use';
import { useAuth } from '../Auth/AuthContext';
import { cache } from './cache';
import { buildErrorLink } from './errorLink';

import { createHttpLink } from '@apollo/client';
import { coreRestLink, restLink } from './restLink';
import { retryLink } from './retryLink';
import { customFetch } from 'core/utils/fetchUtils';

//@ts-ignore TS soesn't think our customFetch is a valid fetch; it is
const httpLink = createHttpLink({ fetch: customFetch, uri: '/api/graphql/' });

const userManagementHttpLink = createHttpLink({
  //@ts-ignore TS doesn't think our customFetch is a valid fetch; it is
  fetch: customFetch,
  uri: '/auth/query/'
});

const appHttpLink = createHttpLink({ uri: '/app/query/' });

const USERS_CLIENT_NAME = 'userManagement';
const APP_CLIENT_NAME = 'app';
const CORE_CLIENT_NAME = 'core';

export const USERS_CONTEXT = {
  context: { clientName: USERS_CLIENT_NAME, fetchPolicy: 'network-only' }
};

export const APP_CONTEXT = {
  context: { clientName: APP_CLIENT_NAME }
};

export const CORE_CONTEXT = {
  context: { clientName: CORE_CLIENT_NAME }
};

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const { refreshToken } = useAuth();
  const navigate = useNavigate();
  const { notifyError } = useNotification();
  const [csrftoken] = useCookie('csrftoken');
  const errorLink = buildErrorLink(notifyError);

  const client = useMemo(() => {
    const wrapLinks = (...links) =>
      ApolloLink.from([retryLink(navigate, refreshToken), errorLink, ...links]);

    const authLink = ApolloLink.split(
      operation => operation.getContext().clientName === USERS_CLIENT_NAME,
      wrapLinks(userManagementHttpLink),
      wrapLinks(appHttpLink)
    );
    const coreLink = ApolloLink.split(
      operation => operation.getContext().clientName === CORE_CLIENT_NAME,
      wrapLinks(coreRestLink(csrftoken), httpLink),
      wrapLinks(restLink, httpLink)
    );
    // NOTE: Not sure if this name is correct, but it seems like it from context
    const appLink = operation =>
      [USERS_CLIENT_NAME, APP_CLIENT_NAME].includes(
        operation.getContext().clientName
      );

    const combinedLink = ApolloLink.split(appLink, authLink, coreLink);

    return new ApolloClient({
      cache,
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'ignore',
          notifyOnNetworkStatusChange: true
        },
        query: {
          errorPolicy: 'all',
          notifyOnNetworkStatusChange: true
        },
        mutate: {
          errorPolicy: 'all'
        }
      },
      link: combinedLink
    });
  }, [navigate, refreshToken, csrftoken]);

  return (
    <ExternalApolloProvider client={client}>{children}</ExternalApolloProvider>
  );
};
