export interface FilterItem {
  // This is immutable and always set serially. Resets the sequence when all filters of a particular type are deleted
  id: string;
  // mutable by the user
  name: string;
  // actual filter - can be different for different pages
  filter?: any;
}

export interface SavedFilterObject {
  // Last serial number used to generate default name and id
  maxCount: number;
  // Key used to retrieve and save different types of filter from the localStorage
  filterType: string;
  // Actual list of filters
  filters: FilterItem[];
}

export const getDefaultSavedFilterObject = (
  filterType: string
): SavedFilterObject => ({
  filterType: filterType,
  maxCount: 0,
  filters: []
});

export const addNewFilter = (filterType: string, filter: any): string => {
  const savedFilterObject: SavedFilterObject =
    JSON.parse(window.localStorage.getItem(filterType)) ||
    getDefaultSavedFilterObject(filterType);
  const { maxCount, filters } = savedFilterObject;
  const newFilterId = `${filterType}-${maxCount + 1}`;
  filters.push({
    id: newFilterId,
    name: `Filter Set ${maxCount + 1}`,
    filter: filter
  });
  window.localStorage.setItem(
    filterType,
    JSON.stringify({
      filterType: filterType,
      maxCount: maxCount + 1,
      filters: filters
    })
  );
  return newFilterId;
};
