import { useContext } from 'react';
import assert from 'shared/utils/assert';

/**
 * Whenever we create a new context, we want to make an accompanying
 * useThatContext hook.  All that hook needs to do is return
 * useContext(thatContext), but it also has to throw an error if it can't
 * find the context (presumably someone forgot to add the matching Provider)/
 *
 * This helper function builds such useSomeContext hooks.  If an (optional)
 * context name is provided, the failure message (if the Provider is missing)
 * will include the appropriate hook/Provider names.
 */

export const buildUseContext =
  (Context: React.Context<any>, contextName?: string) => () => {
    const context = useContext(Context);
    assert(
      context !== undefined,
      contextName
        ? `No context found: did you forget to include a Provider?`
        : `"use${contextName}" can only be used inside a "${contextName}Provider"`
    );
    return context;
  };
