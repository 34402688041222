import { SearchResultUnion } from 'core/graphql/graphql';
import { MotionItem } from 'reablocks';
import { Link, useNavigate } from 'react-router-dom';
import {
  ConvertsToDataType,
  getPluralUrlType,
  getType,
  getTypeIcon,
  getTypeLabel,
  getTypeSubheadingField,
  getTypeUrl,
  nameFields
} from 'shared/data/DataTypeUtil';
import Highlight from 'shared/elements/Highlight/Highlight';
import assert from 'shared/utils/assert';

const ResultTitles = ({ name, searchTerm, subHeading }) => (
  <div className={'ml-sm flex min-w-[505px] flex-wrap items-end'}>
    <div className="min-w-full overflow-hidden overflow-ellipsis whitespace-nowrap pr-[10px]">
      <Highlight
        text={name}
        highlightClass="text-purple100"
        searchTerm={searchTerm}
      />
    </div>
    <div className="w-full">{subHeading}</div>
  </div>
);

const DataTypeTag = ({ type }: { type: ConvertsToDataType }) => {
  const Icon = getTypeIcon(type);
  assert(Icon, 'Unable to find icon for: ' + type);
  const typeLabel = getTypeLabel(type);

  return (
    <div
      className={
        `border border-solid border-gray400 ` + // border
        `flex items-center justify-between gap-[4px]` + // flex
        `mr-lg py-0 pl-[6px] pr-[8px] ` + // margins/padding
        `h-[24px] min-w-fit bg-gray800 ` // dimensions/background
      }
    >
      <Icon
        className="m-0 mr-[4px] min-w-[13px] fill-gray100 text-gray100"
        width="13"
        height="13"
      />
      <span className="font-sm min-w-fit text-right font-mono">
        {typeLabel}
      </span>
    </div>
  );
};

const GlobalSearchResultRow = ({
  close,
  highlightRow,
  isHighlighted,
  result,
  searchTerm
}: {
  close: any;
  highlightRow: (e: any) => void;
  isHighlighted: boolean;
  result: SearchResultUnion;
  searchTerm: string;
}) => {
  assert(close && close != window.close, 'We need a close function');
  // These vary based on whether or not the row is highlighted (highlit?)
  const highlightClasses = isHighlighted
    ? 'text-white bg-gray700'
    : 'text-gray200';
  // Technically every name comes back as "name" (except "hostname"), but then
  // to make GraphQL happy, in the query we have to alias them to these
  const nameField = nameFields.find(nameField => result[nameField]);
  const name = result[nameField];
  const type = getType(result.__typename);
  let pluralType = getPluralUrlType(type);
  // Special Case: handle "assets" wrong (as "asset") for the API
  pluralType = pluralType === 'assets' ? 'asset' : pluralType;

  const url = getTypeUrl(
    type,
    // @ts-ignore I don't want to type-check it all, but coalescing does work
    result.mitreId ?? result.vulnerabilityName ?? result.id
  );
  const subHeading = result[getTypeSubheadingField(type)];
  const navigate = useNavigate();

  const handleResultClick = url => {
    close();
    navigate(url);
  };
  return (
    // NOTE: The "globalSearchResult" class isn't for styling, it's for DOM
    //       manipulation (specifically, for highlighting rows on mouse over)
    <MotionItem
      className={
        highlightClasses + ' globalSearchResult h-16 px-2 hover:cursor-pointer'
      }
      onMouseOver={highlightRow}
      onClick={() => handleResultClick(url)}
    >
      <Link
        onClick={close}
        to={url}
        className={
          highlightClasses +
          'flex h-full items-center justify-between py-0 pl-[5px] pr-[16px]'
        }
      >
        <div className={highlightClasses + ' flex w-full justify-between'}>
          <ResultTitles {...{ name, searchTerm, subHeading }} />
          <DataTypeTag {...{ type }} />
        </div>
      </Link>
      {/* Someday if we want actions on results we'll add a "..." menu here */}
    </MotionItem>
  );
};
export default GlobalSearchResultRow;
