import { ReactComponent as ErrorIllustration } from 'assets/illustrations/error.svg';
import { FC } from 'react';
import css from './ErrorMessage.module.css';

export const ErrorMessage: FC<{ error: any }> = ({ error }) => {
  // const isRefresh =
  //   error?.toString()?.indexOf('dynamically imported module') > -1;

  // useEffect(() => {
  //   if (isRefresh) {
  //     setTimeout(() => {
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
  //       window.location.reload(true);
  //     }, 100);
  //   }
  // }, [isRefresh]);

  return (
    <div className={css.container}>
      <ErrorIllustration />
      <h2>Something bad happened</h2>
      <pre>
        <code>{error.message}</code>
      </pre>
    </div>
  );
};
