/**
 * Most of our buttons are meant to look like buttons, and we uise BaseButton
 * for those.  However, our menu instead has buttons which are only buttons to
 * get the MUI interactions (eg. the ripple effect onClick) ... but they look
 * like menu items, not buttons.
 *
 * This (very simple) component just provides a MUI button styled for our menu.
 */

import Button from '@mui/material/Button';
import { forwardRef, PropsWithChildren, useState } from 'react';

const MenuButton = forwardRef(
  (
    {
      children,
      className = '',
      isActive = false,
      ...props
    }: PropsWithChildren<{
      className?: string;
      isActive?: boolean;
      [key: string]: any; // ...props
    }>,
    ref: any // TypeScript just sucks at refs
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const isPurple = isHovered && isActive;
    const isWhite = !isPurple && (isHovered || isActive);
    return (
      <Button
        className={
          `min-h-[46px] w-full py-[6px] !font-main !normal-case ` +
          `[&_.MuiTouchRipple-root]:rounded-none ` +
          (isHovered ? '!bg-gray600 ' : '') +
          (isPurple
            ? '!text-purple150 [&_svg_path]:!fill-purple150 ' // active and hovered
            : isWhite
              ? // "active" (on item's page or that org) or hovered
                '!text-white [&_svg_path]:!fill-white '
              : // regular
                `!text-gray200 [&_svg_path]:!fill-gray100 `) + // normal/inactive
          className
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...{ ...props, ref }}
      >
        {children}
      </Button>
    );
  }
);

export const MainMenuButton = forwardRef(
  (
    {
      children,
      className = '',
      hasOpenSubmenu,
      isOnItemPage = false,
      isTopButton = false,
      onClick
    }: PropsWithChildren<{
      className?: string;
      hasOpenSubmenu?: boolean;
      isOnItemPage?: boolean;
      isTopButton?: boolean;
      onClick?: any;
    }>,
    ref
  ) => (
    <MenuButton
      {...{ onClick, ref }}
      className={
        `my-sm !ml-[1px] !mr-[2px] !min-w-0 !border-0 !py-0 ` +
        `flex items-center justify-center ` +
        `[&_svg]:h-lg [&_svg]:w-lg ` +
        // Open menus (with submenus to open) have a purple border
        (hasOpenSubmenu
          ? `!border-r-[5px] !border-solid !border-purple150 ` +
            // NOTE: The padding prevents the border from moving the icon
            `!max-w-[62px] `
          : '!max-w-[58px] ') +
        (isTopButton ? `!mt-[6px] ` : ``) +
        className
      }
      isActive={isOnItemPage}
      variant="text"
    >
      {children}
    </MenuButton>
  )
);

/**
 * A sub-menu button (ie. a button for an item in the menu that opens when you
 * click a main menu button)
 */
export const SubmenuButton = ({
  children,
  isOnItemPage = false,
  onClick
}: PropsWithChildren<{ isOnItemPage?: boolean; onClick: any }>) => (
  <MenuButton
    className={`min-h-[46px] [&>MuiTouchRipple-root]:max-w-full`}
    isActive={isOnItemPage}
    {...{ onClick }}
  >
    {children}
  </MenuButton>
);

/**
 * A menu button in the organization-switching menu
 */
export const OrganizationMenuButton = ({
  children,
  closeMenu,
  className = '',
  name,
  selectedOrganization,
  switchOrg
}) => (
  <MenuButton
    className={
      `inline-flex !justify-start gap-md px-[16px] py-[8px] font-semibold ` +
      `!text-gray200 [&_.MuiTouchRipple-root]:max-w-full ` +
      className
    }
    isActive={name === selectedOrganization?.name}
    onClick={() => {
      switchOrg(name);
      closeMenu();
    }}
  >
    {children}
  </MenuButton>
);

export default MenuButton;
