import { useEffect } from 'react';

/**
 * React doesn't want you to return a promise from useEffect (even though the
 * return value literally does nothing).  This hook avoids triggering linting
 * errors, but still saves us from this nonsense:
 *
 *     useEffect(() =>{
 *         const realEffect = async () => {
 *              // do an async thing!
 *         }
 *         realEffect();
 *     })
 *
 * NOTE: There is an NPM package for this, but it seemed like an uncessary
 *       security risk to add a dependency for something so small.
 *
 * @param effect - the callback to be run on mount/dependency change
 * @param teardown - the callback to run on teardown
 * @param dependencies - dependencies array (retriggers effect when they change)
 * @see https://github.com/rauldeheer/use-async-effect/blob/master/index.js
 */
const useAsyncEffect = async (
  effect: any,
  teardown?: any,
  dependencies?: any[]
) => {
  const hasTeardown = typeof teardown === 'function';

  // Handle variable args: if we have no teardown func, it's really dependencies
  const actualDependencies = hasTeardown ? dependencies : teardown;

  useEffect(() => {
    let result;
    let hasMounted = true;
    const mightBeAPromise = effect(() => hasMounted);
    Promise.resolve(mightBeAPromise).then(value => {
      result = value;
    });

    return () => {
      hasMounted = false;

      if (hasTeardown) {
        teardown(result);
      }
    };
  }, actualDependencies);
};

export default useAsyncEffect;
