import { useEffect, useState } from 'react';

type Dimensions = { width: number; height: number };

const getWindowDimensions = (): Dimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};
/**
 * Returns the current screen/window dimensions
 * @see https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 */
const useWindowDimensions = (): Dimensions => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // Update the dimensions state to the current window size
  const handleResize = () => setWindowDimensions(getWindowDimensions());

  useEffect(() => {
    // Whenever the window resizes, update the state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
