import RollerShadesIcon from '@mui/icons-material/RollerShades';
import { MAX_COVERAGE } from 'App/Campaigns/utils';
import { ReactComponent as ContentIcon } from 'assets/icons/content.svg';
import { ReactComponent as PriorityIcon } from 'assets/icons/priority.svg';
import { ReactComponent as TacticsIcon } from 'assets/icons/tactics.svg';
import classNames from 'classnames';
import { Stack } from 'reablocks';
import IntegrationsActiveFilterChip from 'shared/filter/ActiveFilterChips/IntegrationsActiveFilterChip';
import PlatformNamesActiveFilterChip from 'shared/filter/ActiveFilterChips/PlatformNamesActiveFilterChip';
import PriorityActiveFilterChip from 'shared/filter/ActiveFilterChips/PriorityActiveFilterChip';
import { ActiveFilterChip } from 'shared/utils/ActiveFilterChip';
import { coverageOptions } from './FilterTechniques';
import css from './Techniques.module.css';
import { MAX_PRIORITY, MIN_COVERAGE, MIN_PRIORITY } from './utils';

const TechniquesActiveFilters = ({
  className,
  filter,
  setFilter
}: {
  className?: string;
  filter: any;
  setFilter: (filter: any) => void;
}) => (
  <Stack className={classNames(css.activeFilters, className)}>
    <IntegrationsActiveFilterChip {...{ filterValues: filter, setFilter }} />
    <PriorityActiveFilterChip {...{ filter, setFilter }} />
    {/* Future version: */}
    {/* <ContentActiveFilterChip isCoverage={true} {...{ filterValues, setFilterValues }} /> */}
    {filter?.selectedCoverage && filter?.selectedCoverage !== 'content' && (
      <ActiveFilterChip
        icon={<RollerShadesIcon />}
        label="Selected Coverage"
        onDelete={() => setFilter({ ...filter, selectedCoverage: 'content' })}
        value={coverageOptions[filter?.selectedCoverage].split(' ')[0]}
      />
    )}
    {filter?.coverage &&
      filter?.coverage !== [MIN_COVERAGE, MAX_COVERAGE]?.toLocaleString() && (
        <ActiveFilterChip
          icon={<ContentIcon />}
          label="Content"
          value={filter?.coverage?.split(',').join(' to ')}
          onDelete={() =>
            setFilter({
              ...filter,
              coverage: [MIN_COVERAGE, MAX_COVERAGE]?.toLocaleString()
            })
          }
        />
      )}

    <PlatformNamesActiveFilterChip {...{ filter, setFilter }} />

    {filter?.tacticNames?.length > 0 && (
      <ActiveFilterChip
        icon={<TacticsIcon />}
        label="Tactics"
        value={filter?.tacticNames?.split(',')?.[0]}
        suffix={
          filter?.tacticNames?.split(',')?.length > 1 &&
          `+${filter?.tacticNames?.split(',')?.length - 1}`
        }
        onDelete={() => setFilter({ ...filter, tacticNames: '' })}
      />
    )}
  </Stack>
);
export default TechniquesActiveFilters;
