import { Theme, Colors } from 'reablocks';
import { borders, fontWeights, spacings } from './common';

export const themeColors: Colors = {
  black: '#000000',
  white: '#ffffff',
  blue: {
    '100': '#7AE4FF',
    '200': '#48DDFF',
    '300': '#1ECDFF',
    '400': '#06B3FF',
    '500': '#00A0FF',
    '600': '#0093EB',
    '700': '#0879C5',
    '800': '#0D669B'
  },
  purple: {
    '100': '#AC73FF',
    '200': '#9E4DFF',
    '300': '#953BFF',
    '400': '#8E14FF',
    '500': '#8100FE',
    '600': '#6D01D6',
    '700': '#5A03AF',
    '800': '#450088',
    '900': '#1b141d'
  },
  gray: {
    '100': '#90919C',
    '200': '#878994',
    '300': '#696B79',
    '400': '#454853',
    '500': '#35353A',
    '600': '#28282D',
    '700': '#1B1C23',
    '800': '#0C0A0E'
  },
  red: {
    '100': '#FF8C8C',
    '200': '#FF7272',
    '300': '#822025',
    '400': '#EB4848',
    '500': '#DA3434',
    '600': '#B61F1F',
    '700': '#961E1E',
    '800': '#7D1F1F'
  },
  orange: {
    '100': '#FFDA63',
    '200': '#FCD34D',
    '300': '#FBBF25',
    '400': '#FF980E',
    '500': '#FF7D04',
    '600': '#F06706',
    '700': '#C74D07',
    '800': '#9E3C0E'
  },
  green: {
    '100': '#39F0CB',
    '200': '#24E9C1',
    '300': '#22DDB7',
    '400': '#12CFA9',
    '500': '#04B897',
    '600': '#00A187',
    '700': '#05806D',
    '800': '#0A6558'
  },
  slate: {
    '100': '#787F85',
    '200': '#4C5155',
    '300': '#f1f3f5',
    '400': '#eceef0',
    '500': '#e6e8eb',
    '600': '#dfe3e6',
    '700': '#d7dbdf',
    '800': '#c1c8cd',
    '900': '#889096'
  },
  overlay: {
    '100': 'rgba(0, 0, 0, 0.01)',
    '200': 'rgba(0, 0, 0, 0.02)',
    '300': 'rgba(0, 0, 0, 0.03)',
    '400': 'rgba(0, 0, 0, 0.04)',
    '500': 'rgba(0, 0, 0, 0.05)',
    '600': 'rgba(0, 0, 0, 0.06)',
    '700': 'rgba(0, 0, 0, 0.07)',
    '800': 'rgba(0, 0, 0, 0.08)',
    '900': 'rgba(0, 0, 0, 0.09)'
  }
};

export const theme: Theme = {
  colors: themeColors,
  typography: {
    families: {
      fontFamily: 'Inter, sans-serif',
      monoFontFamily: 'Roboto Mono, monospace'
    },
    sizes: {
      xs: '8px',
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '20px',
      xxl: '28px'
    },
    weights: fontWeights
  },
  spacings,
  borders,
  gradients: {
    blue: {
      '100': 'linear-gradient(204deg, #19D4EE 10%, #4B5CFA 100%)',
      '200': 'linear-gradient(30deg, #2E27AD 0%, #679BFF 100%)'
    },
    orange: {
      '100': 'linear-gradient(45deg, #C8511B 0%, #FFA800 100%)'
    },
    red: {
      '100': 'linear-gradient(204deg, #FF8A8A 10%, #C14941 100%)'
    },
    green: {
      '100': 'linear-gradient(45deg, #055F4E 0%, #56C0A7 100%)'
    },
    pink: {
      '100': 'linear-gradient(204deg, #FC7AFF 10%, #C15179 100%)'
    }
  },
  shadows: {
    100: '0 2px 4px 0 rgba(17,22,26,0.16), 0 0 4px 0 rgba(17,22,26,0.08), 0 4px 8px 0 rgba(17,22,26,0.04)',
    200: '0 4px 8px 0 rgba(17,22,26,0.16), 0 4px 8px 0 rgba(17,22,26,0.08), 0 8px 16px 0 rgba(17,22,26,0.04)',
    300: '0 0 8px 0 rgba(17,22,26,0.06), 0 4px 16px 0 rgba(17,22,26,0.08), 0 8px 12px 0 rgba(17,22,26,0.06), 0 16px 24px 0 rgba(17,22,26,0.04)',
    400: '0 4px 12px 0 rgba(17,22,26,0.06), 0 4px 24px 8px rgba(17,22,26,0.12), 0 8px 16px 0 rgba(17,22,26,0.06), 0 32px 40px 0 rgba(17,22,26,0.02)',
    500: '0 4px 12px 0 rgba(0,0,0,0.08), 0 8px 32px 8px rgba(17,22,26,0.12), 0 16px 24px 8px rgba(17,22,26,0.06), 0 64px 48px 8px rgba(17,22,26,0.06)',
    600: '0 4px 12px 0 rgba(0,0,0,0.08), 0 8px 32px 8px rgba(17,22,26,0.12), 0 16px 24px 8px rgba(17,22,26,0.06), 0 64px 48px 8px rgba(17,22,26,0.06)',
    700: '0 4px 12px 0 rgba(0,0,0,0.08), 0 8px 32px 8px rgba(17,22,26,0.12), 0 16px 24px 8px rgba(17,22,26,0.06), 0 64px 48px 8px rgba(17,22,26,0.06)',
    800: '0 4px 12px 0 rgba(0,0,0,0.08), 0 8px 32px 8px rgba(17,22,26,0.12), 0 16px 24px 8px rgba(17,22,26,0.06), 0 64px 48px 8px rgba(17,22,26,0.06)',
    900: '0 4px 12px 0 rgba(0,0,0,0.08), 0 8px 32px 8px rgba(17,22,26,0.12), 0 16px 24px 8px rgba(17,22,26,0.06), 0 64px 48px 8px rgba(17,22,26,0.06)'
  },
  palettes: {
    body: {
      background: themeColors.gray['800'],
      color: '#fff'
    },
    primary: {
      background: themeColors.purple['500'],
      color: themeColors.white,
      'background-hover': themeColors.purple['600'],
      'color-hover': themeColors.purple['600']
    },
    secondary: {
      background: themeColors.gray['300'],
      color: themeColors.white,
      'background-hover': themeColors.gray['400'],
      'color-hover': themeColors.gray['400']
    },
    error: {
      background: themeColors.red['500'],
      color: themeColors.slate['100'],
      'background-hover': themeColors.red['400'],
      'color-hover': themeColors.red['400']
    },
    success: {
      background: themeColors.green['500'],
      color: themeColors.slate['100']
    },
    warning: {
      background: themeColors.orange['500'],
      color: themeColors.slate['100']
    },
    disabled: {
      background: themeColors.gray['500'],
      color: themeColors.gray['300']
    }
  },
  components: {
    block: {
      'block-spacing': spacings.md,
      'block-label-spacing': spacings.sm,
      'block-label-size': '14px'
    },
    button: {
      'button-font-weight': '500',
      'button-font-family': 'var(--font-family-mono)',
      'button-background': themeColors.gray['300'],
      'button-background-hover': themeColors.gray['100'],
      'button-color': themeColors.white,
      'button-color-hover': themeColors.white,
      'button-color-on-background': themeColors.white,
      'button-disabled-color-on-background': themeColors.gray['300'],
      'button-border-radius': '0',
      'button-border': `solid 1px ${themeColors.gray['500']}`,
      'button-focus': themeColors.purple['200'],
      'button-focus-offset': spacings.xs,
      'button-spacing-sm': `${spacings.xs} ${spacings.sm}`,
      'button-spacing-md': `${spacings.sm} ${spacings.md}`,
      'button-spacing-lg': `${spacings.md} ${spacings.lg}`
    },
    card: {
      'card-border': `1px solid ${themeColors.gray['400']}`,
      'card-border-radius': '0',
      'card-spacing': spacings.md,
      'card-background': themeColors.gray['800'],
      'card-color': themeColors.white
    },
    checkbox: {
      'checkbox-label-color': themeColors.gray['300'],
      'checkbox-check-stroke': themeColors.purple['100'],
      'checkbox-box-checked-stroke': themeColors.purple['100'],
      'checkbox-box-unchecked-stroke': themeColors.gray['300']
    },
    chip: {
      'chip-font-family': 'var(--mono-font-family)',
      'chip-background': themeColors.gray['600'],
      'chip-background-hover': themeColors.gray['500'],
      'chip-color': themeColors.gray['200'],
      'chip-color-hover': themeColors.gray['100'],
      'chip-border-radius': '0'
    },
    dialog: {
      'dialog-background': themeColors.gray['600'],
      'dialog-color': themeColors.white
    },
    divider: {
      'divider-background': themeColors.gray['600'],
      'divider-spacing': spacings.xl
    },
    drawer: {
      'drawer-background': themeColors.gray['700'],
      'drawer-color': themeColors.white,
      'color-layer-transparent': 'rgba(0, 0, 0, 0.5)'
    },
    input: {
      'input-background': 'transparent',
      'input-font-family': 'var(--font-family-mono)',
      'input-color': themeColors.white,
      'input-color-placeholder': themeColors.gray['300'],
      'input-color-adornment': themeColors.gray['300'],
      'input-color-disabled': themeColors.gray['300'],
      'input-placeholder-style': 'normal',
      'input-border': `1px solid ${themeColors.gray['400']}`,
      'input-border-radius': '0',
      'input-border-focus': themeColors.purple['500'],
      'input-spacing-md': '9px 15px',
      'input-spacing-sm': spacings.sm,
      'input-spacing-lg': spacings.lg
    },
    list: {
      'list-item-spacing': '14px',
      'list-item-dense-spacing': spacings.md,
      'list-item-color': 'inherit',
      'list-item-border-radius': '0',
      'list-item-color-active': 'inherit',
      'list-item-background-active': 'transparent',
      'list-item-adornment-fill': 'currentColor'
    },
    loader: {
      'loader-background': themeColors.slate['300']
    },
    notification: {
      'notification-background': themeColors.gray['700'],
      'notification-color': themeColors.white,
      'notification-border': `solid 1px ${themeColors.gray['500']}`,
      'notification-color-error': themeColors.red['100'],
      'notification-color-warning': themeColors.orange['100'],
      'notification-color-success': themeColors.green['100']
    },
    popover: {
      'popover-background': themeColors.gray['600'],
      'popover-color': themeColors.white,
      'popover-border': '0',
      'popover-border-radius': '0',
      'popover-spacing': spacings.md
    },
    range: {
      'range-track-background': themeColors.gray['400'],
      'range-track-border-radius': '0',
      'range-track-size': '2px',
      'range-handle-size': '14px',
      'range-handle-border-radius': '0',
      'range-track-active-background': themeColors.purple['500'],
      'range-handle-background': 'var(--primary-color)'
    },
    radio: {
      'radio-label-color': themeColors.white,
      'radio-stroke': themeColors.gray['300'],
      'radio-stroke-active': themeColors.purple['500'],
      'radio-indicator-active': themeColors.purple['500'],
      'radio-stroke-size': '1px',
      'radio-background': 'transparent'
    },
    typography: {
      'page-title-font-size': '40px',
      'page-title-font-weight': 'var(--font-weight-bold)',
      'page-title-color': themeColors.white,
      'page-title-margin': '0 0 var(--spacing-lg) 0',
      'primary-heading-font-size': 'var(--font-size-xxl)',
      'primary-heading-font-weight': 'var(--font-weight-extraBold)',
      'primary-heading-color': themeColors.white,
      'primary-heading-margin': '0 0 var(--spacing-sm) 0',
      'secondary-heading-font-size': 'var(--font-size-xl)',
      'secondary-heading-font-weight': 'var(--font-weight-normal)',
      'secondary-heading-color': themeColors.white,
      'secondary-heading-margin': '0 0 var(--spacing-sm) 0',
      'small-heading-font-size': 'var(--font-size-lg)',
      'small-heading-font-weight': 'var(--font-weight-thin)',
      'small-heading-color': themeColors.white,
      'small-heading-margin': '0 0 var(--spacing-sm) 0',
      'sub-font-size': 'var(--font-size-sm)',
      'sub-font-weight': 'var(--font-weight-bold)',
      'sub-color': themeColors.white,
      'sub-margin': '0 0 var(--spacing-xs) 0'
    },
    select: {
      'select-input-background': 'transparent',
      'select-input-border': 'var(--input-border)',
      'select-input-border-radius': '0',
      'select-input-color': themeColors.white,
      'select-input-spacing': `6px 15px`,
      'select-input-error': themeColors.red['500'],
      'select-input-placeholder-color': themeColors.gray['300'],
      'select-input-disabled-color': themeColors.gray['100'], // ?
      'select-input-icon-color': themeColors.slate['900'], // ?

      'select-menu-background': themeColors.gray['800'],
      'select-menu-border': `solid 1px ${themeColors.purple['500']}`,
      'select-menu-border-radius': '0',
      'select-menu-item-color': themeColors.white,
      'select-menu-group-color': themeColors.slate['50'],
      'select-menu-item-spacing': `${spacings.sm} ${spacings.md}`,
      'select-menu-item-active-background': themeColors.gray['700'],
      'select-menu-item-active-color': themeColors.white,
      'select-menu-item-selected-color': themeColors.white,
      'select-menu-item-selected-background': themeColors.gray['700'],

      'select-chip-background': themeColors.gray['700'],
      'select-chip-border': 'solid 1px transparent',
      'select-chip-border-radius': 'var(--chip-border-radius)',
      'select-chip-color': themeColors.gray['200'],
      'select-chip-icon-color': themeColors.gray['300']
    },
    tab: {
      'tab-color': themeColors.gray['200'],
      'tab-color-active': themeColors.white,
      'tab-border-hover': themeColors.gray['400'],
      'tab-border-active': themeColors.purple['500']
    },
    table: {
      'table-header': themeColors.gray['200'],
      'table-divider': themeColors.gray['600']
    },
    avatar: {
      'avatar-initials-color': themeColors.white,
      'avatar-border': 'solid 1px var(--body-background)'
    },
    Stack: {
      'stack-gap': spacings.md,
      'stack-dense-gap': spacings.sm
    },
    avatarGroup: {
      'avatar-group-spacing': `calc(${spacings.md} * -1)`
    },
    toggle: {
      'toggle-background': 'transparent',
      'toggle-background-checked': 'transparent',
      'toggle-border': `1px solid ${themeColors.gray['300']}`,
      'toggle-border-checked': `1px solid ${themeColors.purple['500']}`,
      'toggle-border-radius': borders.radius.lg,
      'toggle-spacing': '5px',
      'toggle-handle-background': themeColors.gray['300'],
      'toggle-handle-checked-background': themeColors.purple['500'],
      'toggle-handle-border-radius': borders.radius.lg
    },
    textarea: {
      'textarea-background': 'transparent',
      'textarea-border': `1px solid ${themeColors.gray['400']}`,
      'textarea-border-radius': '0',
      'textarea-border-focus': themeColors.purple['500'],
      'textarea-color': themeColors.white,
      'textarea-color-placeholder': themeColors.gray['300'],
      'textarea-placeholder-style': 'normal',
      'textarea-spacing-md': '9px 15px',
      'textarea-spacing-sm': spacings.sm,
      'textarea-spacing-lg': spacings.lg
    },
    tooltip: {
      'tooltip-background': themeColors.gray['600'],
      'tooltip-color': themeColors.white,
      'tooltip-border-radius': '0',
      'tooltip-spacing': spacings.sm
    }
  }
};
