import { DeletableChip, Stack } from 'reablocks';
import { FC, ReactElement } from 'react';

interface ActiveFilterChipProps {
  disableDelete?: boolean;
  icon?: ReactElement;
  label: ReactElement | string;
  onDelete?: () => void;
  suffix?: ReactElement | string;
  value?: ReactElement | string;
}

export const ActiveFilterChip: FC<ActiveFilterChipProps> = ({
  disableDelete,
  icon,
  label,
  onDelete,
  suffix,
  value
}) => (
  <DeletableChip
    deleteIcon={disableDelete && <></>}
    onDelete={onDelete}
    start={
      <Stack>
        {icon ? icon : <></>}
        {label}
      </Stack>
    }
    variant="outline"
  >
    :&nbsp;
    <span className="max-h-[1.2em] max-w-[20em] truncate leading-[1.2em] text-white">
      {value}
    </span>
    &nbsp;{suffix}
  </DeletableChip>
);
