interface FilterType {
  integrationNames: string;
}

const IntegrationsActiveFilterChip = ({
  field = 'integrationNames',
  filterValues,
  setFilter
}: {
  field?: string;
  filterValues: FilterType;
  setFilter: any;
}) => {
  // Currently we don't show a chip for integrations
  return null;
  // const integrationNames = (filterValues?.[field] || '')
  //   .split(',')
  //   .filter(x => x);

  // return integrationNames.length ? (
  //   <ActiveFilterChip
  //     icon={<IntegrationIcon />}
  //     label="Integrations"
  //     value={integrationNames[0]}
  //     suffix={integrationNames.length > 1 && `+${integrationNames.length - 1}`}
  //     onDelete={() => setFilter({ ...filterValues, [field]: '' })}
  //   />
  // ) : null;
};
export default IntegrationsActiveFilterChip;
