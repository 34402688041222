import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import {
  Block,
  Button,
  Card,
  Checkbox,
  Menu,
  Select,
  SelectMenu,
  SelectOption,
  Stack
} from 'reablocks';
import { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import BaseSlider from 'shared/elements/MuiBase/BaseSlider';
import { splitSliderValue } from 'shared/filter/FilterUtil';
import IntegrationFilter from 'shared/filter/Filters/IntegrationFilter';
import { SavedFilters } from 'shared/utils/SavedFilters';
import { FilterItem, addNewFilter } from 'shared/utils/SavedFilters/utils';
import {
  KEY_SAVED_FILTER_TECHNIQUES,
  MAX_COVERAGE,
  MAX_PRIORITY,
  MIN_COVERAGE,
  MIN_PRIORITY,
  TechniquesFilter
} from '../utils';
import css from './FilterTechniques.module.css';

export const coverageOptions = {
  content: 'Combined',
  detectionsPercentage: 'Detection',
  telemetryPercentage: 'Visibility'
};

/**
 * NOTE: This filter component is somewhat unique, in that it is used not just
 *       on the Techniques page, but also on the Attack Matrix page.
 */
interface FilterTechniquesProps {
  allPlatformNames: string[];
  allTacticNames: string[];
  allIntegrationNames: string[];
  filters: TechniquesFilter;
  hasSelectedCoverage?: boolean;
  onFilterChange: (filter: TechniquesFilter) => void;
  savedFilterId: string;
  setSavedFilterId: (savedFilterId: string) => void;
}

export const FilterTechniques: FC<FilterTechniquesProps> = ({
  allPlatformNames,
  allTacticNames,
  allIntegrationNames,
  filters,
  hasSelectedCoverage,
  onFilterChange,
  savedFilterId,
  setSavedFilterId
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [lastSelectedFilterSet, setLastSelectedFilterSet] =
    useState<TechniquesFilter | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [tacticValue, setTacticValue] = useState<string[] | null>(
    filters?.tacticNames?.split(',') || []
  );
  const [integrationValue, setIntegrationValue] = useState<string[] | null>(
    filters?.integrationNames?.split(',') || []
  );
  const [platformValue, setPlatformValue] = useState<string[] | null>(
    filters?.platformNames?.split(',') || []
  );

  const [priorityValue, setPriorityValue] = useState<[number, number]>(
    splitSliderValue(filters?.priority)
  );

  const [coverageValue, setCoverageValue] = useState<[number, number]>(
    splitSliderValue(filters?.coverage)
  );

  const [selectedCoverageValue, setSelectedCoverageValue] = useState('content');

  const updatedFilterObject = useMemo(
    () => ({
      selectedCoverage: selectedCoverageValue.toLocaleString(),
      tacticNames: tacticValue.toLocaleString(),
      integrationNames: integrationValue.toLocaleString(),
      platformNames: platformValue.toLocaleString(),
      priority: priorityValue.toLocaleString(),
      coverage: coverageValue.toLocaleString()
    }),
    [
      tacticValue,
      integrationValue,
      platformValue,
      priorityValue,
      coverageValue,
      selectedCoverageValue
    ]
  );

  const updateFilters = useCallback(() => {
    onFilterChange(updatedFilterObject);
  }, [onFilterChange, updatedFilterObject]);

  const resetFilter = useCallback(
    (newFilter: TechniquesFilter = filters) => {
      setSelectedCoverageValue(newFilter?.selectedCoverage ?? 'content');
      setTacticValue(newFilter?.tacticNames?.split(',') || []);
      setIntegrationValue(newFilter?.integrationNames?.split(',') || []);
      setPlatformValue(newFilter?.platformNames?.split(',') || []);
      setCoverageValue(splitSliderValue(newFilter?.coverage));
      setPriorityValue(splitSliderValue(newFilter?.priority));
    },
    [filters]
  );

  const onClearFilter = () => {
    onFilterChange({
      selectedCoverage: 'content',
      tacticNames: [].toLocaleString(),
      integrationNames: [].toLocaleString(),
      platformNames: [].toLocaleString(),
      priority: [MIN_PRIORITY, MAX_PRIORITY].toLocaleString(),
      coverage: [MIN_COVERAGE, MAX_COVERAGE].toLocaleString()
    });
    setSavedFilterId(null);
    setOpen(false);
  };

  const onSaveFilter = () => {
    const newFilterId = addNewFilter(
      KEY_SAVED_FILTER_TECHNIQUES,
      updatedFilterObject
    );
    updateFilters();
    setSavedFilterId(newFilterId);
    setOpen(false);
  };

  const onSavedFilterSelected = (filterItem: FilterItem) => {
    resetFilter(filterItem?.filter);
    setSavedFilterId(filterItem?.id);
    setLastSelectedFilterSet(filterItem?.filter);
  };

  const onApplyFilter = () => {
    updateFilters();
    if (!isEqual(updatedFilterObject, lastSelectedFilterSet)) {
      setSavedFilterId(null);
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        variant="outline"
        ref={buttonRef}
        className={css.button}
        onClick={() => {
          setOpen(!open);
          resetFilter();
        }}
      >
        <FilterIcon />
        <div>Filter</div>
      </Button>
      <Menu
        open={open}
        reference={buttonRef}
        placement="bottom-end"
        onClose={() => {
          setOpen(false);
          resetFilter();
        }}
        autofocus={false}
      >
        <Card className={css.card} data-testid="filters-card">
          <Stack inline={false} justifyContent="spaceBetween">
            <div>Filters</div>
            <Stack inline={false}>
              <Button
                variant="text"
                disablePadding
                size="small"
                className={css.clearBtn}
                onClick={onClearFilter}
              >
                <CloseIcon className={css.closeIcon} />
                &nbsp;<span>Clear All</span>
              </Button>
              <Button
                variant="text"
                disablePadding
                size="small"
                className={css.saveBtn}
                onClick={onSaveFilter}
              >
                <SaveIcon />
                &nbsp;<span>Save Filter Set</span>
              </Button>
            </Stack>
          </Stack>
          <br />
          <Block label="Saved Filter Set">
            <SavedFilters
              filterType={KEY_SAVED_FILTER_TECHNIQUES}
              defaultSavedFilterId={savedFilterId}
              onFilterSelected={onSavedFilterSelected}
            />
          </Block>
          <br />
          <IntegrationFilter
            setValue={setIntegrationValue}
            value={integrationValue}
          />
          <br />
          {hasSelectedCoverage && (
            <>
              <Block label="Selected Coverage">
                <Select
                  // NOTE: Without min-width everything looks fine, but then when you pick
                  //       an option, the difference in width between the options can make
                  //       the whole section shift.
                  className={`min-w-[200px]`}
                  clearable={false}
                  menu={<SelectMenu className={css.selectMenu} />}
                  placeholder="Select"
                  onChange={setSelectedCoverageValue}
                  value={selectedCoverageValue}
                >
                  {Object.keys(coverageOptions).map(option => (
                    <SelectOption key={option} value={option}>
                      {coverageOptions[option]}
                    </SelectOption>
                  ))}
                </Select>
              </Block>
              <br />
            </>
          )}
          <Block label="Priority">
            <br />
            <div className={css.range}>
              <BaseSlider
                label="Priority"
                min={MIN_PRIORITY}
                max={MAX_PRIORITY}
                onChange={setPriorityValue}
                value={priorityValue}
              />
            </div>
          </Block>
          <br />
          <Block label="Content Coverage">
            <br />
            <div className={css.range}>
              <BaseSlider
                label="Content Coverage"
                min={MIN_COVERAGE}
                max={MAX_COVERAGE}
                onChange={setCoverageValue}
                value={coverageValue}
              />
            </div>
          </Block>
          <br />
          <Block label="Platforms">
            <Select
              multiple
              closeOnSelect={false}
              placeholder="Select"
              value={platformValue}
              onChange={setPlatformValue}
              menu={<SelectMenu className={css.selectMenu} />}
            >
              {allPlatformNames.map(platform => (
                <SelectOption
                  key={platform}
                  value={platform}
                  inputLabel={platform}
                  menuLabel={
                    <Fragment>
                      <Checkbox
                        checked={platformValue?.includes(platform)}
                        label={platform}
                        containerClassName={css.checkboxContainer}
                        labelClassName={css.checkboxLabel}
                      />
                    </Fragment>
                  }
                >
                  {platform}
                </SelectOption>
              ))}
            </Select>
          </Block>
          <br />
          <Block label="Tactics">
            <Select
              multiple
              closeOnSelect={false}
              placeholder="Select"
              value={tacticValue}
              onChange={setTacticValue}
              menu={<SelectMenu className={css.selectMenu} />}
            >
              {allTacticNames.map(tactic => (
                <SelectOption
                  key={tactic}
                  value={tactic}
                  inputLabel={tactic}
                  menuLabel={
                    <Checkbox
                      checked={tacticValue?.includes(tactic)}
                      label={tactic}
                      containerClassName={css.checkboxContainer}
                      labelClassName={css.checkboxLabel}
                    />
                  }
                >
                  {tactic}
                </SelectOption>
              ))}
            </Select>
          </Block>
          <br />
          <Button color="primary" onClick={onApplyFilter}>
            Apply
          </Button>{' '}
          <Button
            onClick={() => {
              setOpen(false);
              resetFilter();
            }}
          >
            Cancel
          </Button>
        </Card>
      </Menu>
    </>
  );
};
