interface CustomFetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

/**
 * If the user has selected a specific sub-organization (to only view data for),
 * this custom fetch function adds the appropriate header, to tell the server.
 */
export const customFetch = (
  uri: string,
  options: CustomFetchOptions = {}
): Promise<Response> => {
  const selectedOrgId = JSON.parse(
    localStorage.getItem('@interpres:selected-organization-id')
  );

  if (selectedOrgId) {
    // TODO: The substring is a hack fix to remove quotes that are somehow
    //       wrapping the selectedOrgId.  Figure out if we're actually putting
    //       quotes into local storage, or if our local storage tool is doing
    //       it ... and either way remove them at the source, instead of here.
    options.headers['X-SUB-TENANT-ID'] = selectedOrgId;
  }

  return fetch(uri, options);
};
