import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
/**
 * This is just a basic wrapper around the MUI tooltip, to add some styling
 * (eg. we want our tooltips to default to our main Inter font, but MUI uses
 * their Roboto).
 */

const Tooltip = ({
  children,
  className,
  placement = 'right',
  title
}: TooltipProps) => (
  <MuiTooltip
    classes={{
      popper: className + ' font-main p-[6px] text-white'
    }}
    {...{ placement, title }}
  >
    {children}
  </MuiTooltip>
);

export default Tooltip;
