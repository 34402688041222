import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Input, Stack } from 'reablocks';
import { FC, useState } from 'react';
import { FilterItem } from '../utils';
import css from './SavedFilterItem.module.css';
import { IconButton } from 'shared/elements/IconButton';

interface SavedFilterItemProps {
  filter: FilterItem;
  onSelect?: (filter: FilterItem) => void;
  onDelete?: (filter: FilterItem) => void;
  onUpdate?: (filter: FilterItem) => void;
}

export const SavedFilterItem: FC<SavedFilterItemProps> = ({
  filter,
  onDelete,
  onUpdate,
  onSelect
}) => {
  const { name } = filter;

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(name || '');

  return (
    <>
      {!inEditMode ? (
        <Stack
          direction="row"
          inline={false}
          justifyContent="spaceBetween"
          className={css.row}
          onClick={() => onSelect(filter)}
        >
          <div>{name}</div>
          <Stack direction="row" inline={false} dense>
            <IconButton
              variant="text"
              size="small"
              disablePadding
              onClick={e => {
                setInEditMode(true);
                e.stopPropagation();
              }}
              icon={<EditIcon className={css.icon} />}
            />
            <IconButton
              variant="text"
              size="small"
              disablePadding
              onClick={e => {
                onDelete(filter);
                e.stopPropagation();
              }}
              icon={<DeleteIcon className={css.icon} />}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="row"
          inline={false}
          justifyContent="spaceBetween"
          onClick={e => e.stopPropagation()}
          className={css.row}
        >
          <Input
            placeholder="Enter name"
            defaultValue={name}
            onValueChange={setNewName}
          />
          <Stack direction="row" inline={false} dense>
            <IconButton
              variant="text"
              size="small"
              disablePadding
              onClick={() => {
                setInEditMode(false);
                onUpdate({ ...filter, name: newName });
              }}
              icon={<CheckIcon className={css.icon} />}
            />
            <IconButton
              variant="text"
              size="small"
              disablePadding
              onClick={() => {
                setInEditMode(false);
                setNewName(name);
              }}
              icon={<CloseIcon className={css.icon} />}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
