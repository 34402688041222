export const MAX_PRIORITY = 100;
export const MIN_PRIORITY = 0;
export const MAX_COVERAGE = 100;
export const MIN_COVERAGE = 0;

export interface ThreatGroupsRow {
  id: string;
  nameAndId: {
    name: string;
    id: string;
  };
  description: string;
  priority: number;
  content: number;
  aliasNames: string[];
  associations: {
    techniques: number;
    softwares: number;
    campaigns: number;
  };
  modifiedTimestamp: string;
  defenceSurface: {
    visibility: number;
    detections: number;
    controls: number;
  };
}

export interface ThreatGroupsFilter {
  priority?: string;
  content?: string;
  integrations?: string;
  softwareMitreIds?: string;
  campaignStixIds?: string;
}

export const ORDER_BY_FIELDS = {
  PRIORITY: 'priority',
  NAME: 'name',
  CONTENT: 'content',
  DETECTIONS_PERCENTAGE: 'detectionsPercentage'
};

export const KEY_SAVED_FILTER_THREAT_GROUPS = '@interpres:threatGroups-filter';
