import Menu from '@mui/material/Menu';
import { useGlobalSearchContext } from 'App/GlobalSearch/GlobalSearchContext';
import classNames from 'classnames';
import { List } from 'reablocks';
import { MouseEventHandler, ReactElement, useRef, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import Tooltip from 'shared/layers/Tooltip/NewTooltip';
import { MainMenuButton } from './MenuButton';
import css from './Nav.module.css';

/**
 * Renders a root-level menu item (ie. an icon button).
 *
 * That button can be optionally be wrapped in either a:
 * - link (for a single-option menu item)
 * - pop-over (for a menu item with a sub-menu)
 *
 * Either way, it will also be wrapped in a tooltip, so that the user can see
 * the menu item's name when they hover over it.
 *
 */
const MenuItem = ({
  buttonClassName,
  icon,
  isActive = false,
  isActivePatterns = [],
  isTopButton,
  link,
  // modifiers = MODIFIERS,
  name,
  onClick,
  setHasMenuOpen,
  submenuClass,
  submenu
}: {
  /** Class to add to the generated <Button> */
  buttonClassName?: string;

  /** An element for the button's icon (presumably an SVG) */
  icon: any; // This should be ReactElement, but TS is weird
  /**
   * Most menus use isActivePattern, but global search has no URL, so it uses
   * this prop instead
   */
  isActive?: boolean;

  /**
   * The menu item will become "active" if any of these match the current URL
   */
  isActivePatterns?: string[];

  /** Indicates the button is at the bottom (requiring different styling) */
  isABottomButton?: boolean;

  /** Indicates that the button is the top-most one in the menu (for styling) */
  isTopButton?: boolean;

  /** The path of the menu item's link */
  link?: string;

  /** The modifiers to pass to the generated ReaBlocks Popover/Tooltip */
  modifiers?: {
    offset: {
      offset: string;
    };
  };
  /** The tooltip to display, and also the name of the sub-menu (if any) */
  name?: string;

  /**
   * If (instead linking to a page) the item triggers custom logic when clicked,
   * this prop specifies that logic
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /** Tells Nav that we've opened/closed this item's menu */
  setHasMenuOpen: (hasMenuOpen: boolean) => void;

  /** If the item has a sub-menu, this sets the class of that submenu */
  submenuClass?: string;

  /**
   * If the item has a sub-menu, this prop contains its React elements
   * (which presumably will be made up of SubmenuItem/UserSubmenuItem tags)
   */
  submenu?: (onClose: any) => ReactElement;

  /* A classname to add to the generated tooltip's "trigger" element */
  triggerClassName?: string;
}) => {
  const { isOpen: isGlobalSearchOpen } = useGlobalSearchContext();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = !!anchorEl;

  const matchesCurrentPath = pattern => matchPath(pattern, pathname);
  // Make the menu white if we're on the page, or the item is global search and
  // we have global search open ... but if global search is open *don't* make
  // any other menu item white.
  const isOnItemPage =
    isActive ||
    (!isGlobalSearchOpen && isActivePatterns.some(matchesCurrentPath));
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Start with a plain old <Button/>
  let button = (
    <Tooltip
      className={
        `[&>.MuiTooltip-tooltip]:!ml-[6px] ` +
        `[&>.MuiTooltip-tooltip]:!bg-gray600 ` +
        `[&>.MuiTooltip-tooltip]:!text-md ` +
        `[&>.MuiTooltip-tooltip]:!font-main`
      }
      title={name}
    >
      <span className="w-full">
        <MainMenuButton
          {...{ isOnItemPage, isTopButton }}
          className={buttonClassName}
          hasOpenSubmenu={submenu && isOpen}
          onClick={e => {
            // TODO: We're not using ReaBlocks! Test if this is still necessary
            setTimeout(() => {
              onClick && onClick(e);
              submenu && setAnchorEl(e.target);
              setHasMenuOpen(true);
            }, 100);
          }}
          ref={buttonRef}
        >
          {icon}
        </MainMenuButton>
      </span>
    </Tooltip>
  );

  if (submenu) {
    const onClose = () => setAnchorEl(null);
    // If we have a submenu, also wrap the (tooltip-wrapped) button in a menu
    button = (
      <>
        <Menu
          {...{ anchorEl: buttonRef.current, onClose }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={
            `[&_.MuiPaper-root]:menu-clip ml-[7px] ` +
            `[&_.MuiPaper-root]:mt-[-5px] ` +
            `[&_ul]:bg-gray700`
          }
          open={isOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <div className={classNames(css.menu, submenuClass)}>
            {/* TODO: I think all the list doeos is limit the menu width;
                      Remove it, add a max width, and see if it works */}
            <List>
              {/* The org menu has no header text, so we hide the H2 for it */}
              {name && (
                <h2 className="!py-md px-[12px] !text-lg !text-purple150">
                  {name}
                </h2>
              )}
              {/* Render props pattern: "submenu" is expected to be a function
                  that will return the submenu (React element) that we actually
                  want to render here (and this way that function can use
                  onClose, when it otherwise couldn't) */}
              {submenu({ onClose })}
            </List>
          </div>
        </Menu>
        {button}
      </>
    );
  }

  if (link) {
    // If this is a single-page menu item, wrap it in a link to its page
    button = (
      <Link className="w-full" to={link}>
        {button}
      </Link>
    );
  }

  return button;
};

export default MenuItem;
