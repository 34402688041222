import Button from '@mui/material/Button';
import { Divider, List, useNotification } from 'reablocks';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ColoredLetterCircle from 'shared/elements/ColoredLetterCircle/ColoredLetterCircle';
import { SearchInput } from 'shared/form/SearchInput';
import ChevronIcon, { Direction } from 'shared/icons/ChevronIcon';
import { useUserContext } from 'shared/user/UserContext';
import MenuButton, { OrganizationMenuButton } from './MenuButton';
import MenuItem from './MenuItem';

const circledLetterColor = '#06B3FF'; // blue400

/**
 * This is an awkward way of closing the org-switching menu, but because
 * ReaBlocks doesn't give us a way to close the menu through code, we have to do
 * it with a (fake) mousedown event.
 */
const closeMenu = () => {
  const fakeEvent = new MouseEvent('mousedown', { bubbles: true });
  document.body.dispatchEvent(fakeEvent);
};

const ParentOrganizationRow = ({ name, selectedOrganization, switchOrg }) => (
  <>
    <OrganizationMenuButton
      {...{ closeMenu, name, selectedOrganization, switchOrg }}
      className="items-center"
    >
      <ColoredLetterCircle color={circledLetterColor} letter={name} size={36} />
      <div className="font-main text-md font-semibold">{name}</div>
    </OrganizationMenuButton>
  </>
);

const childColor = '#0A6558'; // green800

const ChildOrganizationRow = ({ name, selectedOrganization, switchOrg }) => (
  <OrganizationMenuButton
    {...{ closeMenu, name, selectedOrganization, switchOrg }}
  >
    <ColoredLetterCircle color={childColor} letter={name} size={20} />
    <div className="text-md font-semibold">{name}</div>
  </OrganizationMenuButton>
);

const OrganizationList = ({
  organizations,
  selectedOrganization,
  switchOrg
}) => (
  <List className="pl-md">
    {organizations.map(({ id, name }) => (
      <ChildOrganizationRow
        key={id}
        {...{ name, selectedOrganization, switchOrg }}
      />
    ))}
  </List>
);

/**
 * Determines what to tell the user after they try to switch organzations
 * @param newName - name of the organization the user is switching to
 * @param mainName - name of the user's main/parent organization
 * @param oldName - name of organization the user was on (before switching)
 */
// NOTE: Only exported for testing
export const getSwitchMessage = (mainName, newName, oldName = null): string => {
  // If we're switching to the main org we don't use it's name, we use null
  // TODO: Would it be simpler to just use its name?
  newName = newName === mainName ? null : newName;

  // The user switched to the same org they were already on
  if (newName === oldName) {
    return `Already viewing ${newName || `all ${mainName}`} data`;
  }
  if (newName) {
    // Standard case: the user picked a new child org
    return `Now viewing only ${newName} data`;
    // return oldName ? `${message} (no longer viewing ${oldName} data)` : message;
  } else {
    // The user picked the parent org
    return `Now viewing all ${mainName} data`;
  }
};

const MoreResults = ({
  direction,
  more,
  onClick
}: {
  direction: Direction;
  more: number;
  onClick: () => void;
}) =>
  more !== 0 && (
    // On the first MoreResults the chevron points up and is above the text
    <Button className="w-full !normal-case">
      <div className="px-[11px] py-[8px] text-center" onClick={onClick}>
        {direction === 'up' && (
          <div className="flex min-w-full justify-center">
            <ChevronIcon {...{ direction }} />
          </div>
        )}
        <div className="font-lg mb-[4px] min-w-full text-purple200">
          <span className="cursor-pointer">{more} more</span>
        </div>
        {/* On the seconx, the chevron points down and is above the text */}
        {direction === 'down' && (
          <div className="flex min-w-full cursor-pointer justify-center">
            <ChevronIcon {...{ direction }} />
          </div>
        )}
      </div>
    </Button>
  );

const ChildOrganizationSubmenu = ({
  childOrganizations,
  mainOrganization,
  onClose,
  repositionMenu,
  selectedOrganization,
  switchOrganization
}) => {
  const navigate = useNavigate();
  const { notifySuccess } = useNotification();
  const [searchName, setSearchName] = useState('');
  const [scrollIndex, setScrollIndex] = useState(0);
  const search = searchName => {
    setScrollIndex(0);
    setSearchName(searchName);
  };

  const { name } = mainOrganization;
  const switchOrg = (newOrgName: string): void => {
    switchOrganization(newOrgName);
    // TODO: This next bit is mostly duplicated in UserContext ... but that
    //       vesion doesn't have the newOrgName, so it can't replace this code
    //       Solve that by making another local storage var (previousSelectedOrg)
    //       and then use it in UserContext ... and then eliminate this and rely
    //       on the useEffect there instead
    notifySuccess(
      getSwitchMessage(mainOrganization.name, newOrgName, selectedOrganization)
    );
    window.setTimeout(() => navigate('/'));
  };
  const preventBubbling = e => {
    // Bubbling up would close the menu
    e.stopPropagation();
  };

  // Render one of three versions, based on how many child orgs we have
  if (!childOrganizations.length) {
    // We have no child orgs yet: invite the user to add one.
    return (
      <div onClick={preventBubbling}>
        {/* Top Row (instruction text) */}
        <div
          className={
            // Layout
            'flex items-center justify-center overflow-hidden ' +
            // Box Model
            'w-[260px] px-[39px] pt-[12px]'
          }
        >
          <div
            className={
              'font-md text-center font-semibold text-gray200 ' +
              `w-max-full whitespace-normal `
            }
          >
            Select an organization to view content
          </div>
        </div>
        {/* Middle Row (company icon/name/domain)*/}
        <ParentOrganizationRow {...{ name, selectedOrganization, switchOrg }} />
        <Divider disableMargins className="m-xs" />
        {/* Bottom Row (button) */}
        <div>
          <Link to="/settings/organizations/new">
            <MenuButton
              className="m-[16px] mb-[28px] w-[228px] border-0 !bg-purple500 px-[15px]"
              onClick={onClose}
            >
              Add Organization
            </MenuButton>
          </Link>
        </div>
      </div>
    );
  } else if (childOrganizations.length < 8) {
    // We have seven or less: no need for search UI, just show all child orgs
    return (
      <div onClick={preventBubbling}>
        <ParentOrganizationRow {...{ name, selectedOrganization, switchOrg }} />
        <Divider disableMargins className="m-xs" />
        <OrganizationList
          {...{
            organizations: childOrganizations,
            selectedOrganization,
            switchOrg
          }}
        />
      </div>
    );
  } else {
    const filteredOrganizations = childOrganizations.filter(
      ({ name }) =>
        // Either we're not searching (show everything), or ...
        !searchName ||
        // Only show orgs with names containing the search term
        // (case-insensitive) that the user entered
        name.toLowerCase().includes(searchName.toLowerCase())
    );
    const displayedOrganizations = filteredOrganizations.slice(
      scrollIndex,
      scrollIndex + 7
    );
    return (
      <div className="min-h-[421px]" onClick={preventBubbling}>
        <ParentOrganizationRow {...{ name, selectedOrganization, switchOrg }} />
        <Divider disableMargins className="m-xs" />
        <SearchInput
          containerClassname="m-[16px] min-w-[262px] !pl-md"
          className="min-w-[196px]"
          onValueChange={search}
          placeholder={`Search ${childOrganizations.length} organizations`}
          value={searchName}
        />
        <MoreResults
          direction="up"
          onClick={() => {
            setScrollIndex(
              Math.min(childOrganizations.length, scrollIndex - 7)
            );
            repositionMenu();
          }}
          more={scrollIndex}
        />
        <OrganizationList
          {...{
            organizations: displayedOrganizations,
            selectedOrganization,
            switchOrg
          }}
        />
        <MoreResults
          direction="down"
          onClick={() => {
            setScrollIndex(
              Math.min(childOrganizations.length, scrollIndex + 7)
            );
            repositionMenu();
          }}
          more={Math.max(0, filteredOrganizations.length - scrollIndex - 7)}
        />
      </div>
    );
  }
};

const OrganizationSwitchingMenu = ({
  hasMenuOpen,
  repositionMenu,
  setHasMenuOpen
}) => {
  const {
    childOrganizations,
    mainOrganization,
    selectedOrganizationId,
    switchOrganization
  } = useUserContext();

  const selectedOrganization =
    //  If we have a selected org in history, find it in the child orgs
    (selectedOrganizationId &&
      childOrganizations?.find(
        ({ id }) => id + '' === selectedOrganizationId
      )) ||
    // If we couldn't, either there was no selected, or selected wasn't in child
    // orgs ... either way, it means we're on the main org
    mainOrganization;

  const isMain = selectedOrganization?.id == mainOrganization?.id;

  const selectedCircleColor = isMain ? circledLetterColor : childColor;
  const selectedLetter = selectedOrganization?.name;

  if (!childOrganizations || !mainOrganization) {
    return null;
  }
  return (
    <MenuItem
      {...{ hasMenuOpen, setHasMenuOpen }}
      icon={
        <ColoredLetterCircle
          color={selectedCircleColor}
          letter={selectedLetter}
        />
      }
      name="Organization"
      submenu={({ onClose }) => (
        <ChildOrganizationSubmenu
          {...{
            childOrganizations,
            mainOrganization,
            onClose,
            repositionMenu,
            selectedOrganization,
            switchOrganization
          }}
        />
      )}
      submenuClass={
        // The submenu gets a little wider if there are 8+ orgs
        '!fill-gray100 ' +
        (childOrganizations?.length > 7 ? 'min-w-[294px] ' : 'min-w-[260px] ')
      }
    />
  );
};
export default OrganizationSwitchingMenu;
