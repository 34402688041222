import classNames from 'classnames';
import { Button, ButtonProps } from 'reablocks';
import React, { FC, LegacyRef, Ref, forwardRef } from 'react';
import css from './IconButton.module.css';

export interface IconButtonProps extends ButtonProps {
  icon: React.ReactElement;
}

export const IconButton: FC<
  IconButtonProps & {
    ref?: LegacyRef<HTMLButtonElement>;
  }
> = forwardRef(
  (
    {
      className,
      children,
      icon,
      size,
      variant = 'filled',
      color = 'default',
      ...rest
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <Button
      {...rest}
      ref={ref}
      className={classNames(className, css.iconBtn, {
        [css.hasWords]: children !== undefined,
        [css[color]]: true,
        [css[size]]: true,
        [css[variant]]: true
      })}
      size={size}
      color={color}
      variant={variant}
    >
      {icon}
      {children}
    </Button>
  )
);
