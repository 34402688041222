import { PriorityLevel, getPriorityLevel } from 'App/Techniques/utils';
import { ReactComponent as PriorityIconSvg } from 'assets/icons/priority.svg';
import { ReactComponent as SeverityIcon } from 'assets/icons/warning.svg';
import classNames from 'classnames';
import css from './PriorityIcon.module.css';

/**
 * Renders a priority icon, with the appropriate class to color it
 * red/orange/yellow.
 *
 * The coloring can be provided as:
 *  - a classname (styled with the appropriate fill color)
 *  - a level (a PriorityLevel, ie. "high"/"medium"/"low"/"info")
 *  - a priority value (eg. 55) - we'll use getPriorityLevel to convert it
 */
const PriorityIcon = ({
  className,
  isTriangle = true,
  level,
  priority
}: {
  className?: string;
  isTriangle?: boolean;
  level?: PriorityLevel;
  priority?: number;
}) => {
  level ||= getPriorityLevel(priority);
  const levelClass = css[level] || '';
  return isTriangle ? (
    <SeverityIcon className={classNames(levelClass, className)} />
  ) : (
    <PriorityIconSvg className={classNames(levelClass, className)} />
  );
};

export default PriorityIcon;
