import isString from 'lodash/isString';

/**
 * Quick copy of Node's assert (ie. expect a truthy value or throw an error)
 * @param condition - the condition to test (we're asserting that it's truthy)
 * @param message - (optional) the error message to throw (if !condition)
 */
const assert = (condition: any, message: string | Error = '') => {
  if (!condition) {
    throw isString(message) ? new Error(message) : message;
  }
};

export default assert;
