import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { PropsWithChildren, ReactElement } from 'react';
import { colors } from 'shared/../../tailwind.config';
import BaseButton from 'shared/elements/MuiBase/BaseButton';

export const PrimaryDialogButton = ({ children, ...props }) => (
  <BaseButton
    className={
      `flex items-center justify-center ` +
      `h-[38px] bg-purple500 px-[15px] font-mono`
    }
    type="submit"
    {...props}
  >
    {children}
  </BaseButton>
);

/**
 * The base Interpres Dialog, built around the MUI Dialog component.
 *
 * In addition to the obvious props, this component also takes an onSubmit
 * handler.  This is because this component wraps its content and buttons in
 * a form tag (since any dialog forms likely need to span both parts of UI),
 * and so that form's handler needs to be passed in.
 */
const BaseDialog = ({
  button,
  buttons = null,
  children,
  fullWidth = true,
  isOpen,
  maxWidth = 'sm',
  onClose,
  onSubmit,
  size,
  subtitle,
  title
}: PropsWithChildren<{
  button?: ReactElement;
  buttons?: ReactElement;
  fullWidth?: boolean;
  isOpen: boolean;
  // NOTE: Newer dialogs use maxWidth; old ones use size
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  onClose?: () => void;
  onSubmit?: (e: any) => void;
  size?: string;
  subtitle?: string;
  title: string | ReactElement;
}>) => {
  // NOTE: We could use the dialog's "open" prop, but this way forces the dialog
  //       to re-render when re-opened, which gives us fresh state (and fresh
  //       MUI error state) every time the dialog is opened
  return (
    isOpen && (
      <Dialog
        {...{ fullWidth, maxWidth }}
        onClose={onClose}
        open={true}
        sx={{
          // This overrides the styles on the inner "paper" element
          '& .MuiPaper-root': {
            backgroundColor: colors.gray600,
            color: 'white',
            maxWidth: size,
            padding: '24px'
          }
        }}
      >
        {title && (
          <DialogTitle
            className={
              // NOTE: MUI has a 40px height for DialogTitle; the max-h fixes it
              `!max-h-[34px] !p-0 ` + // box
              'text-xxl font-semibold tracking-[-1px] text-white ' + // text
              `flex items-center justify-between ` // flex
            }
          >
            {title}
            <IconButton
              className="!mr-[-10px] !min-w-0 !rounded-full"
              onClick={onClose}
              sx={{ ml: 'auto' }}
            >
              <CloseIcon className="!fill-gray300" />
            </IconButton>
          </DialogTitle>
        )}
        {subtitle && (
          <h2 className="mt-mdlg font-mono text-md font-normal text-white">
            {subtitle}
          </h2>
        )}
        <form {...{ onSubmit }}>
          <div className="mt-xl">{children}</div>
          <div className="mt-xl">
            {/* If we have a single button, right-align it ...
              but if we have multiple, assume they'll have their own wrapper */}
            {button ? (
              <div className="flex justify-end">{button}</div>
            ) : (
              buttons
            )}
          </div>
        </form>
      </Dialog>
    )
  );
};

export default BaseDialog;
