import Slider from '@mui/material/Slider';

const BaseSlider = ({
  label,
  max = 100,
  min = 0,
  onChange: handleChange,
  value
}: {
  label?: string;
  max?: number;
  min?: number;
  onChange: any;
  value: number | number[];
}) => {
  const distance = (max - min) / 10;
  // NOTE: This logic assumes that we always want 10 marks on the slider, so a
  //       a 0-10 slider gets marks of 1,2,3 ..., while a 0-100 slider gets
  //       10,20,30 ....  For more/fewer marks we would need to change this.
  const marks = new Array(10).fill(1).map((_, i) => ({ value: i * distance }));

  // Wrap the provided onChange to provide it a more useful signature (which
  // can be plugged right into a setState call)
  const onChange = (e, value) => handleChange(value, e);

  return (
    <Slider
      aria-label={label}
      // getAriaValueText={valuetext}

      // NOTE: By default MUI will use our "primary" color, which is purple500
      //       ... but we want to use purple400 instead.  We've pretty much
      //      just ignored the MUI palette so far, so there's no particular
      //      reason to use it here; we'll just use Tailwind classes instead.
      //   color={colors.purple400}
      className={
        `!text-purple400 ` +
        `[&_.MuiSlider-valueLabel]:bg-gray600 ` +
        `[&_.MuiSlider-mark.MuiSlider-markActive]:bg-white ` +
        // Style the display that appears above the handle
        `[&_.MuiSlider-valueLabel]:bg-slate800 ` +
        `[&_.MuiSlider-valueLabel]:my-[4px] ` +
        `[&_.MuiSlider-valueLabel]:mx-[12px] ` +
        `[&_.MuiSlider-valueLabel]:font-mono `
      }
      {...{
        marks,
        max,
        min,
        onChange,
        value
      }}
      valueLabelDisplay="auto"
    />
  );
};

export default BaseSlider;
