import { User as BaseUser, ListGroupResponse } from 'core/graphql/graphql';
import { createContext, useContext } from 'react';

export interface FusionAuthUser {
  applicationId?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  roles?: string[];
  scope?: string;
  sid?: string;
  sub?: string;
  tid?: string;
}

interface User extends BaseUser {
  tid?: string;
}

export interface AuthContextProps {
  // NOTE: The official type of fusionAuthUser is "IFusionAuthContext"
  fusionAuthUser: FusionAuthUser;
  user: User | null;
  groups: ListGroupResponse;
  isAuthenticated?: boolean;
  isLoading?: boolean;
  logout: () => void;
  refreshToken: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  fusionAuthUser: null,
  user: null,
  groups: null,
  logout: () => undefined,
  refreshToken: () => undefined
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component'
    );
  }

  return context;
};
