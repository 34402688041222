import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import classNames from 'classnames';
import { DebouncedInput, DebouncedInputProps, Input } from 'reablocks';
import { forwardRef } from 'react';
import css from './SearchInput.module.css';

export interface SearchInputProps extends DebouncedInputProps {
  debounce?: number;
  onClear?: () => void;
  searchIconClassName?: string;
}

export const SearchInput = forwardRef<HTMLElement, SearchInputProps>(
  (
    {
      debounce = 600,
      disabled,
      onClear,
      onValueChange,
      searchIconClassName = '',
      value,
      ...rest
    },
    ref
  ) => {
    const InputComponent = ref ? Input : DebouncedInput;
    return (
      <InputComponent
        {...rest}
        value={value}
        debounce={debounce}
        disabled={disabled}
        // @ts-ignore I don't want to fight with TS's 100 different refs
        ref={ref}
        start={
          <SearchIcon className={classNames(css.icon, searchIconClassName)} />
        }
        end={
          <div className={css.placeholder}>
            {(value as string)?.length > 0 && !disabled && (
              <CloseIcon
                className={classNames(css.icon, css.clear)}
                onClick={() => {
                  onClear?.();
                  onValueChange?.('');
                }}
              />
            )}
          </div>
        }
        onValueChange={onValueChange}
      />
    );
  }
);
