import { gql, useApolloClient, useQuery } from '@apollo/client';
import * as FullStory from '@fullstory/browser';
import { FusionAuthProvider, useFusionAuth } from '@fusionauth/react-sdk';
import * as Sentry from '@sentry/browser';
import { USERS_CONTEXT } from 'core/Apollo';
import { ListGroupResponse, User } from 'core/graphql/graphql';
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useIntercom } from 'react-use-intercom';
import { AuthProvider } from './AuthContext';
import TOSDialog from './TOSDialog';

const PROFILE_QUERY = gql`
  query ProfileQuery {
    currentUser {
      userId
      firstName
      lastName
      email
      imageUrl
      hasAgreedToTerms
      groups {
        groupId
        name
        roles {
          roleId
          name
        }
      }
    }
    groups {
      groups {
        groupId
        name
      }
    }
  }
`;

/** Gets the user/groups (plus previous user/groups and loading status) */
const useUserAndGroup = isAuthenticated => {
  const { loading, data, previousData } = useQuery<{
    currentUser: User;
    groups: ListGroupResponse;
  }>(PROFILE_QUERY, {
    ...USERS_CONTEXT,
    skip: !isAuthenticated
  });
  return {
    hadPreviousData: !!previousData,
    previousData, // TODO: Are we using this?
    previousUser: previousData?.currentUser,
    previousGroups: previousData?.groups,
    userLoading: loading,
    userData: data
  };
};

type InternalAuthProps = PropsWithChildren<{
  onSetClientId: (value: string | null) => void;
}>;

const InternalAuth: FC<InternalAuthProps> = ({ onSetClientId, children }) => {
  const client = useApolloClient();
  const {
    logout: fusionLogout,
    isAuthenticated,
    refreshToken,
    isLoading,
    user
  } = useFusionAuth();
  // Add tid as tenantId of context user

  const { boot } = useIntercom();

  const {
    userLoading,
    userData,
    hadPreviousData,
    previousData,
    previousUser,
    previousGroups
  } = useUserAndGroup(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && user && import.meta.env.PROD) {
      try {
        // If the user doesn't have a name, let's hide it
        const name =
          user.firstName || user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.email;

        FullStory.identify(user.email, {
          displayName: name,
          email: user.email
        });

        Sentry.configureScope(scope =>
          scope.setUser({
            username: user.email,
            name: name,
            email: user.email
          })
        );

        boot({
          hideDefaultLauncher: true,
          /*
          company: {
            companyId: user.company,
            name: user.company
          },
          */
          email: user.email,
          name: name
        });
      } catch {
        /** noop */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  const logout = useCallback(async () => {
    client.clearStore();
    onSetClientId(null);
    // Small timeout to allow the local storage to save before redirect
    setTimeout(() => fusionLogout(), 100);
  }, [client, fusionLogout, onSetClientId]);

  const { hasAgreedToTerms } = userData?.currentUser ?? {};
  const values = {
    fusionAuthUser: user,
    hasAgreedToTerms,
    isAuthenticated,
    isLoading: isLoading || (userLoading && !hadPreviousData),
    groups: userData?.groups || previousGroups,
    logout,
    refreshToken,
    user: userData?.currentUser || previousUser
  };
  const isLoggedIn = userData?.currentUser;
  const { pathname } = window.location;
  const onLoginPage = ['/login', '/'].includes(pathname);
  const showTOSDialog = isLoggedIn && !onLoginPage && !hasAgreedToTerms;
  return (
    <AuthProvider value={values}>
      {/* Don't let the user do *anything* after logging in, until they've 
          agreed to the TOS (but if they want to re-login, let them; maybe they 
          realized they logged-in to the wrong user after seeing the TOS dialog)
      */}
      {showTOSDialog ? <TOSDialog user={userData?.currentUser} /> : children}
    </AuthProvider>
  );
};

export const Auth: FC<PropsWithChildren> = ({ children }) => {
  // TODO: Why do we keep clientId in state and in local storage?
  //       Comment below suggests reasoning: still relevant?
  const [clientId, setClientId] = useLocalStorage<string | null>(
    '@interpres:clientid',
    null
  );
  const tempClientId = useRef<string | null>(clientId);
  return (
    <FusionAuthProvider
      clientID={tempClientId.current}
      serverUrl="/auth"
      redirectUri={`${window.location.protocol}//${window.location.host}`}
    >
      <InternalAuth
        onSetClientId={value => {
          // Set temp value (race conditions) but save ID locally, for later
          tempClientId.current = value;
          setClientId(value);
        }}
      >
        {children}
      </InternalAuth>
    </FusionAuthProvider>
  );
};
