import { InMemoryCache } from '@apollo/client';
import generatedIntrospection from '../graphql/graphql';

export const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    // NOTE: If we don't have these three policies then when we filter by
    //       integration, then clear that filter, the caching will prevent
    //       the original (ie. correct) values from showing. @see PROD-607
    PrioritizedSoftwareType: {
      keyFields: ['id', 'content']
    },
    PrioritizedTechniqueType: {
      keyFields: ['id', 'content']
    },
    PrioritizedThreatGroupsType: {
      keyFields: ['id', 'content']
    }
  }
});
