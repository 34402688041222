export const boxShadow = {
  muiCheckmarkAnimationShadow: `0 0 0 10px,
  10px -10px 0 10px,
  32px 0px 0 20px,
  0px 32px 0 20px,
  -5px 5px 0 10px,
  20px -12px 0 11px`
};

export const colors = {
  // Blues
  blue100: '#7AE4FF',
  blue200: '#48DDFF',
  blue300: '#1ECDFF',
  blue400: '#06B3FF',
  blue500: '#00A0FF',
  blue600: '#0093EB',
  blue700: '#0879C5',
  blue800: '#0D669B',

  // Grays
  gray50: '#CAC4D0',
  gray100: '#90919C',
  gray200: '#878994',
  gray300: '#696B79',
  gray400: '#454853',
  gray500: '#35353A',
  gray600: '#28282D',
  gray700: '#1B1C23',
  gray800: '#0C0A0E',

  // Greens
  green100: '#39F0CB',
  green200: '#24E9C1',
  green300: '#22DDB7',
  green400: '#12CFA9',
  success: '#12CFA9', // "success" color is just an alias of green400
  green500: '#04B897',
  green600: '#00A187',
  green700: '#05806D',
  green800: '#0A6558',

  // Oranges
  orange100: '#FFDA63',
  orange200: '#FCD34D',
  orange300: '#FBBF25',
  orange400: '#FF980E',
  orange500: '#FF7D04',
  orange600: '#F06706',
  orange700: '#C74D07',
  orange800: '#9E3C0E',

  // Purples
  purple100: '#AC73FF',
  purple150: '#9D5CFF',
  purple200: '#9E4DFF',
  purple300: '#953BFF',
  purple400: '#8E14FF',
  purple500: '#8100FE',
  purple600: '#6D01D6',
  purple700: '#5A03AF',
  purple800: '#450088',
  purple900: '#1b141d',

  // Reds
  red100: '#FF8C8C',
  red200: '#FF7272',
  red300: '#F85858',
  red400: '#EB4848',
  error: '#DA3434', // "error" color is just red500
  red500: '#DA3434',
  red600: '#B61F1F',
  red700: '#961E1E',
  red800: '#7D1F1F',
  red900: '#CE2222',

  // Slate (Grays)
  slate100: '#ECEEF0',
  slate200: '#E6E8EB',
  slate300: '#DFE3E6',
  slate400: '#D7D8DF',
  slate500: '#C1C8CD',
  slate600: '#889096',
  slate700: '#787F85', // was slate100 in the old ReaBlocks
  slate800: '#4C5155', // was slate200 in the old ReaBlocks
  slate900: '#36343B',
  // NOTE: We don't really use the yellow palette (and even what we do use
  //       is only used for coloring locked chips on identity detail)
  yellow200: '#fef08a',
  // NOTE: We really don't use the amber palette ... and neither does GC
  //       *sigh*.  In spite of having a ton of colors in our palette, and
  //       even more in their's, their designer picked a new color here
  //       (which is *almost* amber-400 with 30% opacity, but off by 1)
  amber401: '#fbbf254d',
  // MUI does it's hover ripple effects as 4% opacity black, so we need a
  // similar but reversed color for our (dark mode) site
  muiTransparentWhite: 'rgba(255, 255, 255, 0.1)'
};

const fontSize = {
  xs: '8px',
  sm: ['12px', '14px'],
  // NOTE: GC added this in their design for Organizations (sub-tenants)
  // TODO: Ask future designer: do we really need 12, 13 and 14px? Could
  //       the tenant table headers be either 12 or 14, then delete this?
  smallish: ['13px', '16px'],
  md: ['14px', '18px'],
  lg: ['16px', '20px'],
  lgplus: ['17px', '22px'],
  lgxl: ['18px', '24px'],
  xl: '20px',
  xxl: ['28px', '34px']
};

// *** fontWeight ***
// (already in Tailwind, but included here for reference)
// font-thin	      font-weight: 100;
// font-extralight	font-weight: 200;
// font-light	      font-weight: 300;
// font-normal	    font-weight: 400;
// font-medium	    font-weight: 500;
// font-semibold	  font-weight: 600;
// font-bold	      font-weight: 700;
// font-extrabold	  font-weight: 800;
// font-black	      font-weight: 900;

const animation = {
  checkboxOn: 'checkboxOn 700ms forwards ease-out',
  spinReverse: 'spinReverse 1s linear infinite'
  // rippleOn: 'rippleOn 700ms forwards ease-out;',
  // rippleOff: 'rippleOff 700ms forwards ease-out'
};
const keyframes = {
  checkboxOn: {
    '0%': {
      'box-shadow': `0 0 0 10px,
        10px -10px 0 10px,
        32px 0px 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        15px 2px 0 11px`
    },
    '50%': {
      'box-shadow': `0 0 0 10px,
        10px -10px 0 10px,
        32px 0px 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px 2px 0 11px`
    },
    '100%': {
      'box-shadow': `0 0 0 10px,
        10px -10px 0 10px,
        32px 0px 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px -12px 0 11px`
    }
  },
  spinReverse: {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(-360deg)'
    }
  },
  // @keyframes checkbox-off {
  //   0% {
  //     box-shadow:
  //       0 0 0 10px,
  //       10px -10px 0 10px,
  //       32px 0px 0 20px,
  //       0px 32px 0 20px,
  //       -5px 5px 0 10px,
  //       20px -12px 0 11px,
  //       0 0 0 0 inset;
  //   }

  //   25% {
  //     box-shadow:
  //       0 0 0 10px,
  //       10px -10px 0 10px,
  //       32px 0px 0 20px,
  //       0px 32px 0 20px,
  //       -5px 5px 0 10px,
  //       20px -12px 0 11px,
  //       0 0 0 0 inset;
  //   }
  //   50% {
  //     transform: rotate(45deg);
  //     margin-top: -4px;
  //     margin-left: 6px;
  //     width: 0px;
  //     height: 0px;
  //     box-shadow:
  //       0 0 0 10px,
  //       10px -10px 0 10px,
  //       32px 0px 0 20px,
  //       0px 32px 0 20px,
  //       -5px 5px 0 10px,
  //       15px 2px 0 11px,
  //       0 0 0 0 inset;
  //   }
  //   51% {
  //     transform: rotate(0deg);
  //     margin-top: -2px;
  //     margin-left: -2px;
  //     width: 20px;
  //     height: 20px;
  //     box-shadow:
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0px 0px 0 10px inset;
  //   }
  //   100% {
  //     transform: rotate(0deg);
  //     margin-top: -2px;
  //     margin-left: -2px;
  //     width: 20px;
  //     height: 20px;
  //     box-shadow:
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0 0 0 0,
  //       0px 0px 0 0px inset;
  //   }
  // },
  rippleOn: {
    '0%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 0,
      transform: 'scale(13,13)'
    }
  },
  rippleOff: {
    '0%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 0,
      transform: 'scale(13,13)'
    }
  }
};

const spacing = {
  xs: '2px',
  smaller: '3px',
  small: '4px',
  sm: '5px',
  smmd: '8px',
  md: '10px',
  midlg: '15px',
  mdlg: '16px',
  lg: '20px',
  xl: '24px',
  xxl: '30px',
  xxxl: '40px'
};

export default {
  content: ['./src/**/*.{ts,tsx}'],
  plugins: [
    {
      'postcss-import': {},
      'tailwindcss/nesting': {},
      tailwindcss: {},
      autoprefixer: {}
    }
  ],
  theme: {
    // NOTE: Most (all?) values below originally came from
    //       interpres-new-ui/src/shared/utils/Theme/theme.ts
    extend: {
      animation,
      backgroundImage: {
        // fade: 'url("src/App/Nav/fade.svg")'
      },
      borderWidth: {
        DEFAULT: '1px',
        ...spacing
      },
      colors,
      fontFamily: {
        main: 'Inter, sans-serif',
        mono: 'Roboto Mono, monospace'
      },
      fontSize,
      keyframes,
      // gap/margin/padding ... but *sigh* not max-width
      spacing,
      // Repeat the above for max-width, because:
      // https://github.com/tailwindlabs/tailwindcss/discussions/13985
      maxWidth: spacing,

      screens: {
        // NOTE: Ordered by popularity: 1366x768 is #1, 256x1440 is #2, etc.
        screen1336: '1336px',
        screen2560: '2560px',
        screen1920: '1920px',
        screen1280: '1280px',
        screen1512: '1512px',

        // NOTE: The above order is *supposedly* based on data GC collected.  I
        //       find it suspect, as I don't think our users' screen sizes
        //       should vary *that* much from the general web-browsing public:
        //       https://gs.statcounter.com/screen-resolution-stats/desktop/worldwide

        // TODO: Someday get better data on actual user screen sizes, and ...
        //       - if they match GC's, be pleasantly surprised
        //       - if they don't but seem close to the standards, switch to them
        //       - otherwise, switch to standard sizes but tweak them to match
        //         (there really *shouldn't* be much difference ... our users
        //         might have larger monitors, but not totally different ones!)
        //       Once we have a set of "Interpres sizes", replace the above with
        //       them and remove others (both "screen*"" and the "break*" ones)

        // These were used in responsive styling pre-Tailwind.  They're just
        // here until we can switch them to standard sizes (see above).
        break960: '960px',
        break1512: `1512px`,
        break1635: '1635px',
        break1728: '1728px',
        break1986: '1986px',
        break2560: '2560px'
      }
    }
  }
};
