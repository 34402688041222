import { Avatar } from 'reablocks';
import assert from 'shared/utils/assert';

/**
 * Generates a single letter in a circle with the provided color
 * @param props
 * @param props.color
 * @param props.letter - the letter to generate in a colored circle
 */
const ColoredLetterCircle = ({
  color,
  letter,
  size = 30
}: {
  color: string;
  letter: string;
  size?: number;
}) => {
  assert(color, 'A color is required to generate a "Colored Letter Circle"');
  assert(letter, 'A letter is required to generate a "Colored Letter Circle"');

  // If we get a string with 2+ letters, just use the first letter
  const name = letter.substring(0, 1);
  return (
    <Avatar
      className="border border-solid border-gray600"
      {...{ color, name, rounded: true, size }}
    />
  );
};

export default ColoredLetterCircle;
