/**
 * This component is meant to be use when you have a string, and want to
 * "highlight" certain words in it (ie. wrap those words in a span with 1+
 * classes).
 * @param highlightClass - the class(es) to add to span-wrapped words
 * @param shouldHighlight - a function which returns true if the provided word
 *                          should be highlighted
 * @param text - the text to highlight
 */
const Highlight = ({
  highlightClass,
  searchTerm,
  text
}: {
  highlightClass: string;
  searchTerm: string;
  text: string;
}) => {
  if (!text) {
    return <span />;
  }
  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  const isMatch = part => part.toLowerCase() === searchTerm.toLowerCase();
  return parts.map((part, i) => (
    <span key={i} className={isMatch(part) ? highlightClass : ''}>
      {part}
    </span>
  ));
};

export default Highlight;
