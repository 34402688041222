import { ReactComponent as PlatformIcon } from 'assets/icons/platform.svg';
import { ActiveFilterChip } from 'shared/utils/ActiveFilterChip';

const PlatformNamesActiveFilterChip = ({ filter, setFilter }) => {
  const { platformNames } = filter ?? {};
  const names = platformNames?.split(',')?.filter(x => x);
  return (
    !!names?.length && (
      <ActiveFilterChip
        icon={<PlatformIcon />}
        label="Platforms"
        value={names[0]}
        suffix={names.length > 1 && `+${names.length - 1}`}
        onDelete={() => setFilter({ ...filter, platformNames: '' })}
      />
    )
  );
};

export default PlatformNamesActiveFilterChip;
