import Button from '@mui/material/Button';
import { PropsWithChildren } from 'react';

type MuiPaletteType =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';

const BaseButton = ({
  children,
  className = '',
  color = 'primary',
  disabled = false,
  onClick,
  ...props
}: PropsWithChildren<{
  color?: MuiPaletteType;
  // NOTE: onClick is optional because while *most* buttons will have one, but
  //       some will just trigger their form's onSubmit
  onClick?: () => void;
  [key: string]: any; // Passed-through MUI Button props
}>) => {
  className += ' disabled:!bg-gray500 disabled:!text-gray300';
  return (
    <Button
      {...{ ...props, className, color, disabled, onClick }}
      variant="contained"
    >
      {children}
    </Button>
  );
};

export default BaseButton;
