import classNames from 'classnames';
import { Select, SelectMenu, SelectOption } from 'reablocks';
import { FC, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { SavedFilterItem } from './SavedFilterItem';
import {
  FilterItem,
  SavedFilterObject,
  getDefaultSavedFilterObject
} from './utils';

interface SavedFiltersProps {
  className?: string;
  // Key used to retrieve and save different types of filter from the localStorage
  filterType: string;
  // Default option to be selected
  defaultSavedFilterId?: string;
  onFilterSelected: (filter: FilterItem) => void;
}

export const SavedFilters: FC<SavedFiltersProps> = ({
  className,
  filterType,
  defaultSavedFilterId,
  onFilterSelected
}) => {
  const [savedFilterObj, setSavedFilterObj] =
    useLocalStorage<SavedFilterObject>(
      filterType,
      getDefaultSavedFilterObject(filterType)
    );

  const filters = savedFilterObj?.filters || [];

  let defaultSelectedObject = null;
  if (defaultSavedFilterId) {
    const index = filters.findIndex(
      filterItem => filterItem?.id === defaultSavedFilterId
    );
    if (index > -1) {
      defaultSelectedObject = filters[index];
    }
  }

  const [selectedFilter, setSelectedFilter] = useState<any | null>(
    defaultSelectedObject?.id
  );

  const deleteFilter = (filter: FilterItem) => {
    const index = filters.findIndex(
      filterItem => filterItem?.id === filter?.id
    );
    if (index > -1) {
      filters.splice(index, 1);
    }
    setSavedFilterObj({
      filterType: filterType,
      maxCount: filters?.length > 0 ? savedFilterObj?.maxCount : 0,
      filters: filters
    });
  };

  const updateFilter = (filter: FilterItem) => {
    const index = filters.findIndex(
      filterItem => filterItem?.id === filter?.id
    );
    if (index > -1) {
      filters.splice(index, 1, filter);
    }
    setSavedFilterObj({
      filterType: filterType,
      maxCount: filters?.length > 0 ? savedFilterObj?.maxCount : 0,
      filters: filters
    });
  };

  return (
    <Select
      clearable={false}
      closeOnSelect={true}
      placeholder="Select Filter"
      value={selectedFilter}
      onChange={setSelectedFilter}
      menu={<SelectMenu className={classNames(className)} />}
    >
      {filters.map(filter => (
        <SelectOption
          key={filter.id}
          value={filter.id}
          inputLabel={filter.name}
          menuLabel={
            <SavedFilterItem
              filter={filter}
              onSelect={onFilterSelected}
              onDelete={deleteFilter}
              onUpdate={updateFilter}
            />
          }
        >
          {filter.name}
        </SelectOption>
      ))}
    </Select>
  );
};
