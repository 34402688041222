import { gql, useQuery } from '@apollo/client';
import { Block } from 'reablocks';
import { useMemo } from 'react';
import FilterCheckboxSelect from '../FilterCheckboxSelect';

const query = gql`
  query IntegrationNamesQuery {
    integrationNames
  }
`;
/**
 * Renders a drop-down (and wrapping <Block/>) for selecting an integration, on
 * a data list page filter pop-up.
 */
export const IntegrationFilter = ({ value, setValue }) => {
  const { data, error } = useQuery<{ integrationNames: string[] }>(query);
  if (error) {
    console.error(error);
  }

  const lowerCaseIntegrationNames = useMemo(
    () => data?.integrationNames.map(x => x.toLowerCase()),
    [data?.integrationNames]
  );
  // A valid name is (case-insensitively) on the list of all integration names
  const isValid = name =>
    lowerCaseIntegrationNames.includes(name.toLowerCase());

  return (
    !!data?.integrationNames && (
      <Block label="Integration">
        <FilterCheckboxSelect
          currentValue={value}
          onChange={newSelections => setValue(newSelections.filter(isValid))}
          options={data.integrationNames}
          searchPlaceholder="Search by Integration"
        />
      </Block>
    )
  );
};

export default IntegrationFilter;
