import classNames from 'classnames';
import { User } from 'core/graphql/graphql';
import {
  Avatar as ReaAvatar,
  AvatarProps as ReaAvatarProps,
  useTheme
} from 'reablocks';
import { FC } from 'react';

export interface AvatarProps extends ReaAvatarProps {
  user?: Partial<User>;
}

export const Avatar: FC<AvatarProps> = ({
  user,
  rounded = true,
  size = 30,
  className,
  ...rest
}) => {
  if (user) {
    if (user.imageUrl) {
      rest.src = user.imageUrl;
    } else if (user.firstName || user.lastName) {
      rest.name = `${user.firstName} ${user.lastName}`;
    }
  }

  const theme = useTheme();

  return (
    <ReaAvatar
      {...rest}
      className={classNames(className, 'border border-solid border-gray600')}
      size={size}
      rounded={rounded}
      color={theme.palettes.primary.background}
    />
  );
};
