import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import {
  Checkbox,
  Select,
  SelectInput,
  SelectMenu,
  SelectOption
} from 'reablocks';
import { SearchInput } from 'shared/form/SearchInput';

const FilterCheckboxSelect = ({
  currentValue,
  loading,
  onChange,
  options,
  placeholder = 'Select',
  searchPlaceholder = 'Search',
  setSearch
}: {
  currentValue: any;
  loading?: boolean | undefined;
  onChange: any;
  options?: string[] | { [key: string]: string };
  placeholder?: string;
  searchPlaceholder?: string;
  setSearch?: any;
}) => {
  const processedOptions = Array.isArray(options)
    ? options
    : Object.entries(options).map(([label, value]) => ({ label, value }));

  return (
    <Select
      {...{ loading, onChange, placeholder }}
      closeOnSelect={false}
      input={
        <SelectInput
          onInputChange={
            !!setSearch &&
            (e => {
              setSearch(e.target.value);
            })
          }
        />
      }
      menu={<SelectMenu />}
      multiple
      value={currentValue}
    >
      {processedOptions.length > 14 && (
        <div className="mb-sm h-[34px]">
          <SearchInput
            onValueChange={setSearch}
            placeholder={searchPlaceholder}
            value={currentValue}
          />
        </div>
      )}
      {processedOptions.map(option => {
        //@ts-ignore
        const { label, value } = isObject(option)
          ? option
          : { label: option, value: option };
        return (
          <SelectOption
            key={label}
            inputLabel={label}
            menuLabel={
              <Checkbox
                checked={currentValue?.includes(value)}
                label={label}
                containerClassName="fill-gray600 p-sm"
                labelClassName="text-white font-mono text-[14px]"
              />
            }
            value={value}
          >
            {label}
          </SelectOption>
        );
      })}
    </Select>
  );
};

export default FilterCheckboxSelect;
