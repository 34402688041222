import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { User } from '@sentry/react';
import classNames from 'classnames';
import { ListItem } from 'reablocks';
import { ReactElement, ReactNode, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Avatar } from 'shared/elements/Avatar';
import { SubmenuButton } from './MenuButton';
import css from './Nav.module.css';

type MuiIcon = OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
  muiName: string;
};
type SvgIcon = React.FC<React.SVGProps<SVGSVGElement>>;

export interface SubmenuItemProps {
  /** Should this sub-menu item always appear white? (default color is gray) */
  alwaysWhite?: boolean;

  className?: string;
  /** The sub-menu item's icon */
  icon: MuiIcon | SvgIcon;
  /** The sub-menu item's visual text label */
  label: string | ReactNode;
  /** The sub-menu item's visual text label's CSS class */
  labelClass?: string;
  /** If the sub-menu item goes somewhere when clicked, this specifies where */
  link?: string;
  /** If the sub-menu item triggers custom JS when clicked, specify that JS */
  onClick?: any;

  onClose: any;
  /** The keyboard shortcut for the menu item (as React elements) */
  shortcut?: ReactElement;
}

/**
 * Renders a sub-menu item (ie. an icon button) in the Nav menu.  That button
 * will be wrapped in a link, to navigate to the appropriate page when the
 * sub-menu item is clicked.  Also, when the user is on an item's page, the
 * item's text/icon becomes white ("active").
 */
const SubmenuItem = ({
  alwaysWhite,
  icon: Icon,
  isRed = false,
  label,
  link,
  onClick,
  onClose: closeMenu,
  shortcut
}: SubmenuItemProps & { isRed?: boolean }) => {
  // There are two chat pages, /aichat/welcome and /aichat/conversation; both
  // should "activate" the chat menu item
  const pattern = link?.startsWith('aichat') ? `aichat/*` : `${link}/*`;
  const isOnItemPage = !!useMatch(pattern);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link to={link}>
      <SubmenuButton
        {...{ isOnItemPage, isHovered, setIsHovered }}
        onClick={e => {
          onClick && onClick(e);
          closeMenu();
        }}
      >
        <ListItem
          start={<Icon fontSize="large" />}
          className={classNames(
            css.listItem,
            `max-h-[24px] min-h-[24px] w-full justify-between ` +
              `py-0 pl-[7px] pr-[12px] ` +
              `text-left !font-main ` +
              `[&>div]:!whitespace-nowrap ` +
              `[&_div:nth-child(2)]:pl-md ` +
              `[&_div:nth-child(2)]:w-full ` +
              `duration-0 ` + // get rid of the delayed animation from ListItem
              (isRed ? `!text-red200 ` : ``)
          )}
          end={shortcut}
        >
          {label}
        </ListItem>
      </SubmenuButton>
    </Link>
  );
};

/**
 * One sub-menu item is completely different from the rest: the user sub-menu.
 * Rather than try to shoehorn it into our SubmenuItem component, we just have
 * a (completely separate, except for a className) component for it.
 */
export const UserSubmenuItem = ({ user }: { user: User }) =>
  user ? (
    <ListItem
      className={
        `duration-0 ` + // get rid of the delayed animation from ListItem
        css.userListItem
      }
    >
      <Avatar size={40} className={css.avatar} user={user} />
      <div>
        <h3 className="text-white">
          {user.firstName} {user.lastName}
        </h3>
        <small className={css.email}>
          <span className="text-sm text-gray100">{user.email}</span>
        </small>
      </div>
    </ListItem>
  ) : null;

export default SubmenuItem;
