import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';

// Ignore those docker containers :D
const isLocal = window.location.origin.includes('local');

// Add in FS/Sentry when deployed
if (import.meta.env.PROD && !isLocal) {
  // TODO: We need to put this behind a proxy so ad blockers
  // don't block it from capturing
  FullStory.init({
    orgId: import.meta.env.VITE_FULLSTORY_ORG
  });

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new SentryFullStory('interpres', { client: FullStory }),
      new BrowserTracing()
    ],
    ignoreErrors: [
      'Failed to fetch',
      'Unable to preload CSS',
      'NetworkError when attempting to fetch resource',
      'error loading dynamically imported module'
    ],
    denyUrls: ['fullstory.com']
  });
}
