import { MotionGroup } from 'reablocks';
import { useState } from 'react';
import { getTypeIcon } from 'shared/data/DataTypeUtil';
import { determineElementIndex } from 'shared/utils/DOMUtil';
import GlobalSearchResultRow from './GlobalSearchResultRow';
// NOTE: Without this, the bundler won't include the file in the build
import NoResultPNG from '/src/assets/illustrations/no-results.png';

const ResultsDisplay = ({ close, results, searchTerm }) => {
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const highlightRow = e => {
    const resultEl = e.target.closest('.globalSearchResult');
    setHighlightIndex(resultEl ? determineElementIndex(resultEl) : -1);
  };

  return (
    <MotionGroup>
      {results.map((result, index) => (
        <GlobalSearchResultRow
          key={result.__typename + (result.id || result.mitreId)}
          isHighlighted={index === highlightIndex}
          {...{ close, highlightRow, result, searchTerm }}
        />
      ))}
    </MotionGroup>
  );
};

const NoResultsMessage = () => (
  <div className="text-white">
    No search results found; please try different keywords or check your
    spelling.
    <img src={NoResultPNG} />
  </div>
);

const globalSearchDataTypeMessages = {
  Techniques: 'Searches techniques by name and ID',
  Software: 'Searches software by name and ID',
  'Threat Groups': 'Searches threat groups and their aliases by name',
  Campaigns: 'Searches campaigns by name and description',
  Detections: 'Searches detections by name, description, and logic',
  Visibility: 'Searches visibilities by name',
  Assets: 'Searches assets by hostname',
  Identities: 'Searches your identities by name and email address',
  Vulnerabilities: 'Searches your vulnerabilities by CVE ID and description'
};

const DataTypeMessageRow = ({ dataType }) => {
  const Icon = getTypeIcon(dataType);

  return (
    <div className="flex items-center gap-[8px] py-[8px] pr-[16px]">
      <div className="border border-solid border-gray400 bg-gray800 p-md">
        <Icon className="m-md fill-white" width="20" height="20" />
      </div>
      <div>
        <strong className="mb-sm">{dataType}</strong>
        <div className="font-mono text-sm text-gray100">
          {globalSearchDataTypeMessages[dataType]}
        </div>
      </div>
    </div>
  );
};

const NoQueryMessage = () => (
  <div className={`my-[24px] max-w-[640px] ` + ` text-white`}>
    {Object.keys(globalSearchDataTypeMessages).map(dataType => (
      <DataTypeMessageRow {...{ dataType }} key={dataType} />
    ))}
  </div>
);

const GlobalSearchDialogResults = ({ close, results, searchTerm }) => {
  if (!results) {
    return <NoQueryMessage />;
  }
  return (
    <div className="relative min-h-fit">
      <h3 className={`mb-[8px] ml-[5px] mt-xl font-mono text-gray200`}>
        Results{' '}
        <span
          className={
            `border border-solid border-gray400 ` + // border
            `inline-flex items-center gap-[4px] ` + // flex
            `text-sm text-white ` + // text
            `mb-[8px] h-[24px] max-w-fit px-[8px] py-0` // box
          }
        >
          {results.length}
        </span>
      </h3>
      {results.length ? (
        <ResultsDisplay {...{ close, results, searchTerm }} />
      ) : (
        <NoResultsMessage />
      )}
    </div>
  );
};

export default GlobalSearchDialogResults;
