import { DetectionType, ReferenceDetectionType } from 'core/graphql/graphql';
import { subDays } from 'date-fns';

export const MAX_COVERAGE = 100;
export const MIN_COVERAGE = 0;

export interface CampaignsRow {
  id: string;
  name: string;
  origin: string;
  content: number;
  aliasNames: string[];
  associations: {
    techniques: number;
    softwares: number;
    threatGroups: number;
    vulnerabilityCount: number;
    assetCount: number;
  };
  lastSeenTimestamp: string;
  createdTimestamp: string;
  vulnerabilityCveIds: string[];
  stixId: string;
}

export interface CampaignsFilter {
  origin?: string;
  content?: string;
  showOnlyWithExploitation?: boolean;
  limitCampaigns?: boolean;
  techniqueMitreIds?: string;
  softwareMitreIds?: string;
  threatGroupsMitreIds?: string;
  threatGroupsIndustries?: string;
  threatGroupsCountries?: string;
}

export const ORDER_BY_FIELDS = {
  NAME: 'name',
  ORIGIN: 'origin',
  CREATED: 'createdTimestamp',
  CONTENT: 'content'
};

export interface DetectionsListItem extends DetectionType {
  techniqueMitreIds: string[];
  type: 'detection';
}

export interface ReferenceDetectionsListItem extends ReferenceDetectionType {
  techniqueMitreIds: string[];
  type: 'referenceDetection';
}

export const KEY_SAVED_FILTER_CAMPAIGNS = '@interpres:campaigns-filter';

/**
 * Returns the severity of the timestamp depending upon how recent it was -> high, medium, info
 * @param timestamp
 */
export const getTimeSeverity = (timestamp: string): string => {
  const inputEpoch = Date.parse(timestamp);
  const today = new Date();
  const lastWeek = subDays(today, 7).valueOf();
  const lastMonth = subDays(today, 30).valueOf();

  // last week
  if (lastWeek < inputEpoch) {
    return 'high';
  }

  // last month
  if (lastMonth < inputEpoch) {
    return 'medium';
  }

  // older than a month
  return 'info';
};
