import { ReactComponent as LogoIcon } from 'assets/brand/icon-white.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';
import css from './Loader.module.css';

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => (
  <motion.div
    className={classNames(css.loader, className)}
    animate={{
      opacity: [0.5, 0.7, 0.5],
      scale: [0.9, 1.2, 0.9]
    }}
    data-testid="loader"
    transition={{
      duration: 1,
      times: [0, 0.2, 0.5, 0.8, 1],
      repeat: Infinity,
      repeatDelay: 0.5
    }}
  >
    <LogoIcon />
  </motion.div>
);
